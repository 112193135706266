import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

// Hook to manage form field states and errors
const Attribute = ({ onSave }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [atName, setAtName] = useState('');
    const token = localStorage.getItem('token');
    const [atNameError, setAtNameError] = useState(false);
    const [CatNameError, setCatNameError] = useState(false);
    const [selectedCategoryId, setCategoryId] = useState('');
    const onInputChange = (setter, errorSetter) => (e) => {
        setter(e.target.value);
        errorSetter(false);
    };

    const validateFields = (fields) => {
        let isValid = true;
        fields.forEach(({ value, errorSetter }) => {
            if (!value) {
                errorSetter(true);
                isValid = false;
            }
        });
        return isValid;
    };
    const handleSubmit = async (url, requestData, successCallback) => {
        setLoading(true);
        try {
            const response = await axios.post(url, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            if (response.data.status === 1) {
                toast.success(response.data.message);
                successCallback();
                onSave();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };
    const handleSubmitAttribute = () => {
        if (validateFields([
            { value: atName, errorSetter: setAtNameError },
            { value: selectedCategoryId, errorSetter: setCatNameError }
        ])) {
            handleSubmit(
                `${process.env.REACT_APP_API_URL}attribute-store`,
                { name: atName, category_id: parseInt(selectedCategoryId) },
                () => {
                    setAtName('');
                    setCategoryId('');
                    // setTimeout(() => navigate('/attribute'), 1000);
                }
            );
        } else {
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}category-list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setData(response.data.data || []);
        } catch (error) {
            console.error('Error fetching categories:', error);
            toast.error('An error occurred while fetching categories');
        }
    };
    useEffect(() => {
        fetchCategories();
    }, []);
    
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h4> Add Attribute</h4></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label className="custom-label">Enter Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Name"
                                    value={atName}
                                    onChange={onInputChange(setAtName, setAtNameError)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleSubmitAttribute();
                                        }
                                    }}
                                    className={atNameError ? 'is-invalid' : ''}
                                />
                                {atNameError && <Form.Control.Feedback type="invalid">Please enter attribute name.</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label className="custom-label">Select Category</Form.Label>
                                <Form.Select
                                    value={selectedCategoryId}
                                    className={CatNameError ? 'is-invalid' : ''}
                                    onChange={onInputChange(setCategoryId, setCatNameError)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleSubmitAttribute();
                                        }
                                    }}
                                >
                                    <option value="">Select Category Name</option>
                                    {data.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Form.Select>
                                {CatNameError && <Form.Control.Feedback type="invalid">Please select a category.</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>



                    </Row>

                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                <div>
                    <Button variant="primary" onClick={handleSubmitAttribute} className="btn btn-submit">
                        Create
                    </Button>
                </div>
            </Modal.Footer>
        </>
    );
};

export default Attribute;
