import React from 'react'
import { Row, Col, Container, Form, Card, Table } from 'react-bootstrap'
export default function Vendorview() {
    const tablenew = [
        { itemsname: 'Sofa', qty: '1452', vendor: 'sofa', Status: "Running", date: '15/02/2024', cost: '50,000' },
        { itemsname: 'Table', qty: '14', vendor: 'Table', Status: "Running", date: '14/02/2024', cost: '14,000' },
        { itemsname: 'Bed', qty: '85', vendor: 'sofa', Status: "Running", date: '25/01/2024', cost: '147,000' },
        { itemsname: 'Cricket Bat', qty: '98', vendor: 'sofa', Status: "Running", date: '08/08/2024', cost: '45,000' },
        { itemsname: 'Chair', qty: '7458', vendor: 'sofa', Status: "Running", date: '14/09/2024', cost: '89,000' },
    ];
    return (
        <div className='Vendorview'>
            <div className="page-header">
                <div className="add-item d-flex justify-content-between">
                    <div className="page-title">
                        <h4>Vendor View Details</h4>
                        <h6>Order View</h6>
                    </div>
                </div>
                <div className="button_add_row">

                </div>
            </div>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-0">
                        Order View
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex gap-2 justify-content-between">
                        <h6><strong>Order</strong> 443</h6>
                        <h6><strong>Customer</strong> 1223</h6>
                        <h6><strong>Delivery Date</strong> 15/05/2024</h6>
                        <h6><strong>Amount</strong> 50,55252</h6>

                    </div>
                    <div className="table-responsive">
                        
                    <Table striped bordered hover>
                        <thead>
                            <tr className="thead-dark">
                                <th>Items Name</th>
                                <th>Qty</th>
                                <th>Vendor</th>
                                <th>Delivery Status</th>
                                <th> Delivery Date</th>
                                <th> Cost</th>
                            </tr>

                        </thead>
                        <tbody>
                            {tablenew.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.itemsname}</td>
                                    <td>{item.qty}</td>
                                    <td>{item.vendor}</td>
                                    <td>{item.Status}</td>
                                    <td>{item.date}</td>
                                    <td>{item.cost}</td>
                                </tr>
                            ))}
                        </tbody>

                    </Table>
                    
                    </div>
                    <div className="tfoot d-flex justify-content-between">
                        <div className="tfoot-column text-center">
                            <h6><strong>Total Items</strong></h6>
                            <h6>5</h6>
                        </div>
                        <div className="tfoot-column text-center">
                            <h6><strong>Total Qty</strong></h6>
                            <h6>90</h6>
                        </div>
                        <div className="tfoot-column text-center">
                            <h6><strong>Delivery Status</strong></h6>
                            <h6>Complete</h6>
                        </div>
                        <div className="tfoot-column text-center">
                            <h6><strong>Date</strong></h6>
                            <h6>15/12/2024</h6>
                        </div>
                    </div>

                </Card.Body>

            </Card>
            <Card>
                <Card.Header>
                    <Card.Title>Account Details</Card.Title>
                </Card.Header>
                <Card.Body>
                <div className="table-responsive">

                    <Table striped bordered hover>
                        <thead>
                            <tr className="thead-dark">
                                <th>Sr.No</th>
                                <th>Date</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tablenew.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.date}</td>
                                    <td>{item.cost}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={2} className='text-end' style={{ width: "87%" }}><strong>Due Amount</strong> 25,500</td>
                                <td className='text-end'><strong>Total Amount</strong> 25,500</td>
                            </tr>
                        </tfoot>
                    </Table>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}
