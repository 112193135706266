import React, { useState } from 'react'
import { Row, Col, Form, Card } from 'react-bootstrap';
import Select from 'react-select'
import { FaRegPlusSquare } from "react-icons/fa";
import Vendorreportfile from './Vendorreportfile'
export default function Createaccount() {
    const data = [
        { value: 'Rohit', label: 'rohit' }
    ];
    const accounttype = [
        { value: 'Cash', label: 'Cash' },
        { value: 'Bank', label: 'Bank' },
    ];
    const [transaction, setTransaction] = useState(false);

    const Transtionshow = () => {
        setTransaction(true);
    }
    const [Balancesheet, setBalancesheet] = useState(false);

    const Balancesheetshow = () => {
        setBalancesheet(true);
    }
    return (
        <div className="Createaccount">
            <Card>
                <Card.Header>
                    <Card.Title>Create Account</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={5}>
                            <Form.Group>
                                <Form.Label>Select Employee</Form.Label>
                                <Select
                                    options={data}
                                />

                            </Form.Group>
                        </Col>
                        <Col md={5}>
                            <Form.Group>
                                <Form.Label>Account type</Form.Label>
                                <Select
                                    options={accounttype}
                                />

                            </Form.Group>
                        </Col>
                        <Col md={2} className="d-flex justify-content-end align-items-end">
                            <div className="d-flex justify-content-end align-items-end">
                                <button className="btn-added d-flex gap-1" onClick={Transtionshow}>
                                    <FaRegPlusSquare />  Create
                                </button>
                            </div>
                        </Col>
                    </Row>

                </Card.Body>
            </Card>
            {transaction && (
                <Card>
                    <Card.Header>
                        <Card.Title>Transaction</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Transfer Form </Form.Label>
                                    <Select
                                        options={data}
                                    />

                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Transfer To</Form.Label>
                                    <Select
                                        options={accounttype}
                                    />

                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Transfer Amount</Form.Label>
                                    <Form.Control type="number">

                                    </Form.Control>

                                </Form.Group>
                            </Col>
                            <Col md={3} className="d-flex justify-content-end align-items-end">
                                <div className="d-flex justify-content-end align-items-end">
                                    <button className="btn-added d-flex gap-1" onClick={Balancesheetshow}>
                                        Transfer
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}

            {Balancesheet && (
                <Card>
                    <Card.Body>
                        <Vendorreportfile />
                    </Card.Body>
                </Card> 
            )}

        </div>
    )
}