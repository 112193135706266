import React, { useMemo, useState, useEffect } from 'react';
import Rawmaterialsdetails from './Rawmaterialsdetails';
import { FiPlusCircle } from "react-icons/fi";
import { HiOutlineMinusCircle } from "react-icons/hi";
import { Row, Col, Card, Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const OrderProductRawMaterialView = () => {
  const token = localStorage.getItem('token');
  const orderProductId = localStorage.getItem("orderRawproduct_id");
  const [orderlist, setOrderList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderlist = async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}Order-view-product-list-raw/${orderProductId}`;
        const config = {
          method: 'get',
          url: url,
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        };
        const response = await axios(config);
        setOrderList(response.data.data || []);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchOrderlist();
  }, [token, orderProductId]);

  const [counters, setCounters] = useState(Array.from({ length: 30 }, () => 0));

  const increase = (index) => {
    const newCounters = [...counters];
    newCounters[index] += 1;
    setCounters(newCounters);
  };

  const decrease = (index) => {
    const newCounters = [...counters];
    if (newCounters[index] > 0) {
      newCounters[index] -= 1;
      setCounters(newCounters);
    }
  };

  const Vendorproduct = useMemo(
    () => [
      {
        Header: 'Sr No',
        accessor: (row, i) => i + 1,
      },
      {
        Header: 'Raw Materials',
        accessor: 'raw_name',
      },
      {
        Header: 'Vendor Name',
        accessor: 'vender_id',
      },
      {
        Header: 'Unit',
        accessor: 'unit_type',
      },
      {
        Header: 'QTY',
        accessor: 'qty',
      },
      {
        Header: 'Cost Price',
        accessor: 'u_cost_prize',
      },
      {
        Header: 'Recive',
        accessor: 'Recive',
        Cell: ({ row, row: { index } }) => (
          <div className="d-flex gap-2 align-items-center product-quantity">
            <button className="btn fs-5" onClick={() => decrease(index)}>< HiOutlineMinusCircle /></button>
            <p className='mb-0 pb-0'>{counters[index]}</p>
            <button className="btn fs-5" onClick={() => increase(index)}><FiPlusCircle /></button>
          </div>
        ),
      },
    ],
    [counters]
  );

  return (
    <>
      <Rawmaterialsdetails columns={Vendorproduct} data={orderlist} />
    </>
  );
};

export default OrderProductRawMaterialView;
