import React, { useState } from 'react';
import { Container, Row, Col, Form, Modal, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Swal from 'sweetalert2';
const useFormFields = (initialFields) => {
    const [fields, setFields] = useState(initialFields);
    const [errors, setErrors] = useState(
        Object.keys(initialFields).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    );

    const handleFieldChange = (key) => (e) => {
        let value = e.target.value;

        // Ensure numeric input and limit to 10 digits for mobileNumber
        if (key === 'mobileNumber') {
            value = value.replace(/\D/g, ''); // Remove non-numeric characters
            value = value.slice(0, 10); // Limit to 10 characters
        }
        if (key === 'bankAccountNumber') {
            value = value.replace(/\D/g, '');
            // value = value.slice(0, 10);
        }
        if (key === 'ifscCode') {
            value = value.toUpperCase();
            value = value.slice(0, 11);
        }
        if (key === 'gst') {
            value = value.toUpperCase();
            value = value.slice(0, 21);
        }

        // Update fields state
        setFields({ ...fields, [key]: value });
        setErrors({ ...errors, [key]: false });
    };


    return { fields, errors, handleFieldChange, setFields, setErrors };
};

const validateFields = (fields, setErrors) => {
    const newErrors = {};
    let isValid = true;

    // Example validation rules
    if (!fields.vendorName) {
        newErrors.vendorName = true;
        isValid = false;
    }

    
    setErrors(newErrors);
    return isValid;
};

const VendorForm = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    const initialFields = {
        vendorName: '',
        vendorShortName: '',
        mobileNumber: '',
        gst: '',
        cityName: '',
        state: '',
        pinCode: '',
        bankAccountNumber: '',
        accountHolderName: '',
        ifscCode: '',
        accountType: '',
        addressLine1: '',
        addressLine2: ''
    };

    const { fields, errors, handleFieldChange, setFields, setErrors } = useFormFields(initialFields);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        const payload = {
            vendor_name: fields.vendorName,
            vendor_short_name: fields.vendorShortName,
            mobile_number: fields.mobileNumber,
            gst: fields.gst,
            city_name: fields.cityName,
            state: fields.state,
            pincode: fields.pinCode,
            bank_account_number: fields.bankAccountNumber,
            account_holder_name: fields.accountHolderName,
            ifsc_code: fields.ifscCode,
            account_type: fields.accountType,
            address_line1: fields.addressLine1,
            address_line2: fields.addressLine2,
            status: '0'
        };
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Vender-store`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
    
            if (response.data.status === 1) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.message,
                    showConfirmButton: false,
                    timer: 1500
                });
    
                setFields(initialFields);
                setLoading(false);
                setTimeout(() => {
                    navigate('/vendermanagement');
                }, 1000);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.message,
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'An error occurred',
                text: 'Something went wrong, please try again later.',
            });
            setLoading(false);
        }
    };
    

    const handleSubmitVendor = () => {
        if (validateFields(fields, setErrors)) {
            setLoading(true);
            handleSubmit();
        } else {
            setLoading(false);
        }
    };

    return (
        <div id="addVendorModal">
            <div className="page-header">
                <div className="add-item d-flex justify-content-between">
                    <div className="page-title">
                        <h4>Add Vendor</h4>
                        <h6>Vendor</h6>
                    </div>
                </div>
                <div className="button_add_row">

                </div>
            </div>
            <Card>
                <Card.Header>
                    <Card.Title>
                        Add-Vendors
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Container fluid>
                        <Row>
                            {Object.keys(initialFields).map((key, index) => (
                                <Col lg={4} sm={6} xs={12} key={index}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="custom-label">
                                            {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={key.replace(/([A-Z])/g, ' $1')}
                                            value={fields[key]}
                                            onChange={handleFieldChange(key)}
                                            className={errors[key] ? 'is-invalid' : ''}
                                        />
                                        {/* {errors[key] && <Form.Control.Feedback type="invalid">Please enter a valid {key.replace(/([A-Z])/g, ' $1')}.</Form.Control.Feedback>} */}
                                    </Form.Group>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" className="btn btn-submit" onClick={handleSubmitVendor}>
                            Create
                        </Button>
                    </div>
                </Card.Footer>
            </Card>
            <ToastContainer />



        </div>
    );
};

export default VendorForm;
