import { Container, Row, Col, Card, Dropdown, Button } from 'react-bootstrap';
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { Modal } from 'react-bootstrap';
import { MdOutlineDashboard } from "react-icons/md";
import { RiCalendarLine } from 'react-icons/ri';
import React, { useState, useEffect, useRef } from 'react';
import CustomerChart from './CustomerCharts'
import ExpenditureChart from './ExpenditureChart'
import VendorChart from './VenderChart';
import TotalstockChart from './TotalstockChart';
import PieChart from './PieChart';
import Order from './Order'
import { GiHumanTarget } from "react-icons/gi";
import { Link } from 'react-router-dom'
import axios from "axios";
import Pending_Order from './DashboardPages/PendingOrder';
import Today_Order from './DashboardPages/TodayOrder';
import { MdOutlinePendingActions } from "react-icons/md";
import { IoMdToday } from "react-icons/io";
import { MdUpcoming } from "react-icons/md";
export default function Home() {
  const [data, setViewData] = useState({});
  const token = localStorage.getItem('token');
  const [modalState, setModalState] = useState({ todayOrder: false, upcomingOrder: false, pendingOrder: false });

  useEffect(() => {
    fetchOrderlist()
  }, [])

  const fetchOrderlist = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}Dashbord-order-list`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      const orders = response.data.data || [];

      setViewData(orders);

    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
  return (
    <>
      <Container fluid className="home">
      <Row>
            
            <Col md={4} xs={6} s={6} lg={4}>
               <Link onClick={() => setModalState({ ...modalState, pendingOrder: true })} >
                 <div className="card mb-3 w-100 bg-primarynew">
                   <div className="card-body p-3">
                     <div className="d-flex align-items-center">
                       <div className="p-2  rounded-circle me-3">
                         <div className="icon-box md  rounded-5">
                           <MdOutlinePendingActions className="text-white font-size-20" />
                         </div>
                       </div>
                       <div className="d-flex flex-column">
                         <h2 className="m-0 lh-1 text-primary">{data.pending_order}</h2>
                         <p className="m-0 font10 ">Pending Order</p>
                       </div>
                     </div>
                     <div className="d-flex align-items-center justify-content-between mt-1">

                     </div>
                   </div>
                 </div>
               </Link>
             </Col>
             <Col md={4} xs={6} s={6} lg={4} >
               <Link onClick={() => setModalState({ ...modalState, TodayOrder: true })} >
                 <div className="card mb-3 w-100 bg-successnew">
                   <div className="card-body p-3">
                     <div className="d-flex align-items-center">
                       <div className="p-2  rounded-circle me-3">
                         <div className="icon-box md  rounded-5">
                           <IoMdToday className="text-white font-size-20" />
                         </div>
                       </div>
                       <div className="d-flex flex-column">
                         <h2 className="m-0 lh-1 text-primary">{data.today_order}</h2>
                         <p className="m-0 font10 ">Today Order</p>
                       </div>
                     </div>
                     <div className="d-flex align-items-center justify-content-between mt-1">
                     </div>
                   </div>
                 </div>
               </Link>
             </Col>

             <Col md={4} xs={6} s={6} lg={4} >
               <Link onClick={() => setModalState({ ...modalState, UpcomingOrder: true })} >
                 <div className="card mb-3 w-100 bg-infonew">
                   <div className="card-body p-3">
                     <div className="d-flex align-items-center">
                       <div className="p-2  rounded-circle me-3">
                         <div className="icon-box md  rounded-5">
                           <MdUpcoming className="text-white font-size-20" />
                         </div>
                       </div>
                       <div className="d-flex flex-column">
                         <h2 className="m-0 lh-1 text-primary">{data.order_upcoming}</h2>
                         <p className="m-0 font10 ">Upcoming Order</p>
                       </div>
                     </div>
                     <div className="d-flex align-items-center justify-content-between mt-1">

                     </div>
                   </div>
                 </div>
               </Link>
             </Col>
         </Row>
        <Row className="sales-board">
          <Col md={4} lg={4} sm={4} xs={12}>
            <CustomerChart />

          </Col>

          <Col md={4} lg={4} sm={4} xs={12}>
            <ExpenditureChart />

          </Col>
          <Col md={4} lg={4} sm={4} xs={12}>
            {/* <Card className="flex-fill default-cover vendor-card"> */}
             
              <Card.Body>
                <VendorChart />
              </Card.Body>
            {/* </Card> */}
          </Col>
          <Col md={4} lg={4} sm={4} xs={12}>
            <TotalstockChart />

          </Col>
         
          <Col md={4}>
            <Card className="flex-fill default-cover">
              
              <Card.Header>
                <h5 className="card-title mb-0">Customer Due Amount</h5>
              </Card.Header>
              <Card.Body className="p-1">
                <PieChart />
              </Card.Body>
            </Card>
          </Col>

        </Row>
        {/* <Row> */}





        {/* </Row> */}
      </Container>

      <Modal
        show={modalState.pendingOrder}
        size='lg'
        onHide={() => setModalState({ ...modalState, pendingOrder: false })}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static" 
          keyboard={false}
      >
        <Modal.Header closeButton>
          <h3 className="card-title mb-0">Pending Order</h3>
        </Modal.Header>
        <Modal.Body>
          <Pending_Order onSave={() => setModalState({ ...modalState, pendingOrder: false })} />
        </Modal.Body>
      </Modal>


      <Modal
        show={modalState.TodayOrder}
        size='lg'
        onHide={() => setModalState({ ...modalState, TodayOrder: false })}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static" 
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h3 className="card-title mb-0">Today Order</h3>
        </Modal.Header>
        <Modal.Body>
          <Today_Order onSave={() => setModalState({ ...modalState, TodayOrder: false })} />
        </Modal.Body>
      </Modal>

      <Modal
        show={modalState.UpcomingOrder}
        size='lg'
        onHide={() => setModalState({ ...modalState, UpcomingOrder: false })}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static" 
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h3 className="card-title mb-0">Upcoming Order</h3>
        </Modal.Header>
        <Modal.Body>
          <Today_Order onSave={() => setModalState({ ...modalState, UpcomingOrder: false })} />
        </Modal.Body>
      </Modal>
    </>
  )
}
