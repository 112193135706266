import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { Card } from 'react-bootstrap';

const VenderChart = () => {
  const token = localStorage.getItem('token');

  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: 'basic-bar',
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [], // Keep this empty, will not display on the x-axis
      labels: {
        show: false, // Hide x-axis labels
      },
      show: true, // Completely hide x-axis
    },
    yaxis: {
      show: true, // Completely hide y-axis
    },
    colors: ['#fff9ec', '#f3f4f6', '#f7f3e9'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false, // Disable data labels to prevent vendor names from showing on the bars
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const vendorName = w.config.xaxis.categories[dataPointIndex]; // Get vendor name based on dataPointIndex
        const dueAmount = series[seriesIndex][dataPointIndex].toLocaleString();
        return `<div class="arrow_box">
                  <span>Vendor: ${vendorName}</span><br/>
                  <span>Due Amount: ₹${dueAmount}</span>
                </div>`;
      },
      style: {
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      fillSeriesColor: false,
      marker: {
        show: false,
      },
      background: '#000',
      textStyle: {
        color: '#fff',
      },
    },
    stroke: {
      show: false,
      width: 2,
      colors: ['#fff'],
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
    },
  });

  const [chartSeries, setChartSeries] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Vender-list-due-amount`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        }
      );

      const data = response.data.data || [];

      // Extract vendor names and due amounts
      const vendorNames = data.map((vendor) => vendor.vendor_name || []); // Get vendor names for categories
      const dueAmounts = data.map((vendor) => vendor.due_amount || []);

      // Set chart series with due amounts
      setChartSeries([
        {
          name: 'Due Amount',
          data: dueAmounts,
        },
      ]);

      // Update x-axis categories with vendor names (keep for tooltip usage)
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: vendorNames, // Set vendor names here (will not be displayed on the x-axis)
        },
      }));
    } catch (error) {
      console.error('Error fetching chart data:', error);
      setChartSeries([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  return (
    <Card className="flex-fill default-cover vendor-chartt" id="">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h5 className="card-title mb-0 text-white">Vendor Due Amount</h5>
      </Card.Header>
      <Card.Body className="padding_50">
        <div className="app">
          <div className="chart-container position-relative">
            <div className="mixed-chart" style={{ width: '100%' }}>
              <Chart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height={412}
              />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default VenderChart;
