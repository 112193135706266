import React, { useState } from 'react'
import { Row, Col, Modal, Card, Form, } from 'react-bootstrap'
import Orderdetails from './Orderdetails/Orderdetails'
import VendorProductdetails from './Venderrecive/VenderProductdetails'
import Rawmaterial from './Venderrecive/Rawmaterial'
import Actualrecivehistorydetails from './Venderrecive/Actualrecivehistory/Actualrecivehistory'
import Venderpayment from './Venderrecive/Venderpayment/Venderpayment'
import Customerpayment1 from './Venderrecive/Venderpayment/Customerpayment'
import Paymentdetails from './Venderrecive/Venderpayment/Paymentdetails'
import Vendorreportfile from './Venderrecive/Venderpayment/Vendorreportfile'
import Ordertakeprocessing from './Venderrecive/OrdertakeProcessing/Ordertakeprocessing'
import Billsdetails from './Venderrecive/OrdertakeProcessing/Billsdetails'
import Shippinghistory from './Venderrecive/OrdertakeProcessing/Shippinghistory'
import Customerpayment from './Venderrecive/OrdertakeProcessing/Customerpayment'
import Paymentreport from './Venderrecive/OrdertakeProcessing/Paymentreport'

const Orderview = () => {
    const [tabs, setTabs] = useState(0);
    const tabsclick = (index) => {
        setTabs(index);
    }
    return (
        <section className='orderview_section'>
            {/* <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>Order View</h4>
                        <h6>Manage Order View</h6>
                    </div>
                </div>

                <div className="page-btn d-flex gap-2">

                </div>
            </div> */}
            <Card>
                <Card.Header className="d-flex justify-content-between">
                    <Card.Title className='mb-0'>Order View</Card.Title>
                    <div className='d-flex gap-2'>
                        <button onClick={() => tabsclick(0)} className={tabs === 0 ? 'btn-added' : 'btn_tab_active'}>Order Details</button>
                        <button onClick={() => tabsclick(1)} className={tabs === 1 ? 'btn-added' : 'btn_tab_active'}>Vendor Receive</button>
                        {/* <button onClick={() => tabsclick(2)} className={tabs === 2 ? 'btn-added' : 'btn_tab_active'}>Vendor Payment</button> */}
                        <button onClick={() => tabsclick(4)} className={tabs === 4 ? 'btn-added' : 'btn_tab_active'}>Customer Payment</button>
                        <button onClick={() => tabsclick(3)} className={tabs === 3 ? 'btn-added' : 'btn_tab_active'}>Order Processing</button>
                    </div>
                </Card.Header>
                <Card.Body >
                    {tabs === 0 && (
                        <Orderdetails />
                    )}
                    {tabs === 1 && (
                        <div id="venderrecive">
                            <VendorProductdetails />
                            {/* <Rawmaterial /> */}
                            {/* <hr /> */}
                            {/* <Actualrecivehistorydetails /> */}
                        </div>
                    )}
                    {tabs === 2 && (
                        <div >
                            <Venderpayment />

                            {/* <hr /> */}
                            {/* <Paymentdetails /> */}
                            {/* <hr /> */}
                            {/* <Vendorreportfile /> */}
                        </div>
                    )}
                    {tabs === 4 && (
                        <div >
                            <Customerpayment1 />
 
                          
                        </div>
                    )}
                    {tabs === 3 && (
                        <>
                            <Ordertakeprocessing />
                            <hr />
                            {/* <Billsdetails /> */}
                            {/* <hr /> */}
                            {/* <Shippinghistory /> */}
                            {/* <hr /> */}
                            {/* <Customerpayment /> */}
                            {/* <hr /> */}
                            {/* <Paymentreport /> */}
                        </>
                    )}
                </Card.Body>
            </Card>

        </section>
    )
}

export default Orderview
