import React, { useState, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import axios from 'axios';
import PieDesign from './DashboardPages/PieDesign'; // Ensure correct path
import PieRetail from './DashboardPages/PieRetail';
import PieSites from './DashboardPages/PieSites';
// Import the Bootstrap Modal component
import { Modal as BootstrapModal } from 'react-bootstrap'; // Ensure react-bootstrap is installed

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const PieChart = () => {
  const [modalState, setModalState] = useState({ Design: false, Retail: false, Site: false });

  const handleSliceClick = (e) => {
    const label = e.dataPoint.label;
    if (['Retail', 'Site', 'Design'].includes(label)) {
      setModalContent({
        label: label,
        value: e.dataPoint.y
      });
      setModalState(prevState => ({ ...prevState, [label]: true }));
    }
  };
  const [chartOptions, setChartOptions] = useState({
    exportEnabled: false,
    animationEnabled: true,
    data: [{
      type: "pie",
      startAngle: 75,
      toolTipContent: "<b>{label}</b>: {y}",
      showInLegend: true,
      legendText: "{label}",
      indexLabelFontSize: 16,
      indexLabel: "{label} - {y}",
      indexLabelPlacement: "inside",
      dataPoints: [],
      click: handleSliceClick
    }]
  });

  const [modalContent, setModalContent] = useState(null); // State for modal content

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}Dashbord-Customer-due-payment-lise`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      });

      const data = response.data.data || [];

      // Format the data for the pie chart
      const dataPoints = data.map(item => ({
        y: item.total_amount,
        label: item.customer_type,
        color: getColor(item.customer_type),
        indexLabelFontColor: "#FFFFFF"
      }));

      setChartOptions(prevOptions => ({
        ...prevOptions,
        data: [{
          ...prevOptions.data[0],
          dataPoints
        }]
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getColor = (type) => {
    // Define colors based on customer type
    switch (type) {
      case 'Retail':
        return '#f72b50';
      case 'Site':
        return '#ba1654';
      case 'Design':
        return '#008000';
      default:
        return '#000000'; // Fallback color
    }
  };

  

  const closeModal = () => {
    setModalState({ Design: false, Retail: false, Site: false });
    setModalContent(null);
  };

  return (
    <div style={{ height: '100%', width: '100%' }} className='position-relative'>
      <CanvasJSChart options={chartOptions} />
      {modalContent && (
        <BootstrapModal
          show={modalState[modalContent.label]}
          onHide={closeModal}
          size='lg'
          aria-labelledby="modal-title"
          backdrop="static" 
          keyboard={false}
        >
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title id="modal-title">{modalContent.label} Details</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            {/* Conditionally render the relevant modal based on content.label */}
            {modalContent.label === 'Design' && <PieDesign content={modalContent} closeModal={closeModal} />}
            {modalContent.label === 'Retail' && <PieRetail content={modalContent} closeModal={closeModal} />}
            {modalContent.label === 'Site' && <PieSites content={modalContent} closeModal={closeModal} />}
          </BootstrapModal.Body>
        </BootstrapModal>
      )}
    </div>
  );
};

export default PieChart;
