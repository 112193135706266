import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, Row, Col, Form, Card } from 'react-bootstrap';
import { MdAddCircle } from 'react-icons/md';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Product from '../../assets/img/product.png';
import DataTable from './MaterialReceiveTable';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StockManagement = () => {
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [data, setVendorList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  // Fetch data from API
  const fetchVendor = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}material-store-all-product-list`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });

      const receivedData = response.data.data || [];
      setVendorList(receivedData);
      setFilteredData(receivedData);

      // Calculate totals based on full data
      calculateTotals(receivedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      // toast.error('Failed to fetch data.');
    }
  };

  useEffect(() => {
    fetchVendor();
  }, []);

  const calculateTotals = (data) => {
    const totalQuantity = data.reduce((sum, item) => sum + (parseFloat(item.met_qty) || 0), 0);

    const totalAmount = data.reduce((sum, item) => {
      const metCost = parseFloat(item.met_cost) || 0;
      const gst = parseFloat(item.gst) || 0;
      return sum + (metCost + (metCost * gst / 100));
    }, 0);

    setTotalQuantity(totalQuantity);
    setTotalAmount(totalAmount);
  };

  useEffect(() => {
    if (searchInput.trim() === '') {
      // If the search input is empty, show all data
      setFilteredData(data);
      calculateTotals(data);
    } else {
      const searchTerm = searchInput.toLowerCase();

      const filtered = data.filter((item) => {
        const metNameMatches = item.met_name?.toLowerCase().includes(searchTerm);
        const locationMatches = item.product_stock_location_name?.toLowerCase().includes(searchTerm);

        return metNameMatches || locationMatches;
      });

      setFilteredData(filtered); // Update the filtered data

      // Recalculate totals based on filtered data
      calculateTotals(filtered);
    }
  }, [searchInput, data]);

  const columns = useMemo(
    () => [
      {
        Header: 'Sr.No',
        accessor: 'Srno',
        Cell: (row) => row.row.index + 1,
      },
      {
        Header: 'Location',
        accessor: 'product_stock_location_name',
        Cell: ({ value }) => value || 'N/A',
      },
      {
        Header: 'Product Name',
        accessor: 'met_name',
      },
      {
        Header: 'Qty',
        accessor: 'met_qty',
        Cell: ({ value }) => value || 'N/A',
      },
      {
        Header: 'Rate',
        accessor: 'met_cost',
        Cell: ({ value }) => (
          value ? `₹${new Intl.NumberFormat().format(parseFloat(value).toFixed(2))}` : 'N/A'
        ),
      },
      {
        Header: 'Gst (%)',
        accessor: 'gst',
        Cell: ({ value }) => value ? `${parseFloat(value).toFixed(2)}%` : 'N/A',
      },
      {
        Header: 'Total Cost',
        id: 'totalCostInclGst',
        Cell: ({ row }) => {
          const metCost = parseFloat(row.original.met_cost) || 0;
          const gst = parseFloat(row.original.gst) || 0;
          const totalCost = metCost + (metCost * gst / 100);
          return `₹${new Intl.NumberFormat().format(totalCost.toFixed(2))}`;
        },
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => value || 'N/A',
      },
    ],
    []
  );


  return (
    <>
      <div>
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Manage Stock</h4>
              <h6>Manage your stock</h6>
            </div>
          </div>

          <div className="page-btn">
            <Link to="/Receive-Materials" className="btn btn-added d-flex gap-2">
              <MdAddCircle className="me-2" /> Back
            </Link>
          </div>
        </div>

        <Row>
          <Col md={12}>
            <Card style={{ height: 245 }}>
              <Card.Header>
                <div className="card-title">Stock Report</div>
              </Card.Header>
              <Card.Body>
                <Row className="d-flex justify-content-between">
                  <Col lg={4}>
                    <Form.Label>Search Product and Location</Form.Label>
                    <input
                      type="text"
                      value={searchInput}
                      className="form-control"
                      onChange={(e) => setSearchInput(e.target.value)} // Update search input
                      placeholder="Search product"
                    />
                  </Col>
                  <Col lg={5}>
                    <div className="sales-cards">
                      <div className="card d-flex align-items-center justify-content-between default-cover mb-4">
                        <div>
                          <h6>Total Products</h6>
                          <h3>Total Quantity: {totalQuantity}</h3>
                          <span>
                            Total Amount: {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(totalAmount)}
                          </span>
                        </div>
                        <img src={Product} alt="img" width={90} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <DataTable columns={columns} data={filteredData} /> {/* Use filtered data */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default StockManagement;
