import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export default function Category_Expenditure_Edit({ onSave }) {
    const [design_category, setName] = useState(''); // For the input field
    const [expenditure_id, setDesignCategoryId] = useState(''); // For the selected category ID
    const [nameError, setNameError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [design_category_list, setDesignCategoryList] = useState([]);
    const token = localStorage.getItem("token");

    // Handle input change for design category name
    const onInputChangeName = (e) => {
        setName(e.target.value);
        setNameError(false); // Reset the error state
    };

    // Handle dropdown change for design category selection
    const handleRowChangeDesign = (value) => {
        setDesignCategoryId(value); // Update the selected category ID
        const selectedCategory = design_category_list.find(category => category.id === value);
        if (selectedCategory) {
            setName(selectedCategory.design_category_name); // Auto-fill the input field
        } else {
            setName(''); // Clear the input field if no category is selected
        }
    };

    // Fetch design category list on component mount
    useEffect(() => {
        const DesignCategoryList = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}expenditure-category-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setDesignCategoryList(response.data.data || []);
            } catch (error) {
                console.error('Error fetching design categories:', error);
            }
        };
        DesignCategoryList();
    }, [token]);

    // Submit the form data
    const Submit = async () => {
        setLoading(true);
        if (!design_category) {
            setNameError(true);
            setLoading(false);
            return;
        }
        try {
            let url = `${process.env.REACT_APP_API_URL}expenditure-category-update`;
            const requestData = { expenditure_category_name: design_category, id: expenditure_id };
            const config = {
                method: 'POST',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
                data: requestData,
                
            };

            const response = await axios(config);

            if (response.data.status === 1) {
                toast.success(response.data.message);
                setName('');
                setDesignCategoryId('');
                onSave();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
            toast.error("Failed to update the design category. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="Category">
          
            {/* <Row> */}
                <Col md={8} sm={8}>
                    <Form.Group>
                        <Form.Label>Category Design Edit</Form.Label>
                        <Form.Select
                            value={expenditure_id}
                            onChange={(e) => handleRowChangeDesign(e.target.value)}
                        >
                            <option value="">Select</option>
                            {design_category_list.map((vendor) => (
                                <option key={vendor.id} value={vendor.id}>
                                    {vendor.expenditure_category_name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={8} lg={8} col={8} className="justify-content-center">
                    <Form.Group controlId="addCategoryName">
                        <Form.Label>Design Category Edit</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Design Category Edit"
                            value={design_category}
                            onChange={onInputChangeName} // Updated here
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    Submit();
                                }
                            }}
                            className={nameError ? 'is-invalid' : ''}
                        />
                        {nameError && <Form.Control.Feedback type="invalid">Please enter a Design Category Edit.</Form.Control.Feedback>}
                    </Form.Group>
                </Col>
            {/* </Row> */}
            <Modal.Footer className="d-flex justify-content-end">
                <Button variant="primary" onClick={Submit} disabled={loading}>
                    {loading ? 'Updating...' : 'Update'}
                </Button>
            </Modal.Footer>

            {/* <ToastContainer /> */}
        </div>
    );
}
