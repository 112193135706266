import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function Addproductmatrial({ onSave }) {
    const token = localStorage.getItem('token');

    const initialFields = {
        Date: '',
        VenderName: '',
        CustumerName: '',
        Qty: '',
        UnitPrice: '',
        item_id: '',
    };

    const [fields, setFields] = useState(initialFields);
    const [loading, setLoading] = useState(false);
    const [selectoption, setSelectoption] = useState(null);
    const [venderlist, setVenderlist] = useState([]);
    const [errors, setErrors] = useState({});
    const [vendorData, setVendorData] = useState([]); // Add state to store vendor data
    const navigate = useNavigate();
    const [custumerlist, setCustumerlist] = useState([]);


    const handleChange = (value, name) => {
        console.log("handleChange:", name, value);
        if (name === 'item_id') {
            setSelectoption(value);
            setFields({ ...fields, item_id: value });
        } else if (name === 'VenderName') {
            setFields({ ...fields, VenderName: value });
            VenderView(value);
        }
        else if (name === 'CustumerName') {
            setFields({ ...fields, CustumerName: value });
            fetchVendor(value);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log("handleInputChange:", name, value);
        setFields({ ...fields, [name]: value });
    };

    const validateFields = () => {
        const errors = {};
        if (!fields.Date) errors.Date = 'Date is required';
        if (!fields.CustumerName) errors.CustumerName = 'Custumer Name is required';
        if (!fields.VenderName) errors.VenderName = 'Vendor Name is required';
        if (!fields.Qty) errors.Qty = 'Quantity is required';
        if (!fields.UnitPrice) errors.UnitPrice = 'Unit Price is required';
        if (!fields.item_id) errors.item_id = 'Item Name is required';
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateFields();
        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }
        setErrors({});
        setLoading(true);
        const payload = {
            date: fields.Date,
            vendor_id: fields.VenderName,
            custumer_id: fields.CustumerName,
            qty: fields.Qty,
            unit_price: fields.UnitPrice,
            item_name: fields.item_id,
            item_id: fields.item_id,
        };

        // console.log("Payload:", payload); // Log the payload

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Add-Receive-Product-Row-Matrials-store`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setFields(initialFields);
                setSelectoption(null);
                onSave();
                // RowMatrialslist();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const VenderView = async (vendorId) => {
        try {
            let url = `${process.env.REACT_APP_API_URL}Vender-order-details-list/${vendorId}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            setVendorData(response.data.data || []); // Set the vendor data in state
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        }
    };

    // useEffect(() => {
    const fetchVendor = async (vendorId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Vendor-select-receive-list/${vendorId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setVenderlist(response.data.data);
        } catch (error) {
            console.error('Error fetching Vendor:', error);
        }
    };

    //     fetchVendor();
    // }, [token]);
    useEffect(() => {
        const fetchCustumer = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Custumer-name-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setCustumerlist(response.data.data || []);
            } catch (error) {
                console.error('Error fetching Vendor:', error);
            }
        };

        fetchCustumer();
    }, [token]);

    const options = [
        { value: 'Product', label: 'Product' },
        { value: 'RawMatrials', label: 'Raw Matrials' },
    ];

    return (
        <div>
            <Card>
                <Card.Header closeButton>
                    <Card.Title>Add Receive Product</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={4} sm={12}>
                            <Form.Group>
                                <Form.Label>Select Date</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Date"
                                    name="Date"
                                    value={fields.Date}
                                    onChange={handleInputChange}
                                    style={{ borderColor: errors.Date ? 'red' : '' }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4} sm={12}>
                            <Form.Group>
                                <Form.Label>Custumer Name</Form.Label>
                                <Form.Select
                                    value={fields.CustumerName}
                                    onChange={(e) => handleChange(e.target.value, 'CustumerName')}
                                    style={{ borderColor: errors.CustumerName ? 'red' : '' }}
                                >
                                    <option value="">Select Custumer Name</option>
                                    {custumerlist.map((vendor) => (
                                        <option key={vendor.id} value={vendor.id}>
                                            {vendor.customer_name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={4} sm={12}>
                            <Form.Group>
                                <Form.Label>Vendor Name</Form.Label>
                                <Form.Select
                                    value={fields.VenderName}
                                    onChange={(e) => handleChange(e.target.value, 'VenderName')}
                                    style={{ borderColor: errors.VenderName ? 'red' : '' }}
                                >
                                    <option value="">Select Vendor Name</option>
                                    {venderlist.map((vendor) => (
                                        <option key={vendor.id} value={vendor.vender_id}>
                                            {vendor.vendor_name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={4} sm={12}>
                            <Form.Group>
                                <Form.Label>Product Name</Form.Label>
                                <Form.Select
                                    value={fields.item_id}
                                    onChange={(e) => handleChange(e.target.value, 'item_id')}
                                    style={{ borderColor: errors.item_id ? 'red' : '' }}
                                >
                                    <option value="">Select Product Name</option>
                                    {vendorData.map((vendor) => (
                                        <option key={vendor.productname} value={vendor.product_id + ',' + vendor.product_order_id}>
                                            {vendor.productname}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={4} sm={12}>
                            <Form.Group>
                                <Form.Label>Qty</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Qty"
                                    name="Qty"
                                    value={fields.Qty}
                                    onChange={handleInputChange}
                                    style={{ borderColor: errors.Qty ? 'red' : '' }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4} sm={12}>
                            <Form.Group>
                                <Form.Label>Unit Price</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Unit Price"
                                    name="UnitPrice"
                                    value={fields.UnitPrice}
                                    onChange={handleInputChange}
                                    style={{ borderColor: errors.UnitPrice ? 'red' : '' }}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={12} sm={12}>
                            <div className="d-flex justify-content-end">
                                <Button type="submit" className="btn-added" disabled={loading} onClick={handleSubmit}>
                                    {loading ? 'Submitting...' : 'Receive '}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <ToastContainer />
        </div>
    );
}
