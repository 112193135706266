import React, { useMemo } from 'react';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { Card } from 'react-bootstrap';
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";


const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <input
      value={filter || ''}
      onChange={(e) => setFilter(e.target.value)}
      placeholder="Search..."
      className="form-control"
    />
  );
};

const Stocktable = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }, 
    },
    useGlobalFilter,
    usePagination
  );


  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <Card>
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Stock</h5>
          <div className="d-flex gap-3">
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="form-select d-inline"
            style={{width:"120px"}}

          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <div style={{ width: '220px' }}>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </div>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="table-responsive">
          <table {...getTableProps()} className="table datanew">
            <thead style={{ backgroundColor: '#f2f2f2' }}>
              {headerGroups.map((headerGroup) => (
                <tr className="thead-dark" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
       <div className="d-flex justify-content-between align-items-center">
       <div>
            Page
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </div>
        <div className="pagination justify-content-center mt-3">
          <div onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <MdKeyboardDoubleArrowLeft/>
          </div>
          <div onClick={() => previousPage()} disabled={!canPreviousPage}>
           <MdKeyboardArrowLeft/>
          </div>
           <div>
             <h6 className="mb-0 pagination_count d-flex justify-content-center align-items-center">{pageIndex + 1} </h6>
           </div>
          <div onClick={() => nextPage()} disabled={!canNextPage}>
            <MdKeyboardArrowRight/>
          </div>
          <div onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <MdKeyboardDoubleArrowRight/>

          </div>
         
         
        </div>
       </div>

      </Card.Body>
    </Card>
  );
};

export default Stocktable;
