import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

const useFormFields = (initialFields) => {
    const [fields, setFields] = useState(initialFields);
    const [errors, setErrors] = useState(
        Object.keys(initialFields).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    );

    const handleFieldChange = (key) => (e) => {
        let value = e.target.value;

        if (key === 'MobileNumber') {
            value = value.replace(/\D/g, '').slice(0, 10);
        } else if (key === 'Ifcecode') {
            value = value.toUpperCase();
        }

        setFields({ ...fields, [key]: value });
        setErrors({ ...errors, [key]: false });
    };

    return { fields, errors, handleFieldChange, setFields, setErrors };
};

const validateFields = (fields, setErrors) => {
    const newErrors = {};
    let isValid = true;

    if (!fields.AccountType) {
        newErrors.AccountType = true;
        isValid = false;
    }
    if (!fields.AccountName) {
        newErrors.AccountName = true;
        isValid = false;
    }

    setErrors(newErrors);
    return isValid;
};

const VendorForm = ({ onSave }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    const initialFields = {
        SelectEmployName: '',
        AccountType: '',
        AccountName: '',
        AccountNumber: '',
        BankName: '',
        MobileNumber: '',
        Ifcecode: '',
        UpiId: '',
    };

    const { fields, errors, handleFieldChange, setFields, setErrors } = useFormFields(initialFields);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        const payload = {
            emp_id: fields.SelectEmployName,
            account_type: fields.AccountType,
            account_name: fields.AccountName,
            account_number: fields.AccountNumber,
            bank_name: fields.BankName,
            mobile_number: fields.MobileNumber,
            ifce_code: fields.Ifcecode,
            upi_id: fields.UpiId,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Account-store`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });

            if (response.data.status === 1) {
                Swal.fire({
                    title: 'Success',
                    text: 'Add Account Successfully!',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
                setFields(initialFields);
                setLoading(false);
                onSave();
                setTimeout(() => {
                    navigate('/Account-list');
                }, 1000);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: response.data.message,
                    icon: 'error',
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                title: 'Error',
                text: 'An error occurred during the Add',
                icon: 'error',
            });
            setLoading(false);
        }
    };

    const handleSubmitEmployee = () => {
        if (validateFields(fields, setErrors)) {
            setLoading(true);
            handleSubmit();
        } else {
            setLoading(false);
        }
    };

    return (
        <div id="addVendorModal">
            <Card>
                {/* <Card.Header>
                    <Card.Title>Add-Account</Card.Title>
                </Card.Header> */}
                <Card.Body>
                    <Container fluid>
                        <Row>
                            <Col lg={4} sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Account Type</Form.Label>
                                    <Form.Select
                                        value={fields.AccountType}
                                        onChange={handleFieldChange('AccountType')}
                                        className={errors.AccountType ? 'is-invalid' : ''}
                                    >
                                        <option value="">Select Account Type</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Online">Online</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            {fields.AccountType === 'Cash' && (
                                <>
                                    <Col lg={4} sm={6} xs={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="custom-label">Account Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Account Name"
                                                value={fields.AccountName}
                                                onChange={handleFieldChange('AccountName')}
                                                className={errors.AccountName ? 'is-invalid' : ''}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} sm={6} xs={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="custom-label">Mobile Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Mobile Number"
                                                value={fields.MobileNumber}
                                                onChange={handleFieldChange('MobileNumber')}
                                                className={errors.MobileNumber ? 'is-invalid' : ''}
                                            />
                                        </Form.Group>
                                    </Col>
                                </>
                            )}

                            {fields.AccountType === 'Online' && (
                                <>
                                    {Object.keys(initialFields).map((key, index) => (
                                        key !== 'SelectEmployName' && key !== 'AccountType' && (
                                            <Col lg={4} sm={6} xs={12} key={index}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="custom-label">
                                                        {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type={key === 'MobileNumber' || key === 'AccountNumber' ? 'number' : (key === 'Joining_date' ? 'date' : 'text')}
                                                        placeholder={key.replace(/([A-Z])/g, ' $1')}
                                                        value={fields[key]}
                                                        onChange={handleFieldChange(key)}
                                                        className={errors[key] ? 'is-invalid' : ''}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )
                                    ))}
                                </>
                            )}
                        </Row>
                    </Container>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" className="btn btn-submit" onClick={handleSubmitEmployee}>
                            Create
                        </Button>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    );
};

export default VendorForm;
