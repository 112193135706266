import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2';
import { EyeSlash, EyeFill } from "react-bootstrap-icons";

export default function Forgetpassword() {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError(false);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const validateForm = () => {
    let isValid = true;

    if (!newPassword) {
      setPasswordError(true);
      isValid = false;
    }

    if (newPassword !== password) {
      Swal.fire({
        icon: 'error',
        title: 'Password Mismatch',
        text: 'New password and confirm password do not match.',
        confirmButtonText: 'OK'
      });
      isValid = false;
    }

    return isValid;
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}forget-password`;
      const requestData = {
        password: newPassword, // Send the new password
      };

      const response = await axios.post(url, requestData, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        },
      });

      if (response.data.status === 1) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          confirmButtonText: 'OK'
        },3000).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            navigate('/');
          }
        });
        // navigate('/');
        // localStorage.removeItem('token');
        // localStorage.removeItem('userid');

        // localStorage.setItem('userid', response.data.userid);
        // localStorage.setItem('token', response.data.token);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message,
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      console.error('There was a problem with the request:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong. Please try again.',
        confirmButtonText: 'OK'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="account-content">
          <div className="login-wrapper justify-content-start align-items-start forget-wrapper forgot-pass-wrap">
            <div className="login-content justify-content-start align-items-start px-0 forget-content ">
              <form onSubmit={submit}>
                <div className="login-userset">
                  <div className="login-userheading">
                    <h3>Change password?</h3>
                    <h4>Enter New Password &amp; Confirm Password to get inside</h4>
                  </div>

                  <div className="form-login">
                    <label className="dark-mode-text">New Password</label>
                    <div className="pass-group">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        className={`pass-inputa form-control ${passwordError ? 'is-invalid' : ''}`}
                      />
                      <div className='d-flex justify-content-end toggleeyebtn'>
                        <div className='toggleeye' onClick={togglePasswordVisibility}>
                          {showPassword ? <EyeSlash className="eye" /> : <EyeFill className="eye" />}
                        </div>
                      </div>
                    </div>
                    {passwordError && <div className="invalid-feedback">New password is required.</div>}
                  </div>

                  <div className="form-login">
                    <label className="dark-mode-text">Confirm Password</label>
                    <div className="pass-group">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={handlePasswordChange}
                        className={`pass-inputa form-control ${passwordError ? 'is-invalid' : ''}`}
                      />
                      <div className='d-flex justify-content-end toggleeyebtn'>
                        <div className='toggleeye' onClick={togglePasswordVisibility}>
                          {showPassword ? <EyeSlash className="eye" /> : <EyeFill className="eye" />}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-login">
                    <button type="submit" className="btn btn-login" disabled={loading}>
                      {loading ? 'Loading...' : 'Change Password'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
