import React, { useState, useMemo, useEffect } from 'react';
import Vendorreporttable from '../Pages/Vendorreporttable'
import { FaEdit } from "react-icons/fa";
import { Card } from 'react-bootstrap'
import { MdDelete } from "react-icons/md";
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast, ToastContainer } from 'react-toastify';

export default function Leadfollowlist() {

  const [venderpaymentlist, setvandorpaymentlist] = useState([]);
  const token = localStorage.getItem('token');



  const RowMatrialslist = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}Lead-Details-list`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      setvandorpaymentlist(response.data.data || []);
    } catch (error) {
      console.error('Error fetching Vendor:', error);
    }
  };

  useEffect(() => {
    RowMatrialslist();
  }, []);
  const title = "Lead Follow-up List";
  const RowLeadDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });
      if (result.isConfirmed) {
        let url = `${process.env.REACT_APP_API_URL}Lead-Details-delete/${id}`;
        const config = {
          method: 'get',
          url: url,
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        };
        const response = await axios(config);
        if (response.data.status === 1) {
          toast.success(response.data.message);
          RowMatrialslist();
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const Vendorproduct = useMemo(
    () => [
      {
        Header: 'Sr No',
        accessor: (row, i) => i + 1,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Starting Date',
        accessor: 'date',
      },
      {
        Header: 'Deadline Date',
        accessor: 'est_date',
      },
      {
        Header: 'Action',
        accessor: 'Action',
        Cell: ({ row }) => (
          <div>
            {/* <button className="btn btn-sm btn-success"><FaEdit /></button> */}
            <button className="btn btn-sm btn-danger" onClick={() => RowLeadDelete(row.original.id)}><MdDelete /></button>
          </div>
        ),
      },

    ],
    []
  );

  return (
    <>

      <Card>
        <Card.Body>
          <Vendorreporttable title={title} columns={Vendorproduct} data={venderpaymentlist} />
        </Card.Body>
      </Card>
      <ToastContainer/>
    </>
  )
}