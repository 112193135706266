import React, { useState } from 'react';
import { Container, Row, Col, Form, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

// Hook to manage form field states and errors
const Categories = ({ onSave }) => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const token = localStorage.getItem('token');
    const [addcategory, setAddcategory] = useState(false);
    const setAddcategorynew = () => setAddcategory(true);
    const handleClosess = () => setAddcategory(false);
    const onInputChange = (setter, errorSetter) => (e) => {
        setter(e.target.value);
        errorSetter(false);
    };

    const validateFields = (fields) => {
        let isValid = true;
        fields.forEach(({ value, errorSetter }) => {
            if (!value) {
                errorSetter(true);
                isValid = false;
            }
        });
        return isValid;
    };
    const handleSubmit = async (url, requestData, successCallback) => {
        setLoading(true);
        try {
            const response = await axios.post(url, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            if (response.data.status === 1) {
                toast.success(response.data.message);
                successCallback();
                onSave();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };
    const handleSubmitCategory = () => {
        if (validateFields([{ value: name, errorSetter: setNameError }])) {
            handleSubmit(
                `${process.env.REACT_APP_API_URL}category-store`,
                { name },
                () => {
                    setName('');
                    handleClosess();
                }
            );
        } else {
            setLoading(false);
        }
    };
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h4> Add Category</h4></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label className="custom-label">Enter Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Category Name"
                                    value={name}
                                    onChange={onInputChange(setName, setNameError)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleSubmitCategory();
                                        }
                                    }}
                                    className={nameError ? 'is-invalid' : ''}
                                />
                                {nameError && <Form.Control.Feedback type="invalid">Please enter category name.</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                <div>


                    <Button variant="primary" onClick={handleSubmitCategory} disabled={loading}>
                        {loading ? 'Adding...' : 'Create'}
                    </Button>
                </div>
            </Modal.Footer>
        </>
    );
};

export default Categories;
