
import React, { useState, useEffect, useRef } from 'react';
import { IoPrintSharp } from "react-icons/io5";
import { Button } from 'react-bootstrap';
import html2pdf from 'html2pdf.js';
import logopdf from '../assets/img/logopdf.png';
import pdfimage2 from '../assets/img/pdfimage2.png';
import { GrDocumentPdf } from "react-icons/gr";
import axios from "axios";
import { toWords } from 'number-to-words';
import { PDFDocument } from 'pdf-lib';

const Table = () => {
  const printRef = useRef();
  const [data, setViewData] = useState({});
  const [companyDetails, setCompanyDetails] = useState({});
  const token = localStorage.getItem("token");
  const orderProductId = localStorage.getItem("orderproduct_id_pdf");
  const [totalQty, setTotalQty] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loadingList, setLoadingList] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPriceInWords, setTotalPriceInWords] = useState('');
  const [cgstAmount, setCgstAmount] = useState(0);
  const [sgstAmount, setSgstAmount] = useState(0);
  const [totalGst, setTotalGst] = useState(0);

  useEffect(() => {
    const fetchOrderData = async () => {
      setLoadingList(true);
      try {
        const url = `${process.env.REACT_APP_API_URL}Order-view-product-list/${orderProductId}`;
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        });
        const { createOrder, product, product_other_data } = response.data.rows;

        const orderDetails = createOrder[0];
        const totalQty = product.reduce((acc, prod) => acc + parseFloat(prod.qty), 0);
        const totalPrice = product.reduce((acc, prod) => acc + (prod.price * prod.qty), 0);
        const formattedDescription = orderDetails.description
          ? orderDetails.description.replace(/\n/g, '<br/>')
          : "No description available.";

        // Calculate CGST and SGST amounts
        const cgstAmount = (totalPrice * orderDetails.c_gst) / 100;
        const sgstAmount = (totalPrice * orderDetails.s_gst) / 100;
        const totalGst = cgstAmount + sgstAmount;

        setTotalQty(totalQty);
        setTotalPrice(totalPrice);
        setCgstAmount(cgstAmount);
        setSgstAmount(sgstAmount);
        setTotalGst(totalGst);
        setViewData({
          ...orderDetails,
          products: product,
          otherData: product_other_data[0] || [],
          description: formattedDescription,
          other_charges_total: orderDetails.other_charges_total || 0
        });
      } catch (error) {
        console.error("There was a problem fetching data:", error);
      } finally {
        setLoadingList(false);
      }
    };

    fetchOrderData();
  }, [token, orderProductId]);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}Company-details-list`, {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        });
        if (response.data.status === 1) {
          setCompanyDetails(response.data.data[0]);
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    fetchCompanyDetails();
  }, [token]);

  useEffect(() => {
    const calculatedTotalAmount = parseFloat(totalPrice || 0)
      + parseFloat(data.other_charges_total || 0)
      + parseFloat(cgstAmount || 0)
      + parseFloat(sgstAmount || 0);
    setTotalAmount(calculatedTotalAmount);
    setTotalPriceInWords(toWords(calculatedTotalAmount) + ' Rupees only');
  }, [data, totalPrice, cgstAmount, sgstAmount]);

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const handlePrint = () => {
    const printContents = printRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // To reload the page to its original state
  };

  const generatePDF = async () => {
    const element = document.getElementById('pdfgen');
    const opt = {
      margin: [0, 0, 0, 0],
      filename: 'order_invoice.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    };

    try {
      // Generate initial PDF with html2pdf
      const pdfBlob = await html2pdf().from(element).set(opt).outputPdf();

      // Load and modify PDF using pdf-lib
      const pdfDoc = await PDFDocument.load(await pdfBlob.arrayBuffer());
      const pages = pdfDoc.getPages();

      // Set margins for the second and all subsequent pages
      const margin = 15 / 72; // 15 points in inches
      for (let i = 1; i < pages.length; i++) {
        const page = pages[i];
        page.setMargin({ top: margin, right: margin, bottom: margin, left: margin });
      }

      // Save and download the modified PDF
      const modifiedPdfBytes = await pdfDoc.save();
      const modifiedPdfBlob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(modifiedPdfBlob);
      link.download = 'order_invoice.pdf';
      link.click();
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const products = data.products || [];
  const firstPageProducts = products;
  // const remainingProducts = products.slice(8);

  return (
    <>
      <section className='orderview_section'>
        <div className="page-header">
          <div className='d-flex gap-2 justify-content-end w-100'>
            <Button type="button" id="printButton" className='d-flex gap-2 btn_added align-items-center' onClick={handlePrint}>
              <IoPrintSharp /> Print
            </Button>
            <Button type="button" className="btn_added" onClick={generatePDF}>
              <GrDocumentPdf /> Pdf
            </Button>
          </div>
        </div>
      </section>

      <div id="pdfgen" ref={printRef} style={{ position: "relative", padding: "0px", margin: "0px" }}>
        <div className="frameimage">
          <div className="imagebg" />
        </div>

        {/* Page 1 Content */}
        <div className="width_table">
          <table className="table border_pdf">
            <tbody>
              <tr>
                <td colSpan={6}>
                  <div className="invoice-header">
                    <img src={logopdf} alt="Logo" />
                    <h3 style={{ color: "#527271" }}>INVOICE</h3>
                  </div>
                  <h5 style={{ color: "#527271" }}>Invoice to:</h5>
                  <h5 style={{ color: "#527271" }}>NAME: {data.customer_name}</h5>
                  <div className="d-flex justify-content-between">
                    <div style={{ width: "48%", color: "#527271" }}>
                      {data.address1}{" "}
                      <span style={{ display: "block" }}>{data.city}-{data.pincode}({data.state})</span>
                    </div>
                    <div style={{ width: "48%", textAlign: "end", marginLeft: "auto" }}>
                      <div>
                        <span style={{ color: "#527271" }}>
                          <b>Invoice {data.invoice_number_manual}</b>&nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                      <div>
                        <span style={{ color: "#527271" }}>
                          <b>Date :</b>&nbsp;&nbsp;&nbsp;
                        </span>
                        <span style={{ color: "#527271" }}>{data.order_date}</span>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "20px", color: "#527271" }}>
                    <table className="table invoice-table">
                      <thead>
                        <tr>
                          <th style={{ background: "#527271", color: "#fff" }}>S.No</th>
                          <th style={{ background: "#527271", color: "#fff" }}>Name</th>
                          <th style={{ background: "#527271", color: "#fff" }}>HSN</th>
                          <th style={{ background: "#527271", color: "#fff" }}>Quantity</th>
                          <th style={{ background: "#527271", color: "#fff" }}>Price</th>
                          <th style={{ background: "#527271", color: "#fff" }}>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {firstPageProducts.map((product, index) => (
                          <tr key={index} style={{ borderBottom: "1px solid #ccc" }}>
                            <td style={{ color: "#527271" }}>{index + 1}</td>
                            <td style={{ color: "#425756", fontWeight: 600 }}>{product.name}</td>
                            <td style={{ color: "#425756" }}>{product.hsn_no}</td>
                            <td style={{ color: "#527271" }}>{product.qty}/{product.pro_unit}</td>
                            <td style={{ color: "#527271" }}>₹{product.price}</td>
                            <td style={{ color: "#527271" }}>₹{(product.price * product.qty).toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ width: "48%" }}>
                  <div className="mt-3" style={{ color: "#527271" }}>
                    <h6>Description</h6>
                    <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
                  </div>
                </td>
                <td style={{ width: "48%" }}>
                  <div className="d-flex justify-content-end">
                    <table className="table invoice-table">
                      <tbody>
                        {/* <tr>
                          <td style={{ color: "#527271" }}><b>Total Qty</b></td>
                          <td style={{ color: "#527271" }}>₹{totalQty}</td>
                        </tr> */}
                        <tr>
                          <td style={{ color: "#527271", padding: "6px" }}><b>Total Price</b></td>
                          <td style={{ color: "#527271", padding: "6px", textAlign: "right" }}>₹{data.total_price}</td>
                        </tr>
                        {/* <tr>
                          <td style={{ color: "#527271" }}><b>CGST {data.c_gst}%</b></td>
                          <td style={{ color: "#527271" }}>₹{cgstAmount}</td>
                        </tr> */}
                        {/* <tr>
                          <td style={{ color: "#527271" }}><b>SGST {data.s_gst}%</b></td>
                          <td style={{ color: "#527271" }}>₹{sgstAmount}</td>
                        </tr> */}
                        <tr>
                          <td style={{ color: "#527271", padding: "6px" }}><b>Tax Rate</b></td>
                          <td style={{ color: "#527271", padding: "6px", textAlign: "right" }}>₹{sgstAmount + cgstAmount}</td>
                        </tr>
                        <tr>
                          <td style={{ color: "#527271", padding: "6px" }}><b>Shipping</b></td>
                          <td style={{ color: "#527271", padding: "6px", textAlign: "right" }}>₹{data.total_lable_charge}</td>
                        </tr>
                        <tr>
                          <td style={{ background: "#527271", color: "#fff", padding: "6px" }}><b>Total Amount</b></td>
                          <td style={{ background: "#527271", color: "#fff", padding: "6px", textAlign: "right" }}>₹{data.total_amount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>

              <tr>
                <td style={{ color: "#527271" }}>
                  <div>
                    <span
                      style={{
                        color: "#527271",
                        fontSize: 15,
                        display: "block",
                        fontWeight: 500
                      }}
                    >
                      <h6 style={{ color: "#527271", fontWeight: "500" }}>Questions</h6>
                    </span>
                    <div>
                      <span style={{ fontSize: 13 }}>Email Us:- </span>
                      <span style={{ fontSize: 13 }}>{companyDetails.email}</span>
                    </div>
                    <div>
                      <span style={{ fontSize: 13 }}>Call Us:- </span>
                      <span style={{ fontSize: 13 }}>{companyDetails.mobile}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ color: "#527271" }}>
                  <div>
                    <span
                      style={{
                        color: "#527271",
                        fontSize: 15,
                        display: "block",
                        fontWeight: 500
                      }}
                    >
                      <h6 style={{ color: "#527271", fontWeight: "500" }}>Payment Info</h6>
                    </span>
                    <div>
                      <span style={{ fontSize: 13 }}>Gst In Number:- </span>
                      <span style={{ fontSize: 13 }}>{companyDetails.gstin}</span>
                    </div>
                    <div>
                      <span style={{ fontSize: 13 }}>Bank Name:- </span>
                      <span style={{ fontSize: 13 }}>{companyDetails.bank_name}</span>
                    </div>

                    <div>
                      <span style={{ fontSize: 13 }}>Account Number:- </span>
                      <span style={{ fontSize: 13 }}>{companyDetails.account_no}</span>
                    </div>
                    <div>
                      <span style={{ fontSize: 13 }}>A/C Name:- </span>
                      <span style={{ fontSize: 13 }}>{companyDetails.acc_holder_name}</span>
                    </div>
                    <div>
                      <span style={{ fontSize: 13 }}>IFCE CODE:- </span>
                      <span style={{ fontSize: 13 }}>{companyDetails.ifce_code}</span>
                    </div>
                    <div>
                      <span style={{ fontSize: 13 }}>Bank Address:- </span>
                      <span style={{ fontSize: 13 }}>{companyDetails.bank_address}</span>
                    </div>
                    {/* <div>
                      <span style={{ fontSize: 13 }}>Bank Details:-</span>
                      <span style={{ fontSize: 13 }}>Add Your Bank</span>
                    </div> */}
                  </div>
                </td>
                <td>
                  <div style={{ textAlign: "center", position: "relative", top: 80 }}>
                    <span
                      style={{
                        width: "40%",
                        margin: "auto",
                        borderBottom: "1px solid #527271",
                        display: "block"
                      }}
                    />
                    <span style={{ color: "#527271", letterSpacing: "1.2" }}>
                      <b style={{ color: "#527271", letterSpacing: "1.2" }}>
                        Authorised Sign
                      </b>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <div className="bottomimage">
          <img src={pdfimage2} alt="" />
        </div> */}
        </div>

      </>
      );
};

      export default Table;
