import React, { useState, useMemo, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from '../expenditure/ExpenditureTable';

const Expenditure_type_month_Amount = () => {
  const [employeeList, setEmployeeList] = useState([]);
  const token = localStorage.getItem('token');
  const expenditure_month = localStorage.getItem('expenditure_type_month');
  const expenditure_id_type_month = localStorage.getItem('expenditure_id_type_month');

  useEffect(() => {
    fetchEmployeeList();
  }, []);

  const fetchEmployeeList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}expenditure-month-Value/${expenditure_month}/expenditure_id/${expenditure_id_type_month}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      setEmployeeList(response.data.data.expenditures || []);
    } catch (error) {
      console.error('Error fetching employee list:', error);
    }
  };

  const data = useMemo(() => employeeList.map((employee, index) => {
    const formattedDate = new Date(employee.expenditure_created_at).toLocaleDateString('en-GB').replace(/\//g, '-');
    return {
      srnumber: index + 1,
      Date: formattedDate,
      Name: employee.expenditure_type_name,
      Category: employee.expenditure_category_name || 'N/A',
      Employee: employee.emp_name || employee.customer_name || 'N/A',
      Amount: employee.expenditure_amount,
    };
  }), [employeeList]);

  const columns = useMemo(() => [
    { Header: 'Sr.No.', accessor: 'srnumber' },
    { Header: 'Date', accessor: 'Date' },
    { Header: 'Exp Name', accessor: 'Name' },
    { Header: 'Exp Category', accessor: 'Category' },
    { Header: 'Expenditure', accessor: 'Employee' },
    { Header: 'Amount', accessor: 'Amount' },
  ], []);

  return (
    <>
      
      <Card>
        <Card.Body>
          <DataTable columns={columns} data={data} />
        </Card.Body>
      </Card>
    </>
  );
};

export default Expenditure_type_month_Amount;
