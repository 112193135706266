import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, Row, Col, Form, Card } from 'react-bootstrap';
import { MdAddCircle, MdEditCalendar, MdCropRotate, MdArrowRight, MdOutlineGeneratingTokens } from 'react-icons/md';
import { Link } from 'react-router-dom'
import { HiChevronUp } from "react-icons/hi";
import Select from 'react-select';
import Product from '../assets/img/product.png'
import DataTable from './Stocktable';
import { useTable } from 'react-table';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AddstockUpdate from '../Stock/AddstockUpdate';
import Addproduct from '../Stock/Addstock';

const ExtraChargesList = () => {
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [data, setVenderlist] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [productlocation, setproductlocation] = useState([]);
  const [categories, setCategories] = useState([]);

  const [modalState, setModalState] = useState({
    addProductUpdate: false,
    addproduct: false,
  });
  useEffect(() => {
    if (!modalState.addProductUpdate || !modalState.addproduct) {
      fetchVendor();
    }
  }, [token, modalState.addProductUpdate, modalState.addproduct]);
  const ProductUpdate = (id) => {
    setModalState({ ...modalState, addProductUpdate: true })
    localStorage.setItem('stock_update_id', id)
  }
  const fetchVendor = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}Extra-Charges-list`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      setVenderlist(response.data.data || []);
    } catch (error) {
      console.error('Error fetching vendors:', error);
      // toast.error('Failed to fetch vendors.');
    }
  };
  
  useEffect(() => {
    const fetchCategories1 = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}Custumer-name-list`, {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        });
        setproductlocation(response.data.data || []);
      } catch (error) {
        console.error('Error fetching categories:', error);
        toast.error('Failed to fetch categories.');
      }
    };
    fetchCategories1();

  }, [token]);
  const VenderDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        let url = `${process.env.REACT_APP_API_URL}Product-stoct-delete/${id}`;
        const config = {
          method: 'get',
          url: url,
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        };
        const response = await axios(config);
        if (response.data.status === 1) {
          toast.success(response.data.message);
          fetchVendor();
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      // setLoadinglist(false);
    }
  };

  // Define columns
  const columns = useMemo(
    () => [
      {
        Header: 'Sr.No',
        accessor: 'Srno',
        Cell: (row) => {
          return row.row.index + 1;
        },
      },
      {
        Header: 'Customer Name',
        accessor: 'customer_name',
      },
      {
        Header: 'Category',
        accessor: 'category_name',
      },
      {
        Header: 'Vendor Name',
        accessor: 'vendor_name',
      },
      {
        Header: 'Product Name',
        accessor: 'name',
      },
      // {
      //   Header: 'Date',
      //   accessor: 'date',
      // },
      {
        Header: 'Qty',
        accessor: 'pro_qty',
      },
      {
        Header: 'Price',
        accessor: 'price',
      },
      {
        Header: 'Total Amount',
        accessor: 'total_amount',
      },
   
   
      // {
      //   Header: 'Action',
      //   id: 'action',
      //   Cell: ({ row }) => {
      //     const item = row.original;
      //     return (
      //       <div className="d-flex justify-content-around">
      //         <Button
      //           style={{ "--i": "#233882" }}
      //           className="p-2"
      //           onClick={() => ProductUpdate(item.id)}
      //         >
      //           <FaEdit />
      //         </Button>
      //         <Button
      //           style={{ "--i": "red" }}
      //           className="p-2"
      //           onClick={() => VenderDelete(item.id)}
      //         >
      //           <MdDelete />
      //         </Button>
      //       </div>
      //     );
      //   },
      // },
    ],
    []
  );
  const categoryOptions = categories.map((item) => ({ value: item.id, label: item.name }));

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value); // No need to parseInt, keep it as a string
  };
  const filteredOrderList = selectedStatus
    ? data.filter(item => item.customer_id === selectedStatus)
    : data; return (
      <>
        <>


          <div>
            <div className="page-header">
              <div className="add-item d-flex">
                <div className="page-title">
                  <h4>Extra Charges List</h4>
                </div>
              </div>

              <div className="page-btn">
                {/* <Link to="#" className="btn btn-added d-flex gap-2" onClick={() => setModalState({ ...modalState, addproduct: true })}> */}
                <Link to="/Extra-charges" className="btn btn-added d-flex gap-2">
                  <MdAddCircle className="me-2" /> {/* Replace the icon */}
                  Add Extra Charges
                </Link>
              </div>
            </div>

            <div className='justify-content-center processing'>
              <div className='text-center'>
                <Row>
                  <Col md={12} xs={12} lg={12} className='duedrop'>
                    <Form.Select aria-label="Default select example" onChange={handleStatusChange} value={selectedStatus} style={{ border: '1px solid #8080802' }}>
                      <option value="">Select Customer</option>
                      {productlocation.map((vendor) => (
                        <option key={vendor.id} value={vendor.id}>
                          {vendor.customer_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </div>
            </div>
            <DataTable columns={columns} data={filteredOrderList} />


            {/* Example modal component */}
            <Modal id="add-units" show={false} onHide={() => { }} backdrop="static" 
          keyboard={false}> 
              <Modal.Header closeButton>
                <Modal.Title>Add New</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Your modal content here */}
                {/* This is just a placeholder, replace it with your actual modal content */}
                <p>Modal Content</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => { }}>
                  Close
                </Button>
                <Button variant="primary" onClick={() => { }}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <Modal
            show={modalState.addProductUpdate}
            size='xl'
            onHide={() => setModalState({ ...modalState, addProductUpdate: false })}
            aria-labelledby="example-modal-sizes-title-lg"
            backdrop="static" 
          keyboard={false}
          >
            <Modal.Header closeButton>
              {/* <h3 className="card-title mb-0">Expenditure Details</h3> */}
            </Modal.Header>
            <Modal.Body>
              <AddstockUpdate onSave={() => setModalState({ ...modalState, addProductUpdate: false })} />
            </Modal.Body>
          </Modal>
          <Modal
            show={modalState.addproduct}
            size='xl'
            onHide={() => setModalState({ ...modalState, addproduct: false })}
            aria-labelledby="example-modal-sizes-title-lg"
            backdrop="static" 
          keyboard={false}
          >
            <Modal.Header closeButton>
              <h3 className="card-title mb-0">Add Stock Product</h3>
            </Modal.Header>
            <Modal.Body>
              <Addproduct onSave={() => setModalState({ ...modalState, addproduct: false })} />
            </Modal.Body>
          </Modal>
        </>
        <ToastContainer />
      </>
    );
};

export default ExtraChargesList;
