import React, { useState, useEffect, useMemo } from 'react';
import { Card, Table } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

export default function PendingOrder({ onSave }) {
    const token = localStorage.getItem('token');
    const [orders, setOrders] = useState([]);

    // Define table columns
    const columns = useMemo(() => [
        {
            Header: 'Sr. No.',
            accessor: 'Srno',
            Cell: ({ row }) => row.index + 1 
        },
        {
            Header: 'Customer Name',
            accessor: 'customer_name',
        },
        {
            Header: 'Product Name',
            accessor: 'product_name',
        },
        {
            Header: 'Delivery Date',
            accessor: 'delivery_date',
        },
        {
            Header: 'Quantity',
            accessor: 'add_receive_product_raw_qty',
        },
        {
            Header: 'Amount',
            accessor: 'add_receive_product_raw_amount',
        },  
    ], []);

    // Fetch orders from API
    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Order-take-processing-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                console.log('API Response:', response.data); // Debugging
                setOrders(response.data.data || []);
            } catch (error) {
                console.error('Error fetching orders:', error);
                toast.error('Failed to fetch orders.');
            }
        };
        fetchOrders();
    }, [token]);

    return (
        <>
            <Card>
                <Card.Body>
                    <div className="table-responsive">
                        <Table striped bordered hover>
                            <thead style={{ backgroundColor: '#f2f2f2' }}>
                                <tr className="thead-dark">
                                    {columns.map((col, index) => (
                                        <th key={index}>{col.Header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {orders.length === 0 ? (
                                    <tr>
                                        <td colSpan={columns.length}>No orders available</td>
                                    </tr>
                                ) : (
                                    orders.map((order, index) => (
                                        <tr key={index}>
                                            {columns.map((col, colIndex) => (
                                                <td key={colIndex}>
                                                    {col.Cell ? col.Cell({ value: order[col.accessor], row: { index } }) : (order[col.accessor] || 'N/A')}
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
            <ToastContainer />
        </>
    );
}
