import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaEye } from "react-icons/fa";
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

const Ven_Ladger_Payment_List = () => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [data, setVendorDataDetails] = useState([]);
    const ven_ladger_id = localStorage.getItem('ven_ladger_id');
    const ven_costomer_id = localStorage.getItem('ven_det_costomer_id');

    useEffect(() => {
        fetchVendorDetails();
    }, [token]);

    const fetchVendorDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Vendor-ledger-details-list/${ven_ladger_id}/customer_id/${ven_costomer_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            const otherData = response.data.data || [];
            setVendorDataDetails(otherData);
        } catch (error) {
            console.error('Error fetching vendors:', error);
        }
    };

    return (
        <>
            <div className="App">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex">

                            <div><Link to='/vendor-ladgor' className='btn-added'>Back</Link></div>
                        </div>
                    </div>

                    <div className="card-body">
                        <table className='table table-striped hover border'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Sr.no</th>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Site Name</th>
                                    <th>Product Name</th>
                                    <th>Qty</th>
                                    <th>Price</th>
                                    <th>Total Amount</th>
                                    <th>Paid Amount</th>
                                    <th>Due Amount</th>
                                </tr>
                            </thead>
                            <tbody className='trcolorchange'>
                                {data && data.map((vendor, index) => (
                                    <tr key={vendor.vendor_id}>
                                        <td>{index + 1}</td>
                                        <td>{vendor.date}</td>
                                        <td>{vendor.customer_type}</td>
                                        <td>{vendor.customer_name}</td>
                                        <td>{vendor.name}</td>
                                        <td>{vendor.qty}</td>
                                        <td>{vendor.unit_price}</td>
                                        <td>{vendor.total_price}</td>
                                        <td>{vendor.total_payments}</td>
                                        <td>{vendor.total_price - vendor.total_payments}</td>
                                       
                                    </tr>
                                ))}
                            </tbody>
                        </table>


                    </div>
                </div>
            </div>
        </>
    );
};

export default Ven_Ladger_Payment_List;
