import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal as BootstrapModal, Card } from 'react-bootstrap';
import RowMaterial from './DashboardPages/RowMaterial';
import StockMaterial from './DashboardPages/StockMaterial';
import DonutChart from 'react-donut-chart';

const PieChart = () => {
  const [modalState, setModalState] = useState({ RawMaterial: false, StockMaterial: false });
  const [modalContent, setModalContent] = useState(null);
  const [chartData, setChartData] = useState([]);

  const handleSliceClick = (data) => {
    const label = data.label;

    if (['Row Material', 'Stock Material'].includes(label)) {
      setModalContent({
        label: label,
        value: Number(data.value), // Ensure value is a number
        amount: Number(data.amount) // Ensure amount is a number
      });

      setModalState(prevState => ({
        ...prevState,
        [label.replace(" ", "")]: true
      }));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}Dashbord-total-stock-list`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      });

      const { data } = response.data || [];

      const dataPoints = [
        {
          label: 'Row Material',
          value: data.total_pro_qty,
          amount: data.total_product_amount,
          color: '#741258',
        },
        {
          label: 'Stock Material',
          value: data.total_met_qty,
          amount: data.total_stock_amount,
          color: '#f72b50',
        }
      ];

      setChartData(dataPoints);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const closeModal = () => {
    setModalState({ RawMaterial: false, StockMaterial: false });
    setModalContent(null);
  };

  return (
    <Card className="flex-fill default-cover">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h5 className="card-title mb-0">Stock Products</h5>
      </Card.Header>
      <div className='position-relative donut-chart-container'>
        <DonutChart
          data={chartData.map(item => ({
            label: item.label,
            value: item.value,
            color: item.color,
          }))}
          legend={false}
          onClick={data => handleSliceClick(data)}
          height={300}
          width={300}
          innerRadius={0.5}
        />

        <div className="legend">
          {chartData.map(item => (
            <div key={item.label} className="legend-item">
              <span style={{ backgroundColor: item.color }} className="legend-color" />
              <span className="legend-text">{`${item.label}: Qty ${item.value}, ₹${item.amount ? item.amount.toLocaleString() : 'N/A'}`}</span>
            </div>
          ))}
        </div>

        {modalContent && (
          <BootstrapModal
            show={modalState[modalContent.label.replace(" ", "")]}
            onHide={closeModal}
            size='lg'
            aria-labelledby="modal-title"
            backdrop="static"
            keyboard={false}
          >
            <BootstrapModal.Header closeButton>
              <BootstrapModal.Title id="modal-title">{modalContent.label} Details</BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body>
              <p><strong>Quantity:</strong> {modalContent.value}</p>
              <p><strong>Amount:</strong> ₹{modalContent.amount ? modalContent.amount.toLocaleString() : 'N/A'}</p>
              {modalContent.label === 'Row Material' && <RowMaterial content={modalContent} closeModal={closeModal} />}
              {modalContent.label === 'Stock Material' && <StockMaterial content={modalContent} closeModal={closeModal} />}
            </BootstrapModal.Body>
          </BootstrapModal>
        )}
      </div>
    </Card>
  );
};

export default PieChart;
