import React, { useMemo, useState } from 'react';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { Card, Modal, Form, Button, Row, Col, Container } from 'react-bootstrap';
import AddVendor from '../Venderrecive/AddVendor';
import AddReceive from '../Venderrecive/AddReceive';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom'

const GlobalFilter = ({ filter, setFilter }) => {

  return (
    <input
      value={filter || ''}
      onChange={(e) => setFilter(e.target.value)}
      placeholder="Search..."
      className="form-control"
    />
  );
};

const Vendorproduct = ({ title, columns, data, addvendor, btnclass }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    pageCount,
    setPageSize,

    prepareRow,
  } = useTable(
    {
      columns,
      data,
      title,
      addvendor,
      btnclass,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    usePagination
  );
  const [addItems, setAdditems] = useState(false);
  const addItemstrue = () => setAdditems(true);
  const AdditemsClose = () => setAdditems(false);
  const [modalState, setModalState] = useState({
    addVendor: false,
    addReceive: false,
  });
  const navigate = useNavigate();

  const { globalFilter, pageIndex, pageSize } = state;
  const handleView = (order) => {
    navigate('/Receive-Materials');
    // Handle view action here
  };
  return (
    <React.Fragment>
      {/* <ToastContainer/> */}

      <div className="d-flex justify-content-between align-items-center mb-2 table_index">
        <h5 className="mb-0">{title}</h5>
        <div className="d-flex justify-content-between gap-3">
          {/* <button className="btn btn-secondary" onClick={() =>handleView()}>
          {addvendor}
          </button> */}
          {/* <button onClick={() => setModalState({ ...modalState, addReceive: true })} className={btnclass} style={{ background: "transparent", border: "none" }}>
            {addvendor}
          </button> */}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="form-select d-inline"
            style={{ width: "120px" }}

          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <div style={{ width: '220px' }}>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table {...getTableProps()} className="table datanew borderd">
          <thead style={{ backgroundColor: '#f2f2f2' }}>
            {headerGroups.map((headerGroup) => (
              <tr className="thead-dark" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          Page
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </div>
        <div className="pagination justify-content-center mt-3">
          <div onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <MdKeyboardDoubleArrowLeft />
          </div>
          <div onClick={() => previousPage()} disabled={!canPreviousPage}>
            <MdKeyboardArrowLeft />
          </div>
          <div>
            <h6 className="mb-0 pagination_count d-flex justify-content-center align-items-center">{pageIndex + 1} </h6>
          </div>
          <div onClick={() => nextPage()} disabled={!canNextPage}>
            <MdKeyboardArrowRight />
          </div>
          <div onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <MdKeyboardDoubleArrowRight />

          </div>


        </div>
      </div>

      {/* add vender add  */}
      <Modal size="lg" show={addItems} onHide={AdditemsClose} backdrop="static" 
          keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4> Add Raw Matrial</h4></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Container fluid>
              <Row>
                <Col lg={4} sm={6} xs={12}>
                  <Form.Group controlId="rawMaterial">
                    <Form.Label>Raw Material</Form.Label>
                    <Form.Control type="text" placeholder="Enter raw material" />
                  </Form.Group>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <Form.Group controlId="vendor">
                    <Form.Label>Vendor</Form.Label>
                    <Form.Control type="text" placeholder="Enter vendor name" />
                  </Form.Group>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <Form.Group controlId="qty">
                    <Form.Label>QTY</Form.Label>
                    <Form.Control type="text" placeholder="Enter quantity" />
                  </Form.Group>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <Form.Group controlId="unit">
                    <Form.Label>Unit</Form.Label>
                    <Form.Control type="text" placeholder="Enter unit" />
                  </Form.Group>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <Form.Group controlId="salePrice">
                    <Form.Label>Sale Price</Form.Label>
                    <Form.Control type="text" placeholder="Enter sale price" />
                  </Form.Group>
                </Col>
              </Row>

            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
          <div className="">
            <Button variant="primary" className="btn btn-submit" onClick={AdditemsClose}>
              Create
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* add items model  */}
      <Modal
        size="lg"
        show={modalState.addVendor}
        onHide={() => setModalState({ ...modalState, addVendor: false })}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static" 
          keyboard={false}
      >

        <Modal.Body>
          <AddVendor onSave={() => setModalState({ ...modalState, addVendor: false })} />
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={modalState.addReceive}
        onHide={() => setModalState({ ...modalState, addReceive: false })}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body>
          <AddReceive onSave={() => setModalState({ ...modalState, addReceive: false })} />
        </Modal.Body>
      </Modal>
      {/* <ToastContainer /> */}
    </React.Fragment>

  );
};

export default Vendorproduct;
