import React, { useState, useEffect, useMemo } from 'react';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function PieSites({ onSave }) {
    const token = localStorage.getItem('token');
    const [Details, setDetails] = useState([]);

    // Define columns for table
    const columns = useMemo(
        () => [
            {
                Header: 'Customer Name',
                accessor: 'customer_name',
            },
            {
                Header: 'Total Paid',
                accessor: 'total_amount',
            },
            {
                Header: 'Total Receive',
                accessor: 'amount',
            },
            {
                Header: 'Total Due',
                accessor: 'due_amt',
                Cell: ({ row }) => {
                    const { total_amount, amount, due_amt, updated_at } = row.original;
                    const { color } = getDueAmountColor(updated_at, due_amt, total_amount, amount);
                    return (
                        <span style={{ color }}>
                            {due_amt}
                        </span>
                    );
                }
            },
            {
                Header: 'Date',
                accessor: 'formatted_date',
                Cell: ({ row }) => formatDate(row.original.updated_at),
            },
            {
                Header: 'Days',
                id: 'days_since_updated', 
                Cell: ({ row }) => {
                    const { updated_at } = row.original;
                    const { dateDiff } = getDueAmountColor(updated_at);
                    return dateDiff;
                },
            },
        ],
        []
    );

    useEffect(() => {
        fetchProducts();
    }, []);

    // Fetch and filter products
    const fetchProducts = async () => {
        try {
            const url = `${process.env.REACT_APP_API_URL}Customer-payment-list`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            const filteredData = (response.data.data || []).filter(item => item.customer_type === 'Site');
            setDetails(filteredData);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    // Calculate due amount color and date difference
    const getDueAmountColor = (updated_at, due_amt, total_amount, amount) => {
        const updatedDate = new Date(updated_at);
        const currentDate = new Date();

        // Reset the time to 00:00:00 for an accurate day difference calculation
        updatedDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        // Calculate the difference in days
        const dateDiff = Math.floor((currentDate - updatedDate) / (1000 * 60 * 60 * 24));

        // Determine the color based on the date difference
        const color = dateDiff > 1 ? 'red' : 'green';

        return { color, dateDiff };
    };

    // Format date to DD/MM/YYYY
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <div className="table-responsive">
                        <table className="table datanew">
                            <thead style={{ backgroundColor: '#f2f2f2' }}>
                                <tr className='thead-dark'>
                                    {columns.map(column => (
                                        <th key={column.Header}>{column.Header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {Details.map((row, i) => (
                                    <tr key={i}>
                                        {columns.map(column => (
                                            <td key={column.Header}>
                                                {column.Cell ? column.Cell({ row: { original: row } }) : row[column.accessor]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}
