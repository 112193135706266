import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { Row, Col, Container, Form, Card, Table, Button } from 'react-bootstrap';
import { FaArrowLeftLong } from "react-icons/fa6";
import { GrDocumentPdf } from "react-icons/gr";
import { RiDownloadLine } from "react-icons/ri";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from 'react-router-dom';
import { FaEye } from "react-icons/fa";

export default function OrderProcessDetails() {
    const OrderDetails_id = localStorage.getItem("vendor_order_id");
    const [vendorData, setViewdata] = useState([]);
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    useEffect(() => {
        VenderView();
    }, []);

    const VenderView = async () => {
        try {
            let url = `${process.env.REACT_APP_API_URL}Vendor-Order-Process-Details/${OrderDetails_id}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            setViewdata(response.data.data || []);
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        }
    };



    const totalItems = vendorData.length;
    const totalQty = vendorData.reduce((sum, item) => sum + Number(item.firstqty), 0);
    const totalAmount = vendorData.reduce((sum, item) => {
        const totalPrice = (item.firstqty * item.firstprice) * (1 + item.gst / 100);
        return sum + totalPrice;
    }, 0);

    const goBack = () => {
        navigate('/Order-Details');
    };




    return (
        <div className='Vendorview'>
            <div className="page-header d-flex justify-content-between">
                <div className="page-title">
                    <h4>Vendor View Details</h4>
                </div>
                <div className='d-flex gap-2'>

                    <Button type="button" className="btn-added" onClick={goBack}>
                        <FaArrowLeftLong style={{ paddingRight: '3px' }} /> Back
                    </Button>
                </div>
            </div>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-0">Order View</Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex gap-2 justify-content-between">
                        {/* <h6><strong>Amount</strong> 50,55252</h6> */}
                    </div>
                    <div className="table-responsive orderview_section">
                        <Table striped bordered hover>
                            <thead>
                                <tr className="thead-dark">
                                    <th>Sr No</th>
                                    <th>Order Id</th>
                                    <th>Customer Name</th>
                                    <th>Product Name</th>
                                    <th>Qty</th>
                                    <th>Order Date</th>
                                    <th>Amount</th>
                                    <th>Status</th> 
                                </tr>
                            </thead>
                            <tbody>
                                {vendorData.map((item, index) => {
                                    const totalPrice = (item.firstqty * item.firstprice) * (1 + item.gst / 100);
                                    const statusLabel = item.shipping_status === 1 ? 'Delivered' : 'Pending';
                                    const statusColor = item.shipping_status === 1 ? 'green' : 'red';

                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.order_id}</td>
                                            <td>{item.customer_name}</td>
                                            <td>{item.product_name}</td>
                                            <td>{item.add_receive_product_raw_qty}</td>
                                            <td>{item.add_receive_product_raw_date}</td>
                                            <td>{new Intl.NumberFormat().format(item.add_receive_product_raw_amount)}</td>
                                            <td>
                                                <span style={{ color: statusColor }}>
                                                    {statusLabel}
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                    {/* <div className="tfoot d-flex justify-content-between">
                        <div className="tfoot-column text-center">
                            <h6><strong>Total Items</strong></h6>
                            <h6>{totalItems}</h6>
                        </div>
                        <div className="tfoot-column text-center">
                            <h6><strong>Total Amount</strong></h6>
                            <h6>{totalAmount.toFixed(2)}</h6>
                        </div>
                    </div> */}
                </Card.Body>
            </Card>

        </div>
    );
}
