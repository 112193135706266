import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const Order = () => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: 'basic-line'
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']
    },
    stroke: {
      curve: 'smooth' // optional for smoother line
    },
    markers: {
      size: 4 // optional for marker size on the line
    },
    yaxis: {
      title: {
        text: 'Values'
      }
    },
    title: {
      text: 'Monthly Data',
      align: 'center'
    }
  });

  const [chartSeries, setChartSeries] = useState([
    {
      name: 'series-1',
      data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
    }
  ]);

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="line"
          />
        </div>
      </div>
    </div>
  );
};

export default Order;
