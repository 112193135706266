import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Card, Table, Button } from 'react-bootstrap';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

export default function CustumerProductview() {
    const OrderDetails_id = localStorage.getItem("product_view_id");
    const [vendorData, setVendorData] = useState([]);
    console.warn(vendorData);
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    useEffect(() => {
        fetchVendorData();
    }, []);

    const fetchVendorData = async () => {
        try {
            const url = `${process.env.REACT_APP_API_URL}Crete-custumer-product-list/${OrderDetails_id}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            console.log("API Response:", response); // Log the response for debugging
            const fetchedData = response.data || {};
            if (fetchedData.status == 1) {
                setVendorData(fetchedData.data.products || []);
            } else {
                console.error("API Error:", fetchedData.message); // Log any API errors
            }
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        }
    };

    const goBack = () => {
        navigate('/orderview');
    };

    return (
        <div className='Vendorview'>
            {/* <div className="page-header">
               
                <div className="button_add_row"></div>
            </div> */}

            {vendorData.length === 0 ? (
                <Card>
                    <Card.Body>
                        <p>No data available</p>
                    </Card.Body>
                </Card>
            ) : (
                vendorData.map((product, index) => (
                    <Card key={index} className="mb-4 border">

                        <Card.Body>
                            <h6>Product Details</h6>
                            <Table striped bordered hover>
                                <thead>
                                    <tr className="thead-primary">
                                        <th>Sr.No</th>
                                        {/* <th>Order Id</th> */}
                                        <th>Product Name</th>
                                        <th>Vendor Name</th>
                                        <th>qty</th>
                                        <th>gst</th>
                                        <th>price</th>
                                        <th>Total Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{index + 1}</td>
                                        {/* <td>{product.order_id}</td> */}
                                        <td>{product.product_name}</td>
                                        <td>{product.vendor_name}</td>
                                        <td>{product.qty}</td>
                                        <td>{product.gst}</td>
                                        <td>{product.price}</td>
                                        <td>{(product.qty * product.price) + ((product.qty * product.price * product.gst) / 100)}</td>
                                        </tr>
                                </tbody>
                            </Table>
                            <h6>Product Row Materials Details</h6>
                            <Table striped bordered hover className="mt-3">
                                <thead>
                                    <tr className="thead">
                                        <th>Items Name</th>
                                        <th>Unit Type</th>
                                        <th>Qty</th>
                                        <th>Vendor Name</th>
                                        <th>Cost</th>
                                        <th>Sale</th>

                                        {/* <th>Total Price</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {product.product_row_materials.map((item, idx) => (
                                        <tr key={idx}>
                                            <td>{item.raw_name}</td>
                                            <td>{item.unit_type}</td>
                                            <td>{item.qty}</td>
                                            <td>{item.vendor_name}</td>
                                            <td>{item.u_cost_prize}</td>
                                            <td>{item.unit_sale_price}</td>

                                            {/* <td>{(item.qty * item.unit_sale_price) * (1 + item.gst / 100).toFixed(2)}</td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                ))
            )}
        </div>
    );
}
