import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

const useFormFields = (initialFields) => {
    const [fields, setFields] = useState(initialFields);
    const [errors, setErrors] = useState(
        Object.keys(initialFields).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    );

    const handleFieldChange = (key) => (e) => {
        let value = e.target.value;

        if (key === 'MobileNumber') {
            value = value.replace(/\D/g, '').slice(0, 10); // Limit to 10 digits
        } else if (key === 'Salary') {
            value = value.replace(/\D/g, ''); // Ensure only numbers are accepted
        } else if (key === 'AadharNumber') {
            value = formatAadharNumber(value); // Format Aadhar number
        }

        setFields({ ...fields, [key]: value });
        setErrors({ ...errors, [key]: false });
    };

    const formatAadharNumber = (value) => {
        // Remove all non-digit characters and limit to 12 digits
        const sanitizedValue = value.replace(/\D/g, '').slice(0, 12);
        // Format as '1111 1111 1111'
        return sanitizedValue.replace(/(\d{4})(\d{4})(\d{4})/, '$1 $2 $3');
    };

    return { fields, errors, handleFieldChange, setFields, setErrors };
};

const validateFields = (fields, setErrors) => {
    const newErrors = {};
    let isValid = true;

    if (!fields.Name) {
        newErrors.Name = true;
        isValid = false;
    }

    const aadharNumber = fields.AadharNumber.replace(/\s/g, ''); // Remove spaces
    if (aadharNumber.length !== 12) {
        newErrors.AadharNumber = "Aadhar number must be 12 digits";
        isValid = false;
    }

    setErrors(newErrors);
    return isValid;
};

const VendorForm = ({ onSave }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    const initialFields = {
        Name: '',
        Age: '',
        MobileNumber: '',
        Designation: '',
        AadharNumber: '',
        EmailId: '',
        Salary: '',
        Address: '',
        Joining_date: '',
        Status: '1',
    };

    const { fields, errors, handleFieldChange, setFields, setErrors } = useFormFields(initialFields);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        const payload = {
            emp_name: fields.Name,
            age: fields.Age,
            mobile_number: fields.MobileNumber,
            designation: fields.Designation,
            role: fields.AadharNumber.replace(/\s/g, ''), // Remove spaces for submission
            email: fields.EmailId,
            salayr: fields.Salayr,
            address: fields.Address,
            joining_date: fields.Joining_date,
            status: fields.Status,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Employee-store`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });

            if (response.data.status === 1) {
                Swal.fire({
                    title: 'Success',
                    text: 'Add Employee Successfully!',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
                setFields(initialFields);
                setLoading(false);
                onSave();
                setTimeout(() => {
                    navigate('/employeelist');
                }, 1000);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: response.data.message,
                    icon: 'error',
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                title: 'Error',
                text: 'An error occurred during the update',
                icon: 'error',
            });
            setLoading(false);
        }
    };

    const handleSubmitEmployee = () => {
        if (validateFields(fields, setErrors)) {
            setLoading(true);
            handleSubmit();
        } else {
            setLoading(false);
        }
    };

    return (
        <div id="addVendorModal">
            <div className="page-header">
                <div className="add-item d-flex justify-content-between">
                    <div className="page-title">
                        <h6>Employee</h6>
                    </div>
                </div>
            </div>
            <Card>
                <Card.Header>
                    <Card.Title>Add Employee</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Container fluid>
                        <Row>
                            {Object.keys(initialFields).map((key, index) => (
                                <Col lg={4} sm={6} xs={12} key={index}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="custom-label">
                                            {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                        </Form.Label>
                                        {key === 'Status' ? (
                                            <Form.Control
                                                as="select"
                                                value={fields[key]}
                                                onChange={handleFieldChange(key)}
                                                className={errors[key] ? 'is-invalid' : ''}
                                            >
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </Form.Control>
                                        ) : (
                                            <Form.Control
                                                type={key === 'MobileNumber' || key === 'Salayr' || key === 'Age' ? 'number' : (key === 'Joining_date' ? 'date' : 'text')}
                                                placeholder={key.replace(/([A-Z])/g, ' $1')}
                                                value={fields[key]}
                                                onChange={handleFieldChange(key)}
                                                className={errors[key] ? 'is-invalid' : ''}
                                            />
                                        )}
                                    </Form.Group>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" className="btn btn-submit" onClick={handleSubmitEmployee}>
                            Create
                        </Button>
                    </div>
                </Card.Footer>
            </Card>
            <ToastContainer />
        </div>
    );
};

export default VendorForm;
