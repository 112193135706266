import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Modal, Card, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { ToastContainer } from "react-toastify";
const useFormFields = (initialFields) => {
    const [fields, setFields] = useState(initialFields);
    const [errors, setErrors] = useState(
        Object.keys(initialFields).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    );

    const handleFieldChange = (key) => (e) => {
        let value = e.target.value;

        // Specific handling for each field type (e.g., numeric input, character limits)
        if (key === 'mobileNumber') {
            value = value.replace(/\D/g, ''); // Remove non-numeric characters
            value = value.slice(0, 10); // Limit to 10 characters
        }
        if (key === 'bankAccountNumber') {
            value = value.replace(/\D/g, '');
        }
        if (key === 'ifscCode') {
            value = value.toUpperCase();
            value = value.slice(0, 11);
        }
        if (key === 'gst') {
            value = value.toUpperCase();
            value = value.slice(0, 21);
        }
        if (key === 'status') {
            value = e.target.value; // Set value directly from select dropdown
        }

        // Update fields state
        setFields({ ...fields, [key]: value });
        setErrors({ ...errors, [key]: false });
    };

    return { fields, errors, handleFieldChange, setFields, setErrors };
};

const VendorForm = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const initialFields = {
        vendorName: '',
        vendorShortName: '',
        mobileNumber: '',
        gst: '',
        cityName: '',
        state: '',
        pinCode: '',
        bankAccountNumber: '',
        accountHolderName: '',
        ifscCode: '',
        accountType: '',
        addressLine1: '',
        addressLine2: '',
        status: ''
    };

    const { fields, errors, handleFieldChange, setFields, setErrors } = useFormFields(initialFields);
    const [loading, setLoading] = useState(false);
    const [viewdata, setViewdata] = useState([]);

    useEffect(() => {
        ProductView();
    }, []);

    const ProductView = async () => {
        const vendorUpdate_id = localStorage.getItem('vendorUpdate_id');
        try {
            let url = `${process.env.REACT_APP_API_URL}Vender-edit/${vendorUpdate_id}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            setViewdata(response.data.data);
            // Update fields with fetched data
            if (response.data.data.length > 0) {
                const data = response.data.data[0]; // Assuming you are expecting only one vendor data object
                setFields({
                    vendorName: data.vendor_name || '',
                    vendorShortName: data.vendor_short_name || '',
                    mobileNumber: data.mobile_number || '',
                    gst: data.gst || '',
                    cityName: data.city_name || '',
                    state: data.state || '',
                    pinCode: data.pincode || '',
                    bankAccountNumber: data.bank_account_number || '',
                    accountHolderName: data.account_holder_name || '',
                    ifscCode: data.ifsc_code || '',
                    accountType: data.account_type || '',
                    addressLine1: data.address_line1 || '',
                    addressLine2: data.address_line2 || '',
                    status: data.status !== undefined ? String(data.status) : '' // Ensure status is a string
                });
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const validateFields = (fields, setErrors) => {
        const newErrors = {};
        let isValid = true;

        if (!fields.vendorName) {
            newErrors.vendorName = true;
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };
  

    const handleSubmit = async () => {
        const vendorUpdate_id = localStorage.getItem('vendorUpdate_id');
    
        // Show a SweetAlert2 warning confirmation before submission
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to update this vendor?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'No, cancel!',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        });
    
        if (result.isConfirmed) {
            if (validateFields(fields, setErrors)) {
                setLoading(true);
                const payload = {
                    id: vendorUpdate_id,
                    vendor_name: fields.vendorName,
                    vendor_short_name: fields.vendorShortName,
                    mobile_number: fields.mobileNumber,
                    gst: fields.gst,
                    city_name: fields.cityName,
                    state: fields.state,
                    pincode: fields.pinCode,
                    bank_account_number: fields.bankAccountNumber,
                    account_holder_name: fields.accountHolderName,
                    ifsc_code: fields.ifscCode,
                    account_type: fields.accountType,
                    address_line1: fields.addressLine1,
                    address_line2: fields.addressLine2,
                    status: fields.status // Include status in payload
                };
    
                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}Vender-update`, payload, {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-access-token': token,
                        },
                    });
    
                    if (response.data.status === 1) {
                        Swal.fire({
                            title: 'Success',
                            text: 'Vendor updated successfully!',
                            icon: 'success',
                            timer: 2000,
                            showConfirmButton: false
                        });
                        setLoading(false);
                        navigate('/vendermanagement');
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: response.data.message,
                            icon: 'error',
                        });
                        setLoading(false);
                    }
                } catch (error) {
                    console.error('Error:', error);
                    Swal.fire({
                        title: 'Error',
                        text: 'An error occurred during the update',
                        icon: 'error',
                    });
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        } else {
            Swal.fire('Cancelled', 'Vendor update has been cancelled', 'info');
        }
    };
    

    const handleSubmitVendor = () => {
        setLoading(true);
        handleSubmit();
    };

    return (
        <div id="addVendorModal">
            <div className="page-header">
                <div className="add-item d-flex justify-content-between">
                    <div className="page-title">
                        <h4>Add Vendor</h4>
                        <h6>Vendor</h6>
                    </div>
                </div>
                <div className="button_add_row">

                </div>
            </div>
            <Card>
                <Card.Header>
                    <Card.Title>
                        Add Vendor
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Container fluid>
                        <Row>
                            {Object.keys(initialFields).map((key, index) => (
                                <Col lg={4} sm={6} xs={12} key={index}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="custom-label">
                                            {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={key.replace(/([A-Z])/g, ' $1')}
                                            value={fields[key]}
                                            onChange={handleFieldChange(key)}
                                            className={errors[key] ? 'is-invalid' : ''}
                                        />
                                        {errors[key] && (
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a valid {key.replace(/([A-Z])/g, ' $1')}.
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                            ))}
                            <Col lg={4} sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Status</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={fields.status}
                                        onChange={handleFieldChange('status')}
                                        className={errors.status ? 'is-invalid' : ''}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Form.Control>
                                    {errors.status && (
                                        <Form.Control.Feedback type="invalid">
                                            Please select a valid status.
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" className="btn btn-submit" onClick={handleSubmitVendor} disabled={loading}>
                            {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Vendor-Update'}
                        </Button>
                    </div>
                </Card.Footer>
            </Card>


            <ToastContainer />
        </div>
    );
};

export default VendorForm;
