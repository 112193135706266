import React, { useState, useEffect } from 'react';
import { FaSearch, FaPlus, FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Card, Row, Col, Form, Button, Table, Modal } from 'react-bootstrap';
import $ from 'jquery';
import 'select2';
import { Link } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Swal from 'sweetalert2';
// import nodata from '.'

export default function Category({ onSave }) {

    const [expenditure_type, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const token = localStorage.getItem("token");
    const [loading, setLoading] = useState(false);


    const onInputChangeName = (e) => {
        setName(e.target.value);
        setNameError(false);
    };


    const Submit = async () => {
        setLoading(true);
        if (!expenditure_type) {
            setNameError(true);
            setLoading(false);
            return;
        }
        try {
            let url = `${process.env.REACT_APP_API_URL}expenditure-type`;
            const requestData = { expenditure_type_name: expenditure_type };
            const config = {
                method: 'POST',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
                data: requestData,
            };

            const response = await axios(config);

            if (response.data.status === 1) {
                toast.success(response.data.message);
                setName('');
                onSave();

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="Category">
            <div className="page-header">
            </div>
            {/* <Row> */}
            <Col md={8} lg={8} col={8} className="justify-content-center">
                <Form.Group controlId="addCategoryName">
                    <Form.Label>Expenditure Type</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Expenditure Type"
                        value={expenditure_type}
                        onChange={onInputChangeName}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                Submit();
                            }
                        }}
                        className={nameError ? 'is-invalid' : ''}
                    />
                    {nameError && <Form.Control.Feedback type="invalid">Please enter a Expenditure Type.</Form.Control.Feedback>}
                </Form.Group>
            </Col>
            {/* </Row> */}
            <Modal.Footer className="d-flex justify-content-end">
                <div>
                    <Button variant="primary" onClick={Submit} disabled={loading}>{loading ? 'Adding...' : 'Create'}</Button>
                </div>
            </Modal.Footer>

            {/* <ToastContainer /> */}
        </div>
    );
}

