import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

export default function AddrowmaterialOrder({ onSave }) {
    const [formData, setFormData] = useState({
        unit_type: '',
        atName: '',
        qty: '',
        sale_price: '',
        unit_sale_price: '',
        selectedvendorId: '',
        selectedcategoryId: '',
        selectedproductId: '',
    });
    const [errors, setErrors] = useState({
        unit_type: false,
        atName: false,
        sale_price: false,
        unit_sale_price: false,
        qty: false,
        selectedvendorId: false,
        selectedproductId: false,
    });
    const [data, setData] = useState([]);
    const [datacat, setDataCat] = useState([]);
    const [datapro, setDataPro] = useState([]);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: false }));
    };

    const validateFields = () => {
        let isValid = true;
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                newErrors[key] = true;
                isValid = false;
            }
        });
        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (url, requestData, successCallback) => {
        setLoading(true);
        try {
            const response = await axios.post(url, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            if (response.data.status === 1) {
                toast.success(response.data.message);
                successCallback();
                onSave();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitAttribute = () => {
        if (validateFields()) {
            handleSubmit(
                `${process.env.REACT_APP_API_URL}Order-add-row`,
                {
                    name: formData.atName,
                    qty: formData.qty,
                    sale_price: formData.sale_price,
                    unit_type: formData.unit_type,
                    unit_sale_price: formData.unit_sale_price,
                    selectedproductId: formData.selectedproductId,
                    vender_id: formData.selectedvendorId,
                    category_id: parseInt(formData.selectedcategoryId),
                },
                () => {
                    setFormData({
                        unit_type: '',
                        atName: '',
                        selectedvendorId: '',
                    });
                    // setTimeout(() => navigate('/rawmaterial'), 1000);
                }
            );
        } else {
            setLoading(false);
        }
    };

    const fetchVender = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Vender-list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setData(response.data.data || []);
        } catch (error) {
            console.error('Error fetching categories:', error);
            toast.error('An error occurred while fetching categories');
        }
    };
    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Category-list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setDataCat(response.data.data || []);
        } catch (error) {
            console.error('Error fetching categories:', error);
            toast.error('An error occurred while fetching categories');
        }
    };
    const fetchProduct = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Product-list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setDataPro(response.data.data || []);
        } catch (error) {
            console.error('Error fetching categories:', error);
            toast.error('An error occurred while fetching categories');
        }
    };

    useEffect(() => {
        fetchCategories();
        fetchVender();
        fetchProduct();
    }, []);

    // New effect to calculate sale_price
    useEffect(() => {
        const qty = parseFloat(formData.qty) || 0;
        const unitSalePrice = parseFloat(formData.unit_sale_price) || 0;
        const calculatedSalePrice = qty * unitSalePrice;
        setFormData((prev) => ({ ...prev, sale_price: calculatedSalePrice.toFixed(2) }));
    }, [formData.qty, formData.unit_sale_price]);

    return (
        <div>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h4>Add Product Raw Material</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label className="custom-label">Category Name</Form.Label>
                                <Form.Select
                                    name="selectedcategoryId"
                                    value={formData.selectedcategoryId}
                                    onChange={onInputChange}
                                    className={errors.selectedcategoryId ? 'is-invalid' : ''}
                                >
                                    <option value="">Category</option>
                                    {datacat.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label className="custom-label">Product Name</Form.Label>
                                <Form.Select
                                    name="selectedproductId"
                                    value={formData.selectedproductId}
                                    onChange={onInputChange}
                                    className={errors.selectedproductId ? 'is-invalid' : ''}
                                >
                                    <option value="">Product</option>
                                    {datapro.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label className="custom-label">Vendor</Form.Label>
                                <Form.Select
                                    name="selectedvendorId"
                                    value={formData.selectedvendorId}
                                    onChange={onInputChange}
                                    className={errors.selectedvendorId ? 'is-invalid' : ''}
                                >
                                    <option value="">Vendor</option>
                                    {data.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.vendor_name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label className="custom-label">Enter Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Name"
                                    name="atName"
                                    value={formData.atName}
                                    onChange={onInputChange}
                                    className={errors.atName ? 'is-invalid' : ''}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label className="custom-label">Unit</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Unit"
                                    name="unit_type"
                                    value={formData.unit_type}
                                    onChange={onInputChange}
                                    className={errors.unit_type ? 'is-invalid' : ''}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Qty</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Qty"
                                    name="qty"
                                    value={formData.qty}
                                    onChange={onInputChange}
                                    className={errors.qty ? 'is-invalid' : ''}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Unit Sale Price</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Sale Price"
                                    name="unit_sale_price"
                                    value={formData.unit_sale_price}
                                    onChange={onInputChange}
                                    className={errors.unit_sale_price ? 'is-invalid' : ''}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Sale Price</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Sale Price"
                                    name="sale_price"
                                    value={formData.sale_price}
                                    onChange={onInputChange}
                                    className={errors.sale_price ? 'is-invalid' : ''}
                                    readOnly
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                <Button
                    variant="primary"
                    onClick={handleSubmitAttribute}
                    className="btn btn-submit"
                    disabled={loading}
                >
                    {loading ? 'Creating...' : 'Create'}
                </Button>
            </Modal.Footer>
            {/* <ToastContainer /> */}
        </div>
    );
}
