import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

const AddProduct = () => {
    const token = localStorage.getItem('token');
    const productUpdate_id = localStorage.getItem('productUpdate_id');
    const navigate = useNavigate();

    const [viewdata, setViewdata] = useState([]);
    const [viewdatatype, setViewdatatype] = useState([]);
    const [formData, setFormData] = useState({});
    const [totalCost, setTotalCost] = useState(0);
    const [totalSale, setTotalSale] = useState(0);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        name: false,
        category_id: false,
        gst: false,
    });

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_API_URL}category-list`, setCategories);
        ProductView();
    }, []);

    useEffect(() => {
        calculateTotals();
    }, [formData]);

    const ProductView = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}product-view/${productUpdate_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            const data = response.data.data;
            setViewdata(data);
            setViewdatatype(response.data.data[0]);
            // console.warn(response.data.data[0])
            setFormData(data.reduce((acc, item) => {
                acc[item.id] = {
                    name: item.name,
                    category_id: item.category_id,
                    cost_price: item.cost_price,
                    sale_price: item.sale_price,
                    gst: item.gst,
                    pro_unit: item.pro_unit,
                    hsn_no: item.hsn_no,
                    man_amount: item.man_amount,
                    sale_man_amount: item.sale_man_amount,
                    pro_qty: item.pro_qty,
                    attributes: item.attributes,
                    row_materials: item.row_materials
                };
                return acc;
            }, {}));
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchData = async (url, setter) => {
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setter(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleChange = (e, itemId, field) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            [itemId]: {
                ...formData[itemId],
                [field]: value
            }
        });
    };

    const handleAttrChange = (e, itemId, attrId) => {
        const { name, value } = e.target;
        const updatedAttributes = formData[itemId].attributes.map(attr => attr.id === attrId ? { ...attr, [name]: value } : attr);
        setFormData({
            ...formData,
            [itemId]: {
                ...formData[itemId],
                attributes: updatedAttributes
            }
        });
    };

    const handleMaterialChange = (e, itemId, materialId) => {
        const { name, value } = e.target;
        const updatedMaterials = formData[itemId].row_materials.map(material =>
            material.id === materialId ? { ...material, [name]: value } : material
        );
        setFormData({
            ...formData,
            [itemId]: {
                ...formData[itemId],
                row_materials: updatedMaterials
            }
        });
    };

    const calculateTotals = () => {
        let totalCostPrice = 0;
        let totalSalePrice = 0;

        Object.values(formData).forEach(item => {
            item.row_materials.forEach(material => {
                if (material.qty && material.u_cost_prize && material.unit_sale_price) {
                    const qty = parseFloat(material.qty);
                    const costPrice = parseFloat(material.u_cost_prize);
                    const salePrice = parseFloat(material.unit_sale_price);
                    const gst = formData[productUpdate_id]?.gst || 0;
                    console.warn(gst)
                    const costPriceWithQty = qty * costPrice;
                    const salePriceWithQty = qty * salePrice * (1 + gst / 100);

                    totalCostPrice += costPriceWithQty;
                    totalSalePrice += salePriceWithQty;
                }
            });
        });

        setTotalCost(totalCostPrice.toFixed(2));
        setTotalSale(totalSalePrice.toFixed(2));
    };

    const validateInputs = () => {
        let isValid = true;
        const newErrors = { name: false, category_id: false, gst: false };

        if (!formData[productUpdate_id]?.name) {
            newErrors.name = true;
            isValid = false;
        }
        if (!formData[productUpdate_id]?.category_id) {
            newErrors.category_id = true;
            isValid = false;
        }
        if (!formData[productUpdate_id]?.gst) {
            newErrors.gst = true;
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };
    const handleUploadProduct = async () => {
        if (!validateInputs()) {
            // Show error message with SweetAlert2
            Swal.fire({
                title: 'Error',
                text: 'Please fill in all required fields.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }
    
        // Show confirmation dialog before proceeding with the update
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to update the product?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'No, cancel!',
        });
    
        if (result.isConfirmed) {
            setLoading(true);
            try {
                const updatedProduct = viewdata.map(item => ({
                    attributes: formData[item.id]?.attributes,
                    row_materials: formData[item.id]?.row_materials,
                }));
    
                const response = await axios.post(`${process.env.REACT_APP_API_URL}product-update`, {
                    products: updatedProduct,
                    id: productUpdate_id,
                    name: formData[productUpdate_id]?.name || '',
                    category_id: formData[productUpdate_id]?.category_id || '',
                    pro_unit: formData[productUpdate_id]?.pro_unit || '',
                    hsn_no: formData[productUpdate_id]?.hsn_no || '',
                    man_amount: formData[productUpdate_id]?.man_amount || '',
                    sale_man_amount: formData[productUpdate_id]?.sale_man_amount || '',
                    pro_qty: formData[productUpdate_id]?.pro_qty || '',
                    cost_price: totalCost,
                    sale_price: totalSale,
                    gst: formData[productUpdate_id]?.gst || '',
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    }
                });
    
                if (response.data.status === 1) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Product updated successfully!',
                        icon: 'success',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                    setTimeout(() => {
                        navigate('/productmodule');
                    }, 1000);
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: 'Failed to update product. Please try again later.',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }
            } catch (error) {
                console.error("There was an error updating the product:", error);
                Swal.fire({
                    title: 'Error',
                    text: 'There was an error updating the product. Please try again later.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } finally {
                setLoading(false);
            }
        } else {
            // User canceled the action
            Swal.fire('Cancelled', 'Product update has been cancelled.', 'info');
        }
    };

    const formatNumber = (num) => {
        return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };

    return (
        <div className="">
            <div className="table-responsive">
                <Card>
                    <Card.Header>
                        <Card.Title>
                            Product Update
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {loading ? (
                            <div className="text-center py-4">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        ) : (
                            <table className="table">
                                <thead>
                                    <tr className='thead-dark'>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Category Id</th>
                                        <th>GST</th>
                                        <th>Unit</th>
                                        <th>Hsn</th>
                                        <th>Qty</th>
                                        <th>Cost Amount</th>
                                        <th>Sale Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {viewdata.map((item) => (
                                        <React.Fragment key={item.id}>
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                        name="name"
                                                        value={formData[item.id]?.name || ''}
                                                        onChange={(e) => handleChange(e, item.id, 'name')}
                                                    />
                                                    {errors.name && <div className="invalid-feedback">Name is required.</div>}
                                                </td>
                                                <td>
                                                    <Form.Select
                                                        value={formData[item.id]?.category_id || ''}
                                                        className={errors.category_id ? 'is-invalid' : ''}
                                                        onChange={(e) => handleChange(e, item.id, 'category_id')}
                                                    >
                                                        <option value="">Select Category</option>
                                                        {categories.map((cat) => (
                                                            <option key={cat.id} value={cat.id}>
                                                                {cat.name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    {errors.category_id && <div className="invalid-feedback">Category ID is required.</div>}
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className={`form-control ${errors.gst ? 'is-invalid' : ''}`}
                                                        name="gst"
                                                        value={formData[item.id]?.gst || ''}
                                                        onChange={(e) => handleChange(e, item.id, 'gst')}
                                                    />
                                                    {errors.gst && <div className="invalid-feedback">GST is required.</div>}
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className={`form-control`}
                                                        name="pro_unit"
                                                        value={formData[item.id]?.pro_unit || ''}
                                                        onChange={(e) => handleChange(e, item.id, 'pro_unit')}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className={`form-control`}
                                                        name="hsn_no"
                                                        value={formData[item.id]?.hsn_no || ''}
                                                        onChange={(e) => handleChange(e, item.id, 'hsn_no')}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className={`form-control`}
                                                        name="pro_qty"
                                                        value={formData[item.id]?.pro_qty || ''}
                                                        onChange={(e) => handleChange(e, item.id, 'pro_qty')}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className={`form-control`}
                                                        name="man_amount"
                                                        value={formData[item.id]?.man_amount ? formatNumber(formData[item.id]?.man_amount) : ''}
                                                        onChange={(e) => handleChange(e, item.id, 'man_amount')}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className={`form-control`}
                                                        name="sale_man_amount"
                                                        value={formData[item.id]?.sale_man_amount?formatNumber(formData[item.id]?.sale_man_amount) : ''}
                                                        onChange={(e) => handleChange(e, item.id, 'sale_man_amount')}
                                                    />
                                                </td>
                                            </tr>
                                            {item.manufacture === 'Trading' ? (
                                                <>
                                                    <tr>
                                                        <td colSpan="4">
                                                            <h6 className="fw-bold">Row Materials</h6>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr className='thead-primary'>
                                                                        <th>Id</th>
                                                                        <th>Qty</th>
                                                                        <th>Unit Cost Price</th>
                                                                        <th>Unit Sale Price</th>
                                                                        <th>Cost Price</th>
                                                                        <th>Sale Price</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {formData[item.id]?.row_materials.map(material => (
                                                                        <tr key={material.id}>
                                                                            <td>{material.id}</td>
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    name="qty"
                                                                                    value={material.qty || ''}
                                                                                    onChange={(e) => handleMaterialChange(e, item.id, material.id)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="u_cost_prize"
                                                                                    value={material.u_cost_prize || ''}
                                                                                    onChange={(e) => handleMaterialChange(e, item.id, material.id)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    name="unit_sale_price"
                                                                                    value={material.unit_sale_price || ''}
                                                                                    onChange={(e) => handleMaterialChange(e, item.id, material.id)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control graycolorall"
                                                                                    name="Costprice"
                                                                                    value={(material.qty * material.u_cost_prize).toFixed(2)}
                                                                                    readOnly
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control graycolorall"
                                                                                    name="Saleprice"
                                                                                    value={((material.qty * material.unit_sale_price) * (1 + (formData[item.id]?.gst / 100))).toFixed(2)}
                                                                                    readOnly
                                                                                />

                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4">
                                                            <h6 className="fw-bold">Attributes</h6>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr className='thead-info'>
                                                                        <th>Id</th>
                                                                        <th>Name</th>
                                                                        <th>Value</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {formData[item.id]?.attributes.map(attr => (
                                                                        <tr key={attr.id}>
                                                                            <td>{attr.id}</td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="name"
                                                                                    value={attr.name || ''}
                                                                                    onChange={(e) => handleAttrChange(e, item.id, attr.id)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    name="value"
                                                                                    value={attr.value || ''}
                                                                                    onChange={(e) => handleAttrChange(e, item.id, attr.id)}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </>
                                            ) : null}
                                        </React.Fragment>
                                    ))}
                                    {viewdatatype.manufacture === 'Trading' ? (
                                        <tr>
                                            <td colSpan={4}>
                                                <div className="d-flex justify-content-between gap-2">
                                                    <div className='w-100'>
                                                        <label htmlFor="">Total Cost</label>
                                                        <input
                                                            type="text"
                                                            className="form-control graycolorall"
                                                            value={totalCost}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className='w-100'>
                                                        <label htmlFor="">Total Sale Price</label>
                                                        <input
                                                            type="text"
                                                            className="form-control graycolorall"
                                                            value={totalSale}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ) : null}
                                </tbody>
                            </table>
                        )}
                    </Card.Body>
                    <Card.Footer>
                        <div className="d-flex justify-content-end">
                            <Button onClick={handleUploadProduct} disabled={loading}>
                                {loading ? 'Updating...' : 'Update Product'}
                            </Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>

            <ToastContainer />
        </div>
    );
};

export default AddProduct;
