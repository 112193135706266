import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { FaEdit } from 'react-icons/fa';  // Importing edit icon

const useFormFields = (initialFields) => {
    const [fields, setFields] = useState(initialFields);
    const [errors, setErrors] = useState(
        Object.keys(initialFields).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    );

    const handleFieldChange = (key) => (e) => {
        let value = e.target.value;

        if (key === 'mobileNumber') {
            value = value.replace(/\D/g, '').slice(0, 10);
        }
        if (key === 'Salary') {
            value = value.replace(/\D/g, '');
        }

        setFields({ ...fields, [key]: value });
        setErrors({ ...errors, [key]: false });
    };

    return { fields, errors, handleFieldChange, setFields, setErrors };
};

const validateFields = (fields, setErrors) => {
    const newErrors = {};
    let isValid = true;

    if (!fields.CompanyName) {
        newErrors.CompanyName = true;
        isValid = false;
    }

    setErrors(newErrors);
    return isValid;
};

const CompanyDetails = ({ onSave }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    const initialFields = {
        CompanyName: '',
        GstinNumber: '',
        Address: '',
        City: '',
        State: '',
        PinCode: '',
        Mobile: '',
        Email: '',
        BankName: '',
        AccountNo: '',
        IfscCode: '',
        BankAddress: '',
        AccHolderName: '',
    };

    const { fields, errors, handleFieldChange, setFields, setErrors } = useFormFields(initialFields);
    const [loading, setLoading] = useState(false);
    const [isEditable, setIsEditable] = useState(false); // State to toggle edit mode

    useEffect(() => {
        const fetchCompanyDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Company-details-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });

                if (response.data.status === 1) {
                    const companyDetails = response.data.data[0];
                    setFields({
                        CompanyName: companyDetails.company_name || '',
                        GstinNumber: companyDetails.gstin || '',
                        State: companyDetails.state || '',
                        BankName: companyDetails.bank_name || '',
                        Email: companyDetails.email || '',
                        Mobile: companyDetails.mobile || '',
                        City: companyDetails.city || '',
                        PinCode: companyDetails.pincode || '',
                        BankAddress: companyDetails.bank_address || '',
                        AccountNo: companyDetails.account_no || '',
                        IfscCode: companyDetails.ifce_code || '',
                        Address: companyDetails.company_address || '',
                        AccHolderName: companyDetails.acc_holder_name || '',
                    });
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error fetching company details:', error);
                toast.error('An error occurred while fetching company details.');
            }
        };

        fetchCompanyDetails();
    }, [setFields, token]);

    const handleSubmit = async () => {
        const payload = {
            id: 1,
            company_name: fields.CompanyName,
            company_address: fields.Address,
            gstin: fields.GstinNumber,
            state: fields.State,
            bank_name: fields.BankName,
            bank_address: fields.BankAddress,
            account_no: fields.AccountNo,
            ifce_code: fields.IfscCode,
            acc_holder_name: fields.AccHolderName,
            city: fields.City,
            pincode: fields.PinCode,
            email: fields.Email,
            mobile: fields.Mobile,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Company-details-update`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });

            if (response.data.status === 1) {
                toast.success(response.data.message);
                onSave();
                setIsEditable(false); // Switch back to view mode after saving
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitEmployee = () => {
        if (validateFields(fields, setErrors)) {
            setLoading(true);
            handleSubmit();
        } else {
            setLoading(false);
        }
    };

    return (
        <div id="addVendorModal">
            <Card>
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <Card.Title>Company Details</Card.Title>
                        <Button variant="primary" onClick={() => setIsEditable(!isEditable)}>
                            {isEditable ? 'Cancel' : 'Edit'}
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Container fluid>
                        <Row>
                            {Object.keys(initialFields).map((key, index) => (
                                <Col lg={4} sm={6} xs={12} key={index}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="custom-label">
                                            {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                        </Form.Label>
                                        <div className="d-flex align-items-center">
                                            <Form.Control
                                                type="text"
                                                placeholder={key.replace(/([A-Z])/g, ' $1')}
                                                value={fields[key]}
                                                onChange={handleFieldChange(key)}
                                                className={errors[key] ? 'is-invalid' : ''}
                                                readOnly={!isEditable} 
                                            />
                                            {isEditable && <FaEdit className="ms-1 editfield" />} 
                                        </div>
                                    </Form.Group>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex justify-content-end">
                        {isEditable && (
                            <Button variant="primary" className="btn btn-submit" onClick={handleSubmitEmployee} disabled={loading}>
                                {loading ? 'Updating...' : 'Update'}
                            </Button>
                        )}
                    </div>
                </Card.Footer>
            </Card>
            <ToastContainer />
        </div>
    );
};

export default CompanyDetails;
