import React, { useState, useEffect } from 'react';
import { FaSearch, FaPlus, FaEdit } from "react-icons/fa";
import { Button, Row, Col, Form, Modal, Container } from 'react-bootstrap';
import { MdDelete } from "react-icons/md";
// import { Button, Form, Modal } from 'react-bootstrap';
import $ from 'jquery';
import 'select2';
import { Link } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Swal from 'sweetalert2';
import Vendors_Payment from './Venderpayment';
import nodata from '../assets/img/nodata.svg'
import { useNavigate } from 'react-router-dom';
import { FaEye } from "react-icons/fa";
import { FaCcAmazonPay } from "react-icons/fa";


export default function Vendermanagement() {
    const [data, setData] = useState([]);
    const [loadinglist, setLoadinglist] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const token = localStorage.getItem("token");
    const [vendorData, setViewdata] = useState([]);
    const handleCloseEditModal = () => setShowEditModal(false);
    const handleShowEditModal = () => setShowEditModal(true);
    const [showEditModal, setShowEditModal] = useState(false);
    const [modalState, setModalState] = useState({ paymeny_update: false });
    const navigate = useNavigate();



    useEffect(() => {
        if (!modalState.paymeny_update) {
            Venderlist();
        }
    }, [modalState.paymeny_update]);

    const PaymentUpdate = (id) => {
        setModalState({ ...modalState, paymeny_update: true })
    }
    const Venderlist = async () => {
        setLoadinglist(true);
        try {
            let url = `${process.env.REACT_APP_API_URL}Vender-list`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            setData(response.data.data);

            // Calculate total pages based on the fetched data
            setTotalPages(Math.ceil(response.data.data.length / itemsPerPage));
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setLoadinglist(false);
        }
    };




    const VenderDelete = async (id) => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });
            if (result.isConfirmed) {
                let url = `${process.env.REACT_APP_API_URL}Vender-delete/${id}`;
                const config = {
                    method: 'get',
                    url: url,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                };
                const response = await axios(config);
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    Venderlist();
                } else {
                    toast.error(response.data.message);
                }
            }
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setLoadinglist(false);
        }
    };

    const VenderView = async (id) => {
        // setCurrentEditId(id);
        handleShowEditModal();
        // setLoadinglist(true);
        try {
            let url = `${process.env.REACT_APP_API_URL}Vender-edit/${id}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            setViewdata(response.data.data[0]);
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setLoadinglist(false);
        }
    };

    const VendorUpdate = async (id) => {
        localStorage.setItem("vendorUpdate_id", id);
        navigate('/Vendor-Update');
    };
    const OrderDetails = async (id) => {
        localStorage.setItem("OrderDetails_id", id);
        navigate('/Order-Details');
    };

    const LadgerList = async (id) => {
        localStorage.setItem("ven_ladger_id", id);
        navigate('/vendor-ladgor');
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        const selectedItemsPerPage = parseInt(e.target.value);
        setItemsPerPage(selectedItemsPerPage);

        // Recalculate total pages based on the new items per page value
        setTotalPages(Math.ceil(filteredData.length / selectedItemsPerPage));
    };

    const filteredData = data ? data.filter(item =>
        item.vendor_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.account_holder_name.toLowerCase().includes(searchQuery.toLowerCase())
    ) : [];




    const paginatedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const renderPagination = () => {
        const pageNumbers = [];
        const totalPages = Math.ceil(filteredData.length / itemsPerPage);
        const maxPageButtons = 10;
        const ellipsis = '...';

        let startPage, endPage;

        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= Math.floor(maxPageButtons / 2)) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + Math.floor(maxPageButtons / 2) >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - Math.floor(maxPageButtons / 2);
                endPage = currentPage + Math.floor(maxPageButtons / 2);
            }
        }

        // Adjust startPage and endPage if totalPages < maxPageButtons
        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= Math.floor(maxPageButtons / 2)) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + Math.floor(maxPageButtons / 2) >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - Math.floor(maxPageButtons / 2);
                endPage = currentPage + Math.floor(maxPageButtons / 2);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <>
                        <button onClick={() => handlePageChange(1)}>{1}</button>
                        {startPage > 2 && <span>{ellipsis}</span>}
                    </>
                )}
                {pageNumbers.map(number => (
                    <button key={number} onClick={() => handlePageChange(number)} className={number === currentPage ? 'active' : ''}>
                        {number}
                    </button>
                ))}
                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && <span>{ellipsis}</span>}
                        <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                    </>
                )}
            </div>
        );
    };
    return (
        <div className="Category">
            <div className="page-header">
                <div className="add-item d-flex justify-content-between">
                    <div className="page-title">
                        <h4>Vendor Management</h4>
                        <h6>Manage Your Vendor List</h6>
                    </div>

                </div>
                <div className='button_add_row'>
                    <Link to="/vendor-payment" style={{ "--i": "#488223" }} className="btn btn-added p-2 me-3">Vendor Payment</Link>
                </div>
                <div className="button_add_row">
                    <Link to='/Add-Vendors' className="btn btn-added d-flex gap-2">
                        <FaPlus />  Add Vendor

                    </Link>
                </div>
            </div>
            <div className="card table-list-card">
                <div className="card-header d-flex justify-content-between">
                    <div className="Category">
                        {/* Existing JSX code */}
                        <div>
                            <select onChange={handleItemsPerPageChange} className='form-select' value={itemsPerPage}>
                                <option value={10}>Items 10</option>
                                <option value={20}>Items 20</option>
                                <option value={30}>Items 30</option>
                                <option value={50}>Items 50</option>
                            </select>
                        </div>
                        {/* Existing JSX code */}
                    </div>
                    <div className="searchinputs d-flex">
                        <input type="text" placeholder="Search" value={searchQuery} onChange={handleSearch} />
                        <div className="btn d-flex align-items-center justify-content-center">
                            <FaSearch />
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table-striped bordered hover table">
                            <thead>
                                <tr className="thead-dark">
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    {/* <th>Address</th> */}
                                    <th>Amount</th>
                                    <th>Paid Amount</th>
                                    <th>Due Amount</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>

                                {loadinglist ? (
                                    <tr>
                                        <td colSpan="6" className='text-center'>
                                            <div>Loading...</div>
                                        </td>
                                    </tr>
                                ) : (
                                    paginatedData && paginatedData.length > 0 ? (
                                        paginatedData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td>{item.vendor_name}</td>
                                                <td>{item.mobile_number}</td>
                                                {/* <td>{item.address_line1}</td> */}
                                                <td>{new Intl.NumberFormat().format(item.total_amount)}</td>
                                                <td>{new Intl.NumberFormat().format(item.total_amount_payments_receive)}</td>
                                                <td>{new Intl.NumberFormat().format(item.due_amount)}</td>
                                                <td
                                                    style={{
                                                        color: item.status === 1 ? 'green' : 'red'
                                                    }}
                                                >
                                                    {item.status === 1 ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                                </td>
                                                <td className="edit-delete-action justify-content-start d-flex gap-2">

                                                    <Link to="#" style={{ "--i": "#233882" }} className="p-2" onClick={() => VenderView(item.id)}><FaEye /></Link>
                                                    <Button to="javascript:void(0)" style={{ "--i": "#233882" }} className="p-2" onClick={() => VendorUpdate(item.id)}><FaEdit /></Button>
                                                    <Link to="javascript:void(0)" style={{ "--i": "red" }} className="p-2" onClick={() => VenderDelete(item.id)}><MdDelete /></Link>
                                                    {/* <Link to="#" style={{ "--i": "#488223" }} className="p-2" onClick={() => PaymentUpdate(item.id)}>Pay</Link> */}
                                                    <Link to="javascript:void(0)" style={{ "--i": "#237982" }} className="p-2" onClick={() => OrderDetails(item.id)}>Order Details</Link>
                                                    <Link to="javascript:void(0)" style={{ "--i": "#ff9900" }} className="p-2" onClick={() => LadgerList(item.id)}>Ledger</Link>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="8" className='text-center'>
                                                <img src={nodata} alt="nodata" width={250} />
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td colSpan={7}>
                                        <span>Pages: <strong>{totalPages} </strong> </span>

                                        <span>Of <strong>{itemsPerPage}</strong></span>
                                    </td>
                                    <td className='d-flex justify-content-end'>
                                        {renderPagination()}

                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <Modal show={showEditModal} size="lg" onHide={handleCloseEditModal} data-backdrop="static" data-keyboard="false">
                <Modal.Header closeButton>
                    <Modal.Title><h4>View Details</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>Vendor Name:</th>
                                    <td>{vendorData.vendor_name}</td>
                                </tr>
                                <tr>
                                    <th>Vendor Short Name:</th>
                                    <td>{vendorData.vendor_short_name}</td>
                                </tr>
                                <tr>
                                    <th>Mobile Number:</th>
                                    <td>{vendorData.mobile_number}</td>
                                </tr>
                                <tr>
                                    <th>Gst:</th>
                                    <td>{vendorData.gst}</td>
                                </tr>
                                <tr>
                                    <th>City Name:</th>
                                    <td>{vendorData.city_name}</td>
                                </tr>
                                <tr>
                                    <th>State:</th>
                                    <td>{vendorData.state}</td>
                                </tr>
                                <tr>
                                    <th>Pin Code:</th>
                                    <td>{vendorData.pincode}</td>
                                </tr>
                                <tr>
                                    <th>Bank Account Number:</th>
                                    <td>{vendorData.bank_account_number}</td>
                                </tr>
                                <tr>
                                    <th>Account Holder Name:</th>
                                    <td>{vendorData.account_holder_name}</td>
                                </tr>
                                <tr>
                                    <th>Ifsc Code:</th>
                                    <td>{vendorData.ifsc_code}</td>
                                </tr>
                                <tr>
                                    <th>Account Type:</th>
                                    <td>{vendorData.account_type}</td>
                                </tr>
                                <tr>
                                    <th>Address Line1:</th>
                                    <td>{vendorData.address_line1}</td>
                                </tr>
                                <tr>
                                    <th>Address Line2</th>
                                    <td>{vendorData.address_line2}</td>
                                </tr>
                                <tr>
                                    <th>Status:</th>
                                    <td
                                        style={{
                                            color: vendorData.status === 1 ? 'green' : 'red'
                                        }}
                                    >
                                        {vendorData.status === 1 ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
                                    </td>
                                </tr>

                                {/* Render other fields similarly */}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                size='xl'
                show={modalState.paymeny_update}
                onHide={() => setModalState({ ...modalState, paymeny_update: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static" 
          keyboard={false}
            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">Vendor Payment Add</h3>
                </Modal.Header>
                <Modal.Body>
                    <Vendors_Payment onSave={() => setModalState({ ...modalState, paymeny_update: false })} />
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </div>
    );
}

