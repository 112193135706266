import React, { useState, useMemo, useEffect } from 'react';
import { Card, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { MdDelete } from 'react-icons/md';
import Vendorproduct from '../Vendorproduct';
import { FiPlusCircle } from 'react-icons/fi';
import { HiOutlineMinusCircle } from 'react-icons/hi';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Paymentdetailspage from './Vendorreporttable';
import Customerpayment_Update from './Customerpayment_Update';
import Vendorproductpayment from './Vendorreporttable';
import { format } from 'date-fns';
import { FaSearch, FaPlus, FaEdit } from "react-icons/fa";


export default function Customerpayment() {
    const token = localStorage.getItem('token');
    const [venderpaymentlist, setvandorpaymentlist] = useState([]);
    const [selectedCustomerId, setselectedCustomerId] = useState(null);
    const [modalState, setModalState] = useState({ paymeny_update: false });

    const initialFields = {
        VenderName: '',
        AccountName: '',
        CustumerNameSearch: '',
        CustumerName: '',
        Amount: '',
        Mode: '',
        Date: '',
    };

    const [fields, setFields] = useState(initialFields);
    const [loading, setLoading] = useState(false);
    const [selectoption, setSelectoption] = useState(null);
    const [venderlist, setVenderlist] = useState([]);
    const [vendorreprotlist, setvendorreprotlist] = useState([]);
    const [customerDuelist, setcustomerDuelist] = useState('');
    // console.warn(customerDuelist);
    const [custumerlist, setCustumerlist] = useState([]);
    const [accountlist, setAccountlist] = useState([]);
    const [errors, setErrors] = useState({});
    const [counters, setCounters] = useState([]);
    const [vendorData, setVendorData] = useState([]);

    const handleChange = (value, name) => {
        if (name === 'Mode') {
            setSelectoption(value);
            setFields({ ...fields, Mode: value.value });
        } else if (name === 'AccountName') {
            setFields({ ...fields, AccountName: value });
        } else if (name === 'CustumerName') {
            setFields({ ...fields, CustumerName: value });
            setselectedCustomerId(value);
        }
        else if (name === 'CustumerNameSearch') {
            setFields({ ...fields, CustumerNameSearch: value });
            setselectedCustomerId(value);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Remove commas and ensure it's treated as a number
        const rawValue = value.replace(/,/g, '');

        // Update the state with the raw number value
        setFields({
            ...fields,
            [name]: rawValue
        });
    };


    const validateFields = () => {
        const errors = {};
        if (!fields.CustumerName) errors.CustumerName = 'Custumer Name is required';
        if (!fields.AccountName) errors.AccountName = 'Account Name is required';
        if (!fields.Amount) errors.Amount = 'Amount is required';
        if (!fields.Mode) errors.Mode = 'Payment Mode is required';
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form fields
        const errors = validateFields();
        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to Receive the Payment?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'No, cancel!',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        });

        if (result.isConfirmed) {

            setErrors({});
            setLoading(true);

            const payload = {
                customer_id: fields.CustumerName,
                account_id: fields.AccountName,
                amount: fields.Amount,
                mode: fields.Mode,
                // type: fields.Type,
                date: fields.Date || new Date().toISOString().split("T")[0],
            };

            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}Customer-payment-store`,
                    payload,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-access-token': token,
                        },
                    }
                );
                if (response.data.status === 1) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Recieve Successfully!',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false
                    });
                    setFields(initialFields);
                    setSelectoption(null);
                    RowMatrialslist();
                    fetchVendorReport();
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: response.data.message,
                        icon: 'error',
                    });

                }

            } catch (error) {
                console.error('Error:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'An error occurred during the Add',
                    icon: 'error',
                });
            } finally {
                setLoading(false);
            }
        } else {
            Swal.fire('Cancelled', 'Amount Transfer has been cancelled', 'info');
        }
    };

    useEffect(() => {
        if (!modalState.paymeny_update) {
            fetchVendorReport();
            RowMatrialslist();
        }
    }, [selectedCustomerId, modalState.paymeny_update]);


    const fetchVendorReport = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Customer-report-list/${selectedCustomerId}`,
                { customer_id: selectedCustomerId }, // Payload for the request
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
            setvendorreprotlist(response.data.data || []);
            setcustomerDuelist(response.data.customer_due_amount);
            // alert(response.data.customer_due_amount);
            // console.warn(response.data.customer_due_amount)
        } catch (error) {
            console.error('Error fetching Vendor report:', error);
        }
    };

    useEffect(() => {
        const fetchCustumer = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Custumer-name-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setCustumerlist(response.data.data || []);
            } catch (error) {
                console.error('Error fetching Vendor:', error);
            }
        };

        fetchCustumer();
    }, [token]);

    useEffect(() => {
        const fetchAccount = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Account-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setAccountlist(response.data.data || []);
            } catch (error) {
                console.error('Error fetching Account:', error);
            }
        };

        fetchAccount();
    }, [token]);



    const options = [
        { value: 'Online', label: 'Online' },
        { value: 'Cash', label: 'Cash' },
    ];

    const title = "View Payment Details";
    const title1 = "Customer Report";

    const RowMatrialslist = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Customer-payment-list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setvandorpaymentlist(response.data.data || []);
            console.warn(response.data.data)
        } catch (error) {
            console.error('Error fetching Vendor:', error);
        }
    };



    const transformedVendorPaymentList = useMemo(() => {
        return venderpaymentlist.map(item => ({
            ...item,
            totaldue: item.total_payments - item.totalSum,
        }));
    }, [venderpaymentlist]);
    const PaymentUpdate = (id) => {
        setModalState({ ...modalState, paymeny_update: true })
        localStorage.setItem('payment_update', id)
    }


    const Vendorproduct1 = useMemo(
        () => [
            {
                Header: 'Sr No',
                accessor: (row, i) => i + 1,
            },
            {
                Header: 'Date',
                accessor: 'created_at',
                Cell: ({ value }) => {
                    return format(new Date(value), 'dd-MM-yyyy');
                },
            },
            {
                Header: 'Customer Name',
                accessor: 'customer_name',
            },
            // {
            //     Header: 'Credit/Receive',
            //     accessor: 'Creditreceive',
            //     Cell: () => 'Receive'
            // },
            {
                Header: 'Amount',
                accessor: 'amount',
                Cell: ({ value }) => {
                    if (value !== undefined) {
                        // Convert string with commas to a valid number
                        const cleanedValue = typeof value === 'string'
                            ? Number(value.replace(/,/g, ''))
                            : value;

                        // Check if cleanedValue is a valid number before formatting
                        return !isNaN(cleanedValue) ? new Intl.NumberFormat().format(cleanedValue) : value;
                    }
                    return null;
                }
            },

            {
                Header: 'Due Amount',
                accessor: 'due_amt',
                Cell: ({ value }) => (
                    value !== undefined ? (
                        new Intl.NumberFormat().format(value)
                    ) : null
                ),
            },
            {
                Header: 'Account',
                accessor: 'account_name',
            },
            {
                Header: 'Payment Type',
                accessor: 'type',
                Cell: ({ value }) => (
                    <span style={{ color: value === 'pay' ? 'green' : value === 'refund' ? 'red' : 'black' }}>
                        {value}
                    </span>
                ),
            },
            {
                Header: 'Payment Mode',
                accessor: 'mode',
            },
            {
                Header: 'Action',
                Cell: ({ row }) => (
                    <Button
                        to="#"
                        style={{ "--i": "red" }}
                        onClick={() => PaymentUpdate(row.original.id)}
                        className="p-2"
                    >
                        <FaEdit />
                    </Button>
                )
            }


        ],
        []
    );

    return (
        <div>
            <Card>
                <Card.Header>
                    <Card.Title>Select Payment</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={4} sm={12}>
                                <Form.Group>
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        type="text" // Change to text for formatted value
                                        placeholder="Enter Amount"
                                        name="Amount"
                                        value={new Intl.NumberFormat().format(fields.Amount)} // Format the number with commas
                                        onChange={(e) => handleInputChange(e)} // Handle input change
                                        style={{ borderColor: errors.Amount ? 'red' : '' }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={4} sm={12}>
                                <Form.Group>
                                    <Form.Label>Customer Name</Form.Label>
                                    <Form.Select
                                        value={fields.CustumerName}
                                        onChange={(e) => handleChange(e.target.value, 'CustumerName')}
                                        style={{ borderColor: errors.CustumerName ? 'red' : '' }}
                                    >
                                        <option value="">Select Customer Name</option>
                                        {custumerlist.map((vendor) => (
                                            <option key={vendor.id} value={vendor.id}>
                                                {vendor.customer_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>


                            <Col md={4} sm={12}>
                                <Form.Group>
                                    <Form.Label>Account Name</Form.Label>
                                    <Form.Select
                                        value={fields.AccountName}
                                        onChange={(e) => handleChange(e.target.value, 'AccountName')}
                                        style={{ borderColor: errors.AccountName ? 'red' : '' }}
                                    >
                                        <option value="">Select Account Name</option>
                                        {accountlist.map((vendor) => (
                                            <option key={vendor.id} value={vendor.id}>
                                                {vendor.account_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={4} sm={12}>
                                <Form.Group>
                                    <Form.Label>Select Mode</Form.Label>
                                    <Select
                                        value={selectoption}
                                        onChange={(value) => handleChange(value, 'Mode')}
                                        options={options}
                                        isSearchable={true}
                                        styles={errors.Mode ? { control: (base) => ({ ...base, borderColor: 'red' }) } : {}}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3} sm={12}>
                                <Form.Group>
                                    <Form.Label>Due Amount</Form.Label>
                                    <Form.Control
                                        type="text" 
                                        value={new Intl.NumberFormat().format(
                                            Math.max(0, (customerDuelist || 0) - (fields.Amount || 0)) // Ensure the value is never below 0
                                        )}
                                        className="disabled_input"
                                        disabled
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={4} sm={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Date"
                                        value={fields.Date || new Date().toISOString().split("T")[0]}
                                        onFocus={(e) => e.target.showPicker()}
                                        onChange={handleChange('Date')}
                                        className={errors.Date ? 'is-invalid' : ''}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12} sm={12}>
                                <div className="d-flex justify-content-end">
                                    <Button type="submit" className="btn-added" disabled={loading}>
                                        {loading ? 'Submitting...' : 'Receive'}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    <hr />
                    {/* <Paymentdetailspage title={title} columns={Vendorproduct} data={transformedVendorPaymentList} /> */}
                    {/* <hr></hr> */}
                    {/* <div className='row justify-content-center'>
                        <Col md={3} sm={12}>
                            <Form.Group>
                                <Form.Select
                                    value={fields.CustumerNameSearch}
                                    onChange={(e) => handleChange(e.target.value, 'CustumerNameSearch')}
                                >
                                    <option value="">Select Custumer Name</option>
                                    {custumerlist.map((vendor) => (
                                        <option key={vendor.id} value={vendor.id}>
                                            {vendor.customer_name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </div> */}
                    <Vendorproductpayment title={title1} columns={Vendorproduct1} data={vendorreprotlist} />
                    {vendorreprotlist.length > 0 ? (
                        null
                    ) : (
                        <p style={{ color: "red", textAlign: "center" }}>Please select a Customer to view payment details.</p>
                    )}
                </Card.Body>
            </Card>
            <Modal
                size='xl'
                show={modalState.paymeny_update}
                onHide={() => setModalState({ ...modalState, paymeny_update: false })}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">Customer Payment Update</h3>
                </Modal.Header>
                <Modal.Body>
                    <Customerpayment_Update onSave={() => setModalState({ ...modalState, paymeny_update: false })} />
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </div>
    );
}
