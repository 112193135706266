import React, { useState, useEffect, useMemo } from 'react';
import { Container, Card, Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DataTable from './Stocktable';

export default function DueView({ onSave }) {
    const token = localStorage.getItem('token');
    const [materials, setMaterial] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');

    const columns = useMemo(
        () => [
            {
                Header: 'Customer Name',
                accessor: 'customer_name',
            },
            {
                Header: 'Total Paid',
                accessor: 'total_amount',
            },
            {
                Header: 'Total Receive',
                accessor: 'amount',
            },
            {
                Header: 'Total Due',
                accessor: 'due_amt',
                Cell: ({ row }) => {
                    const { total_amount, amount, due_amt, updated_at } = row.original;
                    const { color } = getDueAmountColor(updated_at, due_amt, total_amount, amount);
                    return (
                        <span style={{ color }}>
                            {due_amt}
                        </span>
                    );
                }
            },
            {
                Header: 'Date',
                accessor: 'formatted_date',
                Cell: ({ row }) => formatDate(row.original.updated_at),
            },
            {
                Header: 'Days',
                id: 'days_since_updated', 
                accessor: (row) => {
                    const { updated_at, total_amount, amount, due_amt } = row;
                    const { dateDiff } = getDueAmountColor(updated_at, due_amt, total_amount, amount);
                    return dateDiff;
                },
            },
            
        ],
        []
    );



    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const url = `${process.env.REACT_APP_API_URL}Customer-payment-list`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            setMaterial(response.data.data || []);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    // const getDueAmountColor = (updated_at, due_amt, total_amount, amount) => {
    //     const updatedDate = new Date(updated_at);
    //     const currentDate = new Date();
    //     const dateDiff = Math.floor((currentDate - updatedDate) / (1000 * 60 * 60 * 24));

    //     if (dateDiff > 1) {
    //         return { color: 'red', dateDiff };
    //     }

    //     return { color: 'green', dateDiff };
    // };

    const getDueAmountColor = (updated_at, due_amt, total_amount, amount) => {
        const updatedDate = new Date(updated_at);
        const currentDate = new Date();

        // Reset the time to 00:00:00 for an accurate day difference calculation
        updatedDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        // Calculate the difference in days
        const dateDiff = Math.floor((currentDate - updatedDate) / (1000 * 60 * 60 * 24));

        // Determine the color based on the date difference
        const color = dateDiff > 1 ? 'red' : 'green';

        return { color, dateDiff };
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value); // No need to parseInt, keep it as a string
      };

      const filteredOrderList = selectedStatus ? materials.filter(item => item.customer_type === selectedStatus) : materials;

    return (
        <>
            <Card>
                <Container fluid>
                    <Row className="p-2">
                        <Col md={6} className="d-flex justify-content-start">
                            <h3>Order Due View</h3>
                        </Col>
                        <Col md={6} className="d-flex justify-content-end">
                            <Link to="/orderview">
                                <Button variant="dark">Back</Button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </Card>
            <div className='justify-content-center processing'>
        <div className='text-center'>
          <Row>
            <Col md={12} xs={12} lg={12} className='duedrop'>
              <Form.Select aria-label="Default select example" onChange={handleStatusChange} value={selectedStatus} style={{ border: '1px solid #8080802' }}>
                <option value=''>Customer Type</option>
                <option value="Retail">Retail</option>
                <option value="Site">Site</option>
                <option value="Design">Design</option>
              </Form.Select>
            </Col>
          </Row>
        </div>
      </div>

            <DataTable columns={columns} data={filteredOrderList.filter(product => product.due_amt > 0)} />
        </>
    );
}
