import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddVendor from '../commanpage/AddVendor';
import AddCategory from '../commanpage/Categories';
import Addlocation from '../Stock/Product_Location';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';


export default function Addstock({ onSave }) {
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [venderlist, setVenderlist] = useState([]);
    const [productstocklocationid, setLocationid] = useState('')
    const [vendorid, setVendorid] = useState('')
    const [invoice_no, setInvoice] = useState('')
    const navigate = useNavigate();

    const [date, setDate] = useState('')
    const [productlocation, setproductlocation] = useState([]);
    const [modalState, setModalState] = useState({
        addVendor: false,
        addCategoryt: false,
        addLocation: false,
    });
    const handleLocationChange = (e) => {
        setLocationid(e.target.value);
    };
    const handleVendorChange = (e) => {
        setVendorid(e.target.value);
    };
    const handleInvoiceChange = (e) => {
        setInvoice(e.target.value);
    };
    const handleDateChange = (e) => {
        setDate(e.target.value);
    };
    const [manufacture] = useState('Manufacture');
    const [rows, setRows] = useState([{
        // date: '',
        // vendor_id: null,
        category_id: null,
        name: '',
        pro_unit: '',
        hsn_no: '',
        pro_qty: '',
        gst: '',
        price: '',
        sale_price: '',
        // invoice: '',
        manufacture,
        total_amount: ''
    }]);
    const categoryOptions = categories.map((item) => ({ value: item.id, label: item.name }));
    const vendorOptions = venderlist.map((item) => ({ value: item.id, label: item.vendor_name }));
    const handleAddRow = () => {
        setRows([...rows, {
            // date: '',
            // vendor_id: null,
            category_id: null,
            name: '',
            pro_unit: '',
            hsn_no: '',
            pro_qty: '',
            gst: '',
            sale_price: '',
            price: '',
            // invoice: '',
            total_amount: '',
        }]);
    };

    const handleDeleteRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };

    const handleRowChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;

        const { pro_qty, gst, price } = updatedRows[index];

        if (pro_qty && price) {
            const quantity = parseFloat(pro_qty) || 0;
            const unitPrice = parseFloat(price) || 0;
            const gstRate = parseFloat(gst) || 0;
            const totalAmount = quantity * unitPrice * (1 + gstRate / 100);
            updatedRows[index].total_amount = totalAmount.toFixed(2);
        } else {
            updatedRows[index].total_amount = '';
        }

        setRows(updatedRows);
    };

    const validateForm = () => {
        // Add your form validation logic here
        return true; // Return true if the form is valid
    };

    const handleSubmit = async (url, formData, successCallback) => {
        
        setLoading(true);
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            if (response.data.status === 1) {
                Swal.fire({
                    title: 'Success',
                    text: 'create Successfully!',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });       
                    navigate('/stockmangement');
                setRows([{
                    category_id: null,
                    name: '',
                    pro_unit: '',
                    hsn_no: '',
                    pro_qty: '',
                    gst: '',
                    sale_price: '',
                    price: '',
                    total_amount: ''
                }]);
                onSave();
                if (successCallback) successCallback(response.data.payload);
                setTimeout(() => {
                    navigate('/stockmangement');
                }, 1000);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: response.data.message,
                    icon: 'error',
                });
            }
        } catch (error) {
            // toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitOrder = async () => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, submit it!',
                cancelButtonText: 'Cancel'
            });

            if (result.isConfirmed) {
                const requestData = {
                    products: rows.map(row => ({
                        category_id: row.category_id,
                        vendor_id: vendorid,
                        name: row.name,
                        hsn_no: row.hsn_no,
                        pro_unit: row.pro_unit,
                        pro_qty: row.pro_qty,
                        gst: row.gst,
                        sale_price_rate: row.price,
                        sale_price: row.sale_price,
                        invoice: invoice_no,
                        date: date || new Date().toISOString().split("T")[0],
                        product_stock_location_id: productstocklocationid,
                        total_amount: row.total_amount,
                        manufacture: manufacture,
                        type: 'add_stock'
                    }))
                };

                await handleSubmit(
                    `${process.env.REACT_APP_API_URL}Product-stock-store`,
                    requestData,
                    () => {
                        console.log("Order submitted successfully!");
                    }
                );
            } else {
                // Handle cancellation if needed
                console.log("Order submission was cancelled.");
            }
        } catch (error) {
            console.error("Error in submitting the order: ", error);
        }
    };


    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}category-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setCategories(response.data.data || []);
            } catch (error) {
                console.error('Error fetching categories:', error);
                toast.error('Failed to fetch categories.');
            }
        };
        if (!modalState.addCategoryt) {
            fetchCategories();
        }
    }, [token, modalState.addCategoryt]);

    useEffect(() => {
        const fetchCategories1 = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}product-stock-location-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setproductlocation(response.data.data || []);
            } catch (error) {
                console.error('Error fetching categories:', error);
                toast.error('Failed to fetch categories.');
            }
        };
        if (!modalState.addLocation) {
            fetchCategories1();
        }
    }, [token, modalState.addLocation]);

    useEffect(() => {
        const fetchVendor = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Vender-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setVenderlist(response.data.data || []);
            } catch (error) {
                console.error('Error fetching vendors:', error);
                // toast.error('Failed to fetch vendors.');
            }
        };
        if (!modalState.addVendor) {
            fetchVendor();
        }
    }, [token, modalState.addVendor]);

    return (
        <>
            {/* <ToastContainer /> */}

            <Card>

                {/* <div className="card"> */}
                <div className="card-header">

                    <div className="d-flex align-items-center justify-content-between">

                        <h3 className="card-title mb-0">Add Stock Product</h3>
                        <div className="d-flex gap-2">
                            <Button onClick={() => setModalState({ ...modalState, addVendor: true })} className="btn btn-added d-flex gap-2">
                                Add Vendor
                            </Button>
                            <Button onClick={() => setModalState({ ...modalState, addCategoryt: true })} className="btn btn-added d-flex gap-2">
                                Add Category
                            </Button>
                        </div>
                    </div>

                </div>

                {/* </div> */}
                <Card.Body>


                    <Row className="mt-3">
                        <Col md={4} sm={4}>
                            <Form.Group >
                                <div className="d-flex justify-content-between">
                                    <Form.Label >Product Stock Loc.</Form.Label>
                                    <Button
                                        className='btn-info btn-sm mb-3'
                                        onClick={() => setModalState({ ...modalState, addLocation: true })}
                                        variant="primary"
                                    >
                                        Add
                                    </Button>
                                    {/* <Button
                                            className='btn-red btn-sm mb-3'
                                            // onClick={() => setModalState({ ...modalState, category_expenditure_edit: true })}
                                            variant="primary"
                                        >
                                            Edit
                                        </Button> */}
                                </div>
                                <Form.Select
                                    value={productstocklocationid}
                                    onChange={handleLocationChange} >
                                    <option value="">Select</option>
                                    {productlocation.map((vendor) => (
                                        <option key={vendor.id} value={vendor.id}>
                                            {vendor.product_stock_location_name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Vendor Name</Form.Label>
                                <Form.Select
                                    value={vendorid}
                                    onChange={handleVendorChange} >
                                    <option value="">Select</option>
                                    {venderlist.map((vendor) => (
                                        <option key={vendor.id} value={vendor.id}>
                                            {vendor.vendor_name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} lg={2}>
                            <Form.Group className="mb-3">
                                <Form.Label>Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="date"
                                    value={date || new Date().toISOString().split("T")[0]}
                                    onFocus={(e) => e.target.showPicker()}
                                    onChange={handleDateChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} lg={2}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Invoice</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='Invoice'
                                    name="invoice"
                                    value={invoice_no}
                                    onChange={handleInvoiceChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr></hr>
                    <div className=" d-flex w-100 justify-content-end">
                        <button onClick={handleAddRow} className="btn-addmore">
                            Add More
                        </button>
                    </div>
                    {rows.map((row, index) => (
                        <Row key={index} className="mt-3">
                            <Col xs={12} md={4} lg={3}>
                                <Form.Group className="mb-3" controlId={`category_id_${index}`}>
                                    <Form.Label>Product Category</Form.Label>
                                    <Select
                                        value={categoryOptions.find((option) => option.value === row.category_id)}
                                        options={categoryOptions}
                                        isSearchable={true}
                                        placeholder="Select"
                                        onChange={(selected) => handleRowChange(index, 'category_id', selected ? selected.value : null)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={3}>
                                <Form.Group className="mb-3" controlId={`name_${index}`}>
                                    <Form.Label>Product Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Product Name"
                                        value={row.name}
                                        onChange={(e) => handleRowChange(index, 'name', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`pro_unit_${index}`}>
                                    <Form.Label>Unit</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="pro_unit"
                                        placeholder="Unit"
                                        value={row.pro_unit}
                                        onChange={(e) => handleRowChange(index, 'pro_unit', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`hsn_no_${index}`}>
                                    <Form.Label>HSN No</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="hsn_no"
                                        placeholder="HSN No"
                                        value={row.hsn_no}
                                        onChange={(e) => handleRowChange(index, 'hsn_no', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`pro_qty_${index}`}>
                                    <Form.Label>QTY</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="pro_qty"
                                        placeholder="Qty"
                                        value={row.pro_qty}
                                        onChange={(e) => handleRowChange(index, 'pro_qty', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`gst_${index}`}>
                                    <Form.Label>GST</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="gst"
                                        placeholder="GST"
                                        value={row.gst}
                                        onChange={(e) => handleRowChange(index, 'gst', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`sale_price_${index}`}>
                                    <Form.Label>Sale Price</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="sale_price"
                                        placeholder="Enter Price"
                                        value={row.sale_price ? new Intl.NumberFormat().format(row.sale_price) : ''}
                                        onChange={(e) => {
                                            let value = e.target.value.replace(/,/g, '');
                                            if (!isNaN(value) || value === "") {
                                                handleRowChange(index, 'sale_price', value);
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`price_${index}`}>
                                    <Form.Label>Rate</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="price"
                                        placeholder="Enter Price"
                                        value={row.price ? new Intl.NumberFormat().format(row.price) : ''}
                                        onChange={(e) => {
                                            let value = e.target.value.replace(/,/g, '');
                                            if (!isNaN(value) || value === "") {
                                                handleRowChange(index, 'price', value);
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`total_amount_${index}`}>
                                    <Form.Label>Total Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="total_amount"
                                        placeholder="Total Amount"
                                        value={row.total_amount ? new Intl.NumberFormat().format(row.total_amount) : ''}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={1} xs={12} lg={1} className="d-flex align-items-center">
                                <Button
                                    variant="danger"
                                    onClick={() => handleDeleteRow(index)}
                                >
                                    <RiDeleteBin6Line />
                                </Button>
                            </Col>
                        </Row>
                    ))}
                    <div className=" d-flex w-100 justify-content-end">
                        <Button className="btn takeorder" disabled={loading} onClick={handleSubmitOrder} >
                            {loading ? 'Saving...' : 'Create'}
                        </Button>
                    </div>
                </Card.Body>

            </Card>
            <Modal
                size="lg"
                show={modalState.addVendor}
                onHide={() => setModalState({ ...modalState, addVendor: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <AddVendor onSave={() => setModalState({ ...modalState, addVendor: false })} />
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={modalState.addCategoryt}
                onHide={() => setModalState({ ...modalState, addCategoryt: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <AddCategory onSave={() => setModalState({ ...modalState, addCategoryt: false })} />
                </Modal.Body>
            </Modal>
            <Modal
                show={modalState.addLocation}
                onHide={() => setModalState({ ...modalState, addLocation: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">Product Location</h3>
                </Modal.Header>
                <Modal.Body>
                    <Addlocation onSave={() => setModalState({ ...modalState, addLocation: false })} />
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </>
    );
}
