import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdDashboard, MdOutlineKeyboardDoubleArrowLeft } from 'react-icons/md';
import { RiGridFill, RiFolderReceivedFill } from 'react-icons/ri';
import { GiStockpiles } from 'react-icons/gi';
import { FaUser } from 'react-icons/fa';
import { TbReport, TbCategoryPlus } from 'react-icons/tb';
import { SiConsul } from 'react-icons/si';
import { AiFillProduct } from 'react-icons/ai';
import { IoMdMenu } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { TbLogout2 } from "react-icons/tb";
import { FaSun, FaMoon } from 'react-icons/fa'; // Importing icons
import { BiLogOut } from 'react-icons/bi'; 

import logo from '../assets/img/logo.png';

export default function Sidebar() {
  const [activePage, setActivePage] = useState('');
  const [openSubmenu, setOpenSubmenu] = useState('');


  const navigate = useNavigate();

  const [profile, setProfile] = useState(false);
  const Userlogoutprofile = () => {
    setProfile(prevProfile => !prevProfile);
  }
  const logout = (e) => {
    e.preventDefault();
    // localStorage.removeItem(user_id);
    localStorage.clear();
    navigate('/');
  }
  useEffect(() => {
    const user_id = localStorage.getItem("userid");
    const token = localStorage.getItem("token");
    if (user_id == null) {
      navigate('/');
    };
    if (token == null) {
      navigate('/');
    }
  }, [])

  const handleItemClick = (page) => {
    setActivePage(page);
  };

  const handleSubmenuClick = (submenu) => {
    setOpenSubmenu(openSubmenu === submenu ? '' : submenu);
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.classList.remove('sidebar-open');
    } else {
      document.body.classList.add('sidebar-open');
    }

    // Cleanup function to remove the class if the component unmounts
    return () => {
      document.body.classList.remove('sidebar-open');
    };
  }, [isSidebarOpen]);

  const [darkMode, setDarkMode] = React.useState(false);

  React.useEffect(() => {
    const body = document.body;
    const toggle = document.querySelector('.toggle-inner');

    if (darkMode) {
      body.classList.add('dark-mode');
      toggle.classList.add('toggle-active');
    } else {
      body.classList.remove('dark-mode');
      toggle.classList.remove('toggle-active');
    }
  }, [darkMode]);

  return (
    <section id="sidebar" className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
      <div className="slimScrollDiv">
        <div className="header">
          <div className="header-left active">
            <Link to="/home" className="logo logo-normal">
              <div>
                <img src={logo} alt="logo" />
              </div>
            </Link>
            <Link to="/" className="logo logo-white">
              <img src="assets/img/logo-white.png" alt="" />
            </Link>
            <Link to="/" className="logo-small">
              <img src="assets/img/logo-small.png" alt="" />
            </Link>
            <div id="toggle" onClick={() => setDarkMode(prev => !prev)}>
              <div className="toggle-inner">
                {darkMode ? <FaSun size={24} /> : <FaMoon size={24} />}
              </div>
             
            </div>
            {/* <Link
              to="javascript:void(0)"
              className="dropdown-toggle nav-link userset"
              onClick={Userlogoutprofile}>
              <span className="user-info">
                <span className="user-detail">
                  <span className="user-name text-white">Profile</span>
                </span>
              </span>
              {profile && (
                <div className="dropdown-menu menu-drop-user show ">
                  <Link to="javascript:void(0);" className="dropdown-item">
                    My Profile
                  </Link>
                  <Link to="/company_details" className="dropdown-item">
                    Settings
                  </Link>
                  <Link onClick={logout} type='button' className="dropdown-item">
                    Logout
                  </Link>
                </div>
              )}
            </Link> */}

            <Link id="toggle_btn" to="javascript:void(0)" onClick={toggleSidebar}>
              <IoMdMenu />
            </Link>
          </div>
        </div>
        <Link id="mobile_btn" className="mobile_btn" to="#sidebar">
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul className="pl-0">
              <li className={activePage === 'home' ? 'active' : 'li_design'}>
                <Link to="/home" onClick={() => handleItemClick('home')}>
                  <MdDashboard />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className={`submenu position-relative ${openSubmenu === 'products' ? 'open' : 'li_submenu_design'}`}>
                <Link to="javascript:void(0);" className={`subdrop ${openSubmenu === 'products' ? 'active' : ''}`} onClick={() => handleSubmenuClick('products')}>
                  <RiGridFill className="feather feather-grid" />
                  <span>Products<i className="menu-arrow" /></span>
                </Link>
                <ul style={{ display: openSubmenu === 'products' ? "block" : "none" }} className="links_ul">
                  <li className={activePage === 'category' ? 'active dropdwon_li_list' : ''}>
                    <Link to="/category" onClick={() => handleItemClick('category')}>
                      <div className="d-flex">
                        <TbCategoryPlus />
                        <span>Category</span>
                      </div>
                    </Link>
                  </li>
                  <li className={activePage === 'attribute' ? 'active dropdwon_li_list' : ''}>
                    <Link to="/attribute" onClick={() => handleItemClick('attribute')}>
                      <div className="d-flex">
                        <SiConsul />
                        <span>Attribute</span>
                      </div>
                    </Link>
                  </li>
                  <li className={activePage === 'rawmatrial' ? 'active dropdwon_li_list' : ''}>
                    <Link onClick={() => handleItemClick('rawmatrial')} to="/rawmatrial">
                      <div className="d-flex">
                        <GiStockpiles />
                        <span>Raw Matrial</span>
                      </div>
                    </Link>
                  </li>
                  <li className={activePage === 'productmodule' ? 'active dropdwon_li_list' : ''}>
                    <Link to="/productmodule" onClick={() => handleItemClick('productmodule')}>
                      <div className="d-flex">
                        <AiFillProduct />
                        <span>Product</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>



              <li className={activePage === 'takeorder' ? 'active' : 'li_design'}>
                <Link to="/takeorder" onClick={() => handleItemClick('takeorder')}>
                  <TbReport />
                  <span>Take Order</span>
                </Link>
              </li>
              <li className={activePage === 'Orderview' ? 'active' : 'li_design'}>
                <Link to="/orderview" onClick={() => handleItemClick('Orderview')}>
                  <TbReport />
                  <span>Order View</span>
                </Link>
              </li>
              <li className={activePage === 'ReceiveMaterials' ? 'active' : 'li_design'}>
                <Link to="/Receive-Materials" onClick={() => handleItemClick('ReceiveMaterials')}>
                  <RiFolderReceivedFill />
                  <span>Receive Materials</span>
                </Link>
              </li>
              <li className={activePage === 'vendermanagement' ? 'active' : 'li_design'}>
                <Link to="/vendermanagement" onClick={() => handleItemClick('vendermanagement')}>
                  <FaUser />
                  <span>Vendor Management</span>
                </Link>
              </li>
              <li className={activePage === 'Accountmangement' ? 'active' : 'li_design'}>
                <Link to="/Account-list" onClick={() => handleItemClick('Accountmangement')}>
                  <TbReport />
                  <span>Account Management</span>
                </Link>
              </li>
              <li className={activePage === 'employeelist' ? 'active' : 'li_design'}>
                <Link to="/employeelist" onClick={() => handleItemClick('employeelist')}>
                  <FaUser />
                  <span>Employee List</span>
                </Link>
              </li>
              <li className={activePage === 'Expenditurelist' ? 'active' : 'li_design'}>
                <Link to="/Expenditure-list" onClick={() => handleItemClick('Expenditurelist')}>
                  <SiConsul />
                  <span>Expenditure</span>
                </Link>
              </li>

              {/* <li className={`submenu position-relative ${openSubmenu === 'expenditure' ? 'open' : 'li_submenu_design'}`}>
                <Link to="javascript:void(0);" className={`subdrop ${openSubmenu === 'expenditure' ? 'active' : ''}`} onClick={() => handleSubmenuClick('expenditure')}>
                  <RiGridFill className="feather feather-grid" />
                  <span>Expenditure<i className="menu-arrow" /></span>
                </Link>
                <ul style={{ display: openSubmenu === 'expenditure' ? "block" : "none" }} className="links_ul">
                 
                  <li className={activePage === 'expenditureadd' ? 'active dropdwon_li_list' : ''}>
                    <Link to="/Add-Expenditure" onClick={() => handleItemClick('expenditureadd')}>
                      <div className="d-flex">
                        <SiConsul />
                        <span>Expenditure Add</span>
                      </div>
                    </Link>
                  </li>
                  <li className={activePage === 'expenditurelist' ? 'active dropdwon_li_list' : ''}>
                    <Link to="/Expenditure-list" onClick={() => handleItemClick('expenditurelist')}>
                      <div className="d-flex">
                        <SiConsul />
                        <span>Expenditure List</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li> */}
              {/* <li className={activePage === 'Vendorprofile' ? 'active' : 'li_design'}>
                <Link to="/Vendorprofile" onClick={() => handleItemClick('Vendorprofile')}>
                  <FaUser />
                  <span>Vendor Profile</span>
                </Link>
              </li> */}
              {/* <li className={activePage === 'employeereport' ? 'active' : 'li_design'}>
                <Link to="/employeereport" onClick={() => handleItemClick('employeereport')}>
                  <FaUser />
                  <span>Employee Report</span>
                </Link>
              </li> */}
              <li className={activePage === 'Stockmangement' ? 'active' : 'li_design'}>
                <Link to="/stockmangement" onClick={() => handleItemClick('Stockmangement')}>
                  <TbReport />
                  <span>Stock Management</span>
                </Link>
              </li>
              {/* <li className={activePage === 'Stockmangement1' ? 'active' : 'li_design'}>
                <Link to="/Extra-charges-list" onClick={() => handleItemClick('Stockmangement1')}>
                  <TbReport />
                  <span>Extra Charges</span>
                </Link>
              </li> */}


              {/* <li className={activePage === 'Createaccount' ? 'active' : 'li_design'}>
                <Link to="/createaccount" onClick={() => handleItemClick('Createaccount')}>
                  <TbReport />
                  <span>Create account</span>
                </Link>
              </li> */}
              {/* <li className={activePage === 'Leadmangement' ? 'active' : 'li_design'}>
                <Link to="/leadmangement" onClick={() => handleItemClick('Leadmangement')}>
                  <TbReport />
                  <span>Lead Management</span>
                </Link>
              </li>
              <li className={activePage === 'Leadfollowlist' ? 'active' : 'li_design'}>
                <Link to="/leadfollowlist" onClick={() => handleItemClick('Leadfollowlist')}>
                  <TbReport />
                  <span>Lead Follow List</span>
                </Link>
              </li> */}
              <li className={activePage === 'Leadfollowlist1' ? 'active' : 'li_design'}>
                <Link to="/company_details" onClick={() => handleItemClick('Leadfollowlist1')}>
                  <TbReport />
                  <span>Company Detail</span>
                </Link>
              </li>
              <li className={activePage === 'Generalsetting' ? 'active' : 'li_design'}>
                <Link to="/forgetpassword" onClick={() => handleItemClick('Generalsetting')}>
                  <TbReport />
                  <span>General Setting</span>
                </Link>
              </li>

              <li className="">
                <Link onClick={logout} type='button'>
                  <TbLogout2 />
                  <span>Logout</span>
                </Link>
              </li> 
           
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
