import React, { useState, useEffect } from 'react';
import { FaSearch, FaPlus, FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Button, Form, Modal } from 'react-bootstrap';
import $ from 'jquery';
import 'select2';
import { Link } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Swal from 'sweetalert2';
import { FaEye } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';


export default function Attribute() {
    const [showEditModal, setShowEditModal] = useState(false);
    const [data, setData] = useState([]);
    const [loadinglist, setLoadinglist] = useState(false);
    const [viewdata, setViewdata] = useState([]);
    const [editnameError, setEditNameError] = useState(false);
    const [currentEditId, setCurrentEditId] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    const handleCloseEditModal = () => setShowEditModal(false);
    const handleShowEditModal = () => setShowEditModal(true);
    // const onInputChangeEditName = (e) => {
    //     setEditname(e.target.value);
    //     setEditNameError(false);
    // };

    useEffect(() => {
        productlist();
    }, []);

    const productlist = async () => {
        setLoadinglist(true);
        try {
            let url = `${process.env.REACT_APP_API_URL}product-list`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);

            // Filter the response data where type is 'add_product'
            const filteredData = response.data.data.filter(item => item.type === 'add_product');
            console.warn(response.data.data);
            // Set the filtered data
            setData(filteredData);

            // Calculate total pages based on the filtered data
            setTotalPages(Math.ceil(filteredData.length / itemsPerPage));
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setLoadinglist(false);
        }
    };





    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        const selectedItemsPerPage = parseInt(e.target.value);
        setItemsPerPage(selectedItemsPerPage);

        // Recalculate total pages based on the new items per page value
        setTotalPages(Math.ceil(filteredData.length / selectedItemsPerPage));
    };

    const filteredData = data ? data.filter(item =>
        (item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.category_name && item.category_name.toLowerCase().includes(searchQuery.toLowerCase()))
    ) : [];



    const paginatedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const renderPagination = () => {
        const pageNumbers = [];
        const totalPages = Math.ceil(filteredData.length / itemsPerPage);
        const maxPageButtons = 10;
        const ellipsis = '...';

        let startPage, endPage;

        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= Math.floor(maxPageButtons / 2)) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + Math.floor(maxPageButtons / 2) >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - Math.floor(maxPageButtons / 2);
                endPage = currentPage + Math.floor(maxPageButtons / 2);
            }
        }

        // Adjust startPage and endPage if totalPages < maxPageButtons
        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= Math.floor(maxPageButtons / 2)) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + Math.floor(maxPageButtons / 2) >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - Math.floor(maxPageButtons / 2);
                endPage = currentPage + Math.floor(maxPageButtons / 2);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <>
                        <button onClick={() => handlePageChange(1)}>{1}</button>
                        {startPage > 2 && <span>{ellipsis}</span>}
                    </>
                )}
                {pageNumbers.map(number => (
                    <button key={number} onClick={() => handlePageChange(number)} className={number === currentPage ? 'active' : ''}>
                        {number}
                    </button>
                ))}
                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && <span>{ellipsis}</span>}
                        <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                    </>
                )}
            </div>
        );
    };


    return (
        <div className="Category">
           
            <div className="card table-list-card">
           
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table-striped bordered hover table">
                            <thead>
                                <tr className="thead-dark">
                                    <th>Sr Number</th>
                                    <th>Product</th>
                                    <th>Category Name</th>
                                    <th>Qty</th>
                                    <th>Price</th>
                                </tr>
                            </thead>

                            <tbody>
                                {loadinglist ? (
                                    <tr>
                                        <td colSpan="4" className='text-center'>
                                            <div>Loading...</div>
                                        </td>
                                    </tr>
                                ) : (
                                    paginatedData && paginatedData.length > 0 ? (
                                        paginatedData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>{item.category_name}</td>
                                                <td>{item.pro_qty}</td>
                                                <td>{item.total_amount}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4" className='text-center'>
                                            
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>


                        </table>
                    </div>
                </div>
            </div>

         
            <ToastContainer />
        </div>
    );
}

