import React, { useState,useEffect  } from 'react';
import { FaSearch } from "react-icons/fa";
import {Button,Row,Col,Form,Modal} from 'react-bootstrap';
import $ from 'jquery'; // Make sure jQuery is installed
import 'select2'; // Import Select2 CSS
import { FaPlus } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import {Link} from 'react-router-dom';

export default function Unit() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  

    const [adding, setAdding] = useState(false);
    const handlecloseadding = () => setAdding(false);
    const handleshowadding = () => setAdding(true);


    const [tableData, setTableData] = useState([
        {
            id: 1,
            srNumber: 101,
            CategoryName:"CategoryName",
            editAction:<FaEdit/>,
            addaction:  <FaPlus/>,
            deleteAction: <MdDelete/>,
            Rowmatrial: "Rowmatrial",
        },
        {
            id: 2,
            srNumber: 102,
            CategoryName:"CategoryName",
            editAction:<FaEdit/>,
            addaction:  <FaPlus/>,
            deleteAction: <MdDelete/>,
            Rowmatrial: "Rowmatrial",

        },

    ]);

    // Handle checkbox selection
    const handleCheckboxChange = (id) => {
        setTableData(prevData =>
            prevData.map(item =>
                item.id === id ? { ...item, selected: !item.selected } : item
            )
        );
    };
    useEffect(() => {
        $('#mySelect2').select2();
        return () => {
            $('#mySelect2').select2('destroy');
        };
    }, []);
    return (
        <div className="VenderReport">
            <div class="page-header">
                <div class="add-item d-flex justify-content-between">
                    <div class="page-title">
                        <h4>Unit Mangement</h4>
                        <h6>Manage Your Unit Mangement</h6>
                    </div>
                </div>
                    <div className="button_add_row">
                    <Button  className="btn btn-added d-flex gap-2"   onClick={handleshowadding}>
                     <FaPlus/>  Add Category 

                    </Button>
                    </div>

            </div>

            <div class="card table-list-card">
                <div className="card-header">
                    <div className="searchinputs d-flex">
                        <input type="text" placeholder="Search" />
                        <div className="btn d-flex align-items-center justify-content-center">
                            <FaSearch />
                        </div>
                    </div>

                </div>
                <div class="card-body">

                    <div className="table-responsive">
                        <table className="table-striped bordered hover table">
                            <thead>
                                <tr className="thead-dark">

                                    <th>Sr Number</th>
                                    <th>Category</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((item) => (
                                    <tr key={item.id}>

                                        <td>{item.srNumber}</td>
                                        <td>{item.CategoryName}</td>
                                        <td className="edit-delete-action justify-content-start d-flex gap-2">
                                            <Link to="javascript:void(0)" style={{"--i": "#ff9f43"}} className="p-2" onClick={handleShow}>{item.editAction}</Link>
                                            <Link to="javascript:void(0)" style={{"--i": "red"}} className="p-2">{item.deleteAction}</Link>
                                        </td>
                                        
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} backdrop="static" 
          keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title><h4>Edit Unit </h4></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Enter Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Matrials"
                
              />
            </Form.Group>
           
          </Form>
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" className="btn btn-submit" onClick={handleClose}>
            Create 
          </Button>
        </Modal.Footer>
              </Modal>

      {/* adding  */}
            <Modal show={adding} onHide={handlecloseadding}
            backdrop="static" 
            keyboard={false}>
            <Modal.Header closeButton>
          <Modal.Title>
            <h4> Unit Add</h4></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="custum-label">Enter Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Matrials"
                
              />
            </Form.Group>
           
          </Form>
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" className="btn btn-submit" onClick={handleClose}>
            Create 
          </Button>
        </Modal.Footer>
             </Modal>


           
        </div>


    );
}