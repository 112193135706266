import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, Row, Col, Form, Card } from 'react-bootstrap';
import { MdAddCircle, MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DataTable from './MaterialReceiveTable';
import GodavenProductAdd from './Godaven';
import RecriveView from './MaterialRecriveView';
import AddmaterialUpdate from './AddmaterialUpdate';
import { FaEye, FaEdit } from "react-icons/fa";
import Swal from 'sweetalert2';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Product from '../../assets/img/product.png'; // Assuming this is the product image path

const StockManagement = () => {
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [data, setVendorList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [custumerlist, setCustumerlist] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  const [selectedCustomers, setSelectedCustomers] = useState({});
  const [selectedDate, setSelectedDate] = useState({});
  const [id, setId] = useState('');
  const [modalState, setModalState] = useState({
    recriveAdd: false,
    recriveView: false,
    recriveUpdate: false,
  });

  // Fetch vendor data from API
  const fetchVendor = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}material-store-godaven-list`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      const receivedData = response.data.data || [];
      setVendorList(receivedData);
      setFilteredData(receivedData);
      calculateTotals(receivedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to fetch data.');
    }
  };

  useEffect(() => {
    if (!modalState.recriveAdd && !modalState.recriveUpdate) {
      fetchVendor();
    }
  }, [modalState.recriveAdd, modalState.recriveUpdate]);

  // Calculate total quantity and amount
  const calculateTotals = (data) => {
    const totalQuantity = data.reduce((sum, item) => sum + (parseFloat(item.met_qty) || 0), 0);
    const totalAmount = data.reduce((sum, item) => {
      const metCost = parseFloat(item.met_cost) || 0;
      const gst = parseFloat(item.gst) || 0;
      return sum + (metCost + (metCost * gst / 100));
    }, 0);
    setTotalQuantity(totalQuantity);
    setTotalAmount(totalAmount);
  };

  // Fetch customer list
  useEffect(() => {
    fetchCustomer();
  },[token]);
  const fetchCustomer = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}Add-material-custumer-name-list`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      setCustumerlist(response.data.data || []);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  


  const handleCustomer = async (selectedCustomerId, id) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}material-store-godaven-name-update`;
      const requestData = { customer_id: selectedCustomerId, id };

      const response = await axios.post(url, requestData, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });

      if (response.data.status === 1) {
        toast.success(response.data.message);
        fetchCustomer();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };
  const handleDate = async (selectedDate, id) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}material-store-godaven-date-update`;
      const requestData = { customer_date: selectedDate, id };

      const response = await axios.post(url, requestData, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });

      if (response.data.status === 1) {
        toast.success(response.data.message);
        fetchCustomer();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleDelete = async (orderId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        const url = `${process.env.REACT_APP_API_URL}material-delete/${orderId}`;
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        });
        if (response.data.status === 1) {
          toast.success(response.data.message);
          fetchVendor();
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // Search functionality
  useEffect(() => {
    if (searchInput.trim() === '') {
      setFilteredData(data);
      calculateTotals(data);
    } else {
      const searchTerm = searchInput.toLowerCase();
      const filtered = data.filter((item) => {
        const metNameMatches = item.met_name?.toLowerCase().includes(searchTerm);
        const locationMatches = item.company_name?.toLowerCase().includes(searchTerm);
        return metNameMatches || locationMatches;
      });
      setFilteredData(filtered);
      calculateTotals(filtered);
    }
  }, [searchInput, data]);

  const handleView = (orderId) => {
    localStorage.setItem('met_receive', orderId);
    setModalState({ ...modalState, recriveView: true });
  };

  const handleUpdate = (orderId) => {
    localStorage.setItem('met_receive_update', orderId);
    setModalState({ ...modalState, recriveUpdate: true });
  };

  const columns = useMemo(() => [
    {
      Header: 'Sr.No',
      accessor: 'Srno',
      Cell: (row) => row.row.index + 1,
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ value }) => value || 'N/A',
    },
    {
      Header: 'Vendor Name',
      accessor: 'vendor_name',
      Cell: ({ value }) => value || 'N/A',
    },
    {
      Header: 'Location',
      accessor: 'company_name',
      Cell: ({ value }) => value || 'N/A',
    },
    {
      Header: 'Gst (%)',
      accessor: 'gst',
    },
    {
      Header: 'Total Cost',
      accessor: 'total_cost',
    },
    {
      Header: 'Select Customer',
      accessor: 'customer_name',
      Cell: ({ row }) => {
        const rowId = row.original.id;
        const currentCustomerId = selectedCustomers[rowId] || row.original.customer_id; // Pre-select current customer

        return (
          <select
            value={currentCustomerId}  // Bind the select value to the customer id
            onChange={(e) => {
              const selectedCustomerId = e.target.value;
              setSelectedCustomers((prev) => ({
                ...prev,
                [rowId]: selectedCustomerId, // Update selected customer in state
              }));
              handleCustomer(selectedCustomerId, rowId); // Call API to update the customer
            }}
          >
            <option value="">Select a customer</option>
            {custumerlist.map((customer) => (
              <option key={customer.id} value={customer.id}>
                {customer.customer_name}
              </option>
            ))}
          </select>
        );
      },
    },
    {
      Header: 'Select Date',
      accessor: 'customer_date',
      Cell: ({ row }) => {
        const rowId = row.original.id;
        const currentCustomerDate = selectedDate[rowId] || row.original.customer_date; // Retrieve the date to display
    
        // Format the date to YYYY-MM-DD if it exists
        const formattedDate = currentCustomerDate ? currentCustomerDate.split('T')[0] : '';
    
        return (
          <input
            type="date"
            value={formattedDate} // Set the input value to the formatted date
            onChange={(e) => {
              const selectedCustomerDate = e.target.value; // Get the selected date from input
              setSelectedDate((prev) => ({
                ...prev,
                [rowId]: selectedCustomerDate, // Update the state with the new date
              }));
              handleDate(selectedCustomerDate, rowId); // Call the handler with the new date
            }}
          />
        );
      },
    },
    
    

    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div>
          <Button variant="secondary" className="ms-2">
            <FaEye onClick={() => handleView(row.original.id)} />
          </Button>
          <Button
            style={{ backgroundColor: "#233882", borderColor: "#233882" }}
            className="ms-2"
            onClick={() => handleUpdate(row.original.id)}
          >
            <FaEdit />
          </Button>
          <Button
            style={{ backgroundColor: "#d9534f", borderColor: "#d9534f" }}
            className="ms-2"
            onClick={() => handleDelete(row.original.id)}
          >
            <MdDelete />
          </Button>
        </div>
      ),
    },
  ], [custumerlist, selectedCustomerId]);

  return (
    <>
      <div>
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Receive Materials Godaven</h4>
              <h6>Manage your stock Godaven</h6>
            </div>
          </div>

          <div className="page-btn d-flex gap-2">
            <Button onClick={() => setModalState({ ...modalState, recriveAdd: true })} className="btn btn-secondary d-flex gap-2">
              <img src={Product} alt="" width="25px" className="me-2" />  Add Receive
            </Button>
            <Button as={Link} to="/Receive-Materials" className="btn btn-secondary d-flex gap-2">
              Back
            </Button>
          </div>
        </div>

        <Card>
          <Card.Body>
            <Row>
              <Col md={6} sm={12}>
                <div className="search">
                  <Form.Control
                    type="text"
                    placeholder="Search by vendor, location..."
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6} sm={12} className="text-end">
                <div className="text-right">
                  <span className="me-5 fw-bold">Total Quantity: {totalQuantity}</span>
                  <span className="fw-bold">Total Amount: {totalAmount.toFixed(2)}</span>
                </div>
              </Col>
            </Row>
            <DataTable columns={columns} data={filteredData} />
          </Card.Body>
        </Card>
      </div>

      {/* Add Receive Modal */}
      <Modal size="xl" show={modalState.recriveAdd} onHide={() => setModalState({ ...modalState, recriveAdd: false })} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add Material Receive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GodavenProductAdd closeModal={() => setModalState({ ...modalState, recriveAdd: false })} />
        </Modal.Body>
      </Modal>

      {/* View Receive Modal */}
      <Modal size="xl" show={modalState.recriveView} onHide={() => setModalState({ ...modalState, recriveView: false })} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>View Material Receive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RecriveView closeModal={() => setModalState({ ...modalState, recriveView: false })} />
        </Modal.Body>
      </Modal>

      {/* Update Receive Modal */}
      <Modal size="xl" show={modalState.recriveUpdate} onHide={() => setModalState({ ...modalState, recriveUpdate: false })} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Update Material Receive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddmaterialUpdate closeModal={() => setModalState({ ...modalState, recriveUpdate: false })} />
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default StockManagement;
