import React,{useState} from 'react'
import { Row, Col, Container, Form, Modal, Card } from 'react-bootstrap'
import { PiMoneyWavyLight } from "react-icons/pi";
import bgimage from '../assets/img/image.png'
import { GiMoneyStack } from "react-icons/gi";
import { GoListOrdered } from "react-icons/go";
import { RiAlignItemLeftFill } from "react-icons/ri";
import { TbTruckDelivery } from "react-icons/tb";
import { CiDeliveryTruck } from "react-icons/ci";
import { MdOutlineDeliveryDining } from "react-icons/md";
import { RiFileList3Fill } from "react-icons/ri";
import Select from 'react-select';
import { FaEdit,FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { RiUser2Fill } from "react-icons/ri";

export default function Vendorprofile() {

  const options = [
    {value:"Rohit",label:"Rohit"},
    {value:"Mohit",label:"Mohit"},
    {value:"Sohit",label:"Sohit"},
    {value:"Eohit",label:"Eohit"},
  ];
  const initialTableData = [
    { id: 1, date: '2024-06-10',Custumername:'123456',Order:'123456',orderstatus:'123456',paystatus:'123456',action:<div className='edit-delete-action d-flex gap-2'>
      <div className="me-2 p-2  editbutton">
    <FaEdit />
     </div>
     <div className="me-2 p-2 deletebtn">
    <MdDelete />
     </div>
     <div className="me-2 p-2 viewbtn">
    <FaEye />
     </div>
   
  </div>},
     { id: 1, date: '2024-06-10',Custumername:'123456',Order:'123456',orderstatus:'123456',paystatus:'123456',action:<div className='edit-delete-action d-flex gap-2'>
      <div className="me-2 p-2  editbutton">
    <FaEdit />
     </div>
     <div className="me-2 p-2 deletebtn">
    <MdDelete />
     </div>
     <div className="me-2 p-2 viewbtn">
    <FaEye />
     </div>
   
  </div>}, { id: 1, date: '2024-06-10',Custumername:'123456',Order:'123456',orderstatus:'123456',paystatus:'123456',action:<div className='edit-delete-action d-flex gap-2'>
      <div className="me-2 p-2  editbutton">
    <FaEdit />
     </div>
     <div className="me-2 p-2 deletebtn">
    <MdDelete />
     </div>
     <div className="me-2 p-2 viewbtn">
    <FaEye />
     </div>
   
  </div>}, { id: 1, date: '2024-06-10',Custumername:'123456',Order:'123456',orderstatus:'123456',paystatus:'123456',action:<div className='edit-delete-action d-flex gap-2'>
      <div className="me-2 p-2  editbutton">
    <FaEdit />
     </div>
     <div className="me-2 p-2 deletebtn">
    <MdDelete />
     </div>
     <div className="me-2 p-2 viewbtn">
    <FaEye />
     </div>
   
  </div>}, { id: 1, date: '2024-06-10',Custumername:'123456',Order:'123456',orderstatus:'123456',paystatus:'123456',action:<div className='edit-delete-action d-flex gap-2'>
      <div className="me-2 p-2  editbutton">
    <FaEdit />
     </div>
     <div className="me-2 p-2 deletebtn">
    <MdDelete />
     </div>
     <div className="me-2 p-2 viewbtn">
    <FaEye />
     </div>
   
  </div>}, { id: 1, date: '2024-06-10',Custumername:'123456',Order:'123456',orderstatus:'123456',paystatus:'123456',action:<div className='edit-delete-action d-flex gap-2'>
      <div className="me-2 p-2  editbutton">
    <FaEdit />
     </div>
     <div className="me-2 p-2 deletebtn">
    <MdDelete />
     </div>
     <div className="me-2 p-2 viewbtn">
    <FaEye />
     </div>
   
  </div>},
  ];

  const [tableData, setTableData] = useState(initialTableData);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const filterByDate = () => {
    if (startDate && endDate) {
      const filteredData = initialTableData.filter(item => {
        return item.date >= startDate && item.date <= endDate;
      });
      setTableData(filteredData);
    } else {
      setTableData(initialTableData);
    }
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };
  return (
    <div className="vendorprofile">
      <div className="page-header">
        <div className="add-item d-flex justify-content-between">
          <div className="page-title">
            <h4>Vendor Profile Details</h4>
            <h6>Profile</h6>
          </div>
        </div>
        <div className="button_add_row">

        </div>
      </div>
      <Card>
        <Card.Header>
          <Card.Title className="mb-0">Vendor Profile</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Vendor Name</Form.Label>
                  <Form.Control type="text" Placeholder="Vendor Name" />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Vendor Owner</Form.Label>
                  <Form.Control type="text" Placeholder="Vendor Owner" />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Joined Date</Form.Label>
                  <Form.Control type="date" Placeholder="Joined Date" />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Row>
        <Col md={3} className="mb-3">
          <div className="dash-count bgimagenew">
            <div className="dash-counts">
              <h4>100</h4>
              <h5>Total Amount</h5>
            </div>
            <div className="dash-imgs">
              <PiMoneyWavyLight />
            </div>
          </div>
        </Col>
        <Col md={3} className="mb-3">
          <div className="dash-count bgimagenew">
            <div className="dash-counts">
              <h4>100</h4>
              <h5>Due Amount</h5>
            </div>
            <div className="dash-imgs">
              <GiMoneyStack />
            </div>
          </div>
        </Col>
        <Col md={3} className="mb-3">
          <div className="dash-count bgimagenew">
            <div className="dash-counts">
              <h4>100</h4>
              <h5>Total Order</h5>
            </div>
            <div className="dash-imgs">
              <GoListOrdered />
            </div>
          </div>
        </Col>
        <Col md={3} className="mb-3">
          <div className="dash-count bgimagenew">
            <div className="dash-counts">
              <h4>100</h4>
              <h5>Total Items</h5>
            </div>
            <div className="dash-imgs">
              <RiAlignItemLeftFill />
            </div>
          </div>
        </Col>
        <Col md={3} className="mb-3">
          <div className="dash-count bgimagenew">
            <div className="dash-counts">
              <h4>100</h4>
              <h5>Runings Order</h5>
            </div>
            <div className="dash-imgs">
              <CiDeliveryTruck />
            </div>
          </div>
        </Col>
        <Col md={3} className="mb-3">
          <div className="dash-count bgimagenew">
            <div className="dash-counts">
              <h4>100</h4>
              <h5>Delivering Items</h5>
            </div>
            <div className="dash-imgs">
              <MdOutlineDeliveryDining />
            </div>
          </div>
        </Col>
        <Col md={3} className="mb-3">
          <div className="dash-count bgimagenew">
            <div className="dash-counts">
              <h4>100</h4>
              <h5>Delivered Items</h5>
            </div>
            <div className="dash-imgs">
              <TbTruckDelivery />
            </div>
          </div>
        </Col>
        <Col md={3} className="mb-3">
          <div className="dash-count bgimagenew">
            <div className="dash-counts">
              <h4>100</h4>
              <h5>Late Items</h5>
            </div>
            <div className="dash-imgs">
              <RiFileList3Fill />
            </div>
          </div>
        </Col>
      </Row>
      <Card>
        <Card.Header>
        <Form>
            <Row>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>From Date</Form.Label>
                  <Form.Control type="Date" value={startDate} onChange={handleStartDateChange} Placeholder="From Date" />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>To Date</Form.Label>
                  <Form.Control type="date" value={endDate} onChange={handleEndDateChange} Placeholder="To Date" />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Order Type</Form.Label>
                  <Select
                    options={options}
                  />

                 
                </Form.Group>
              </Col>
              <Col md={3} className='d-flex align-items-end'>
                 <button className="btn-added" type='button' onClick={filterByDate}>
                    Done
                 </button>
              </Col>
            </Row>
          </Form>
        </Card.Header>
        <Card.Body>
        <table className='table'>
        <thead>
          <tr className='thead-dark'>
            <th>Sr No</th>
            <th>Order Id</th>
            <th>Custumer Name</th>
            <th>Order Status</th>
            <th>Payment Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map(item => (
            <tr key={item.id}>
              <td>{item.date}</td>
              <td>{item.Custumername}</td>
              <td>{item.Order}</td>
              <td>{item.orderstatus}</td>
              <td>{item.paystatus}</td>
              <td>{item.action}</td>
            </tr>
          ))}
        </tbody>
      </table>
        </Card.Body>
      </Card>
    </div>
  )
}
