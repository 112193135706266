import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, Row, Col, Container, Card } from 'react-bootstrap';
import DataTable from './Stocktable';
import axios from 'axios';
import { format } from 'date-fns';



const Expenditure_payment_Ladger_Details = () => {
    const token = localStorage.getItem('token');
    const account_ladger_id = localStorage.getItem('Le_orderid');
    const [loading, setLoading] = useState(false);
    const [data, setVenderlist] = useState([]);
    const [customerDuelist, setcustomerDuelist] = useState('');

    useEffect(() => {
        fetchVendor();
    }, [token]);


    const fetchVendor = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Site-ledger-expenditure-list/${account_ladger_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            // Set the data from OtherData array if available
            const otherData = response.data.data || [];
            setVenderlist(otherData);
        } catch (error) {
            console.error('Error fetching vendors:', error);
        }
    };

    // Define columns
    const columns = useMemo(
        () => [
            {
                Header: 'Sr No',
                accessor: (row, i) => i + 1,
            },
            {
                Header: 'Date',
                accessor: 'created_at',
                Cell: ({ value }) => {
                    return format(new Date(value), 'dd-MM-yyyy');
                },
            },
            {
                Header: 'Customer Name',
                accessor: 'customer_name',
            },
          
            {
                Header: 'Amount',
                accessor: 'amount',
            },
            // {
            //     Header: 'Due Amount',
            //     accessor: 'due_amt',
            //     Cell: ({ value }) => (
            //         value !== undefined ? (
            //           new Intl.NumberFormat().format(value)
            //         ) : null
            //       ),
            // },
            {
                Header: 'Account',
                accessor: 'account_name',
            },
            {
                Header: 'Payment Mode',
                accessor: 'account_mode',
            },
            


        ],
        []
    );
    return (
        <>

            <div>
                <DataTable columns={columns} data={data} />
                {/* Example modal component */}
                <Modal id="add-units" show={false} onHide={() => { }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Your modal content here */}
                        {/* This is just a placeholder, replace it with your actual modal content */}
                        <p>Modal Content</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { }}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => { }}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>

    );
};

export default Expenditure_payment_Ladger_Details;
