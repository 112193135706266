import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'
export default function Generate() {
    const Print = () =>{     
        //console.log('print');  
        let printContents = document.getElementById('tableprint').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
       document.body.innerHTML = originalContents; 
      }
    return (
        <div>
            <div className="">
                <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="invoice-container">
                                    <div className="invoice-header">
                                        {/* Row start */}
                                        <div className="row gutters">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div className="custom-actions-btns mb-5">
                                                    
                                                    <Link to="/" onClick={Print} className="btn btn-secondary">
                                                        <i className="icon-printer" /> Print
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Row end */}
                                        {/* Row start */}
                                        <div className="invoice-body" id="tableprint">

                                            <div className="custom-table table-responsive">

                                                <Table Stripped Hover Bodered >
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Link to="void:javascript(0)" className="invoice-logo">
                                                                    Endless.com
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex justify-content-end">
                                                                    <address className="text-right">
                                                                        Maxwell admin Inc, 45 NorthWest Street.
                                                                        <br />
                                                                        Sunrise Blvd, San Francisco.
                                                                        <br />
                                                                        00000 00000
                                                                    </address>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="invoice-details">
                                                                    <address>
                                                                        Alex Maxwell
                                                                        <br />
                                                                        150-600 Church Street, Florida, USA
                                                                    </address>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="invoice-details">
                                                                    <div className="invoice-num">
                                                                        <div>Invoice - #009</div>
                                                                        <div>January 10th 2020</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </Table>
                                                <Table >
                                                    <thead>
                                                        <tr className="thead-dark">
                                                            <th>Items</th>
                                                            <th>Product ID</th>
                                                            <th>Quantity</th>
                                                            <th>Sub Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                Sofa Set
                                                                <p className="m-0 text-muted">
                                                                    Reference site about Lorem Ipsum, giving
                                                                    information on its origins.
                                                                </p>
                                                            </td>
                                                            <td>#50000981</td>
                                                            <td>9</td>
                                                            <td>$5000.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Table
                                                                <p className="m-0 text-muted">
                                                                    As well as a random Lipsum generator.
                                                                </p>
                                                            </td>
                                                            <td>#50000126</td>
                                                            <td>5</td>
                                                            <td>$100.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Grocery
                                                                <p className="m-0 text-muted">
                                                                    Lorem ipsum has become the industry standard.
                                                                </p>
                                                            </td>
                                                            <td>#50000821</td>
                                                            <td>6</td>
                                                            <td>$49.99</td>
                                                        </tr>
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td colSpan={2}>
                                                                <p>
                                                                    Subtotal
                                                                    <br />
                                                                    Shipping &amp; Handling
                                                                    <br />
                                                                    Tax
                                                                    <br />
                                                                </p>
                                                                <h5 className="text-success">
                                                                    <strong>Grand Total</strong>
                                                                </h5>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    $5000.00
                                                                    <br />
                                                                    $100.00
                                                                    <br />
                                                                    $49.00
                                                                    <br />
                                                                </p>
                                                                <h5 className="text-success">
                                                                    <strong>$5150.99</strong>
                                                                </h5>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                     <tr>
                                                        <td colSpan={4}> <div className="invoice-footer text-center">Thank you for your Business.</div>
                                                        </td>
                                                     </tr>

                                                    </tfoot>

                                                </Table>

                                            </div>

                                        </div>
                                        {/* Row start */}

                                    </div>
                                    {/* Row end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>



    )
}
