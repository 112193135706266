import React, { useMemo, useState } from 'react';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { Card,Modal,Form,Button,Row,Col,Container } from 'react-bootstrap';
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import {Link} from 'react-router-dom'

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <input
      value={filter || ''}
      onChange={(e) => setFilter(e.target.value)}
      placeholder="Search..."
      className="form-control"
    />
  );
};

const Vendorreporttable = ({ title,columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      title,
      initialState: { pageIndex: 0, pageSize: 10 }, 
    },
    useGlobalFilter,
    usePagination
  );
  const [addItems, setAdditems] = useState(false);
  const addItemstrue = () => setAdditems(true);
  const AdditemsClose = () => setAdditems(false);
  

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <React.Fragment>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5 className="mb-0">{title}</h5>
          <div className="d-flex gap-3">
         
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="form-select d-inline"
            style={{width:"120px"}}

          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <div style={{ width: '220px' }}>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </div>
          </div>
        </div>
        <div className="table-responsive">
          <table {...getTableProps()} className="table datanew">
            <thead style={{ backgroundColor: '#f2f2f2' }}>
              {headerGroups.map((headerGroup) => (
                <tr className="thead-dark" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
       <div className="d-flex justify-content-between align-items-center">
       <div>
            Page
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </div>
        <div className="pagination justify-content-center mt-3">
          <div onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <MdKeyboardDoubleArrowLeft/>
          </div>
          <div onClick={() => previousPage()} disabled={!canPreviousPage}>
           <MdKeyboardArrowLeft/>
          </div>
           <div>
             <h6 className="mb-0 pagination_count d-flex justify-content-center align-items-center">{pageIndex + 1} </h6>
           </div>
          <div onClick={() => nextPage()} disabled={!canNextPage}>
            <MdKeyboardArrowRight/>
          </div>
          <div onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <MdKeyboardDoubleArrowRight/>

          </div>
        </div>
       </div>
       
      {/* add vender add  */}
      <Modal size="lg" show={addItems} onHide={AdditemsClose} data-backdrop="static" data-keyboard="false">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4> Add Raw Matrial</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Container fluid>
            <Row>
                <Col lg={4} sm={6} xs={12}>
                    <Form.Group controlId="rawMaterial">
                        <Form.Label>Raw Material</Form.Label>
                        <Form.Control type="text" placeholder="Enter raw material" />
                    </Form.Group>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                    <Form.Group controlId="vendor">
                        <Form.Label>Vendor</Form.Label>
                        <Form.Control type="text" placeholder="Enter vendor name" />
                    </Form.Group>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                    <Form.Group controlId="qty">
                        <Form.Label>QTY</Form.Label>
                        <Form.Control type="text" placeholder="Enter quantity" />
                    </Form.Group>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                    <Form.Group controlId="unit">
                        <Form.Label>Unit</Form.Label>
                        <Form.Control type="text" placeholder="Enter unit" />
                    </Form.Group>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                    <Form.Group controlId="salePrice">
                        <Form.Label>Sale Price</Form.Label>
                        <Form.Control type="text" placeholder="Enter sale price" />
                    </Form.Group>
                </Col>
            </Row>
                       
                    </Container>
        </Form>
                </Modal.Body>
                <Modal.Footer  className="d-flex justify-content-end">
                  <div className="">
                  <Button variant="primary" className="btn btn-submit" onClick={AdditemsClose}>
                        Create
                    </Button>
                  </div>
                </Modal.Footer>
            </Modal>

            {/* add items model  */}
    </React.Fragment>
  
  );
};

export default Vendorreporttable;
