import React, { useMemo, useState, useEffect } from 'react';
import Vendorproductt from './Vendorproduct';
import { Card, Modal, Form, Button, Row, Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { FiPlusCircle } from 'react-icons/fi';
import { HiOutlineMinusCircle } from 'react-icons/hi';
import { toast, ToastContainer } from 'react-toastify';
import { FaEye } from "react-icons/fa";
import { FaSearch, FaPlus, FaEdit } from "react-icons/fa";
// import { MdDelete } from "react-icons/md";

import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';



import axios from 'axios';
import OrderProductRawMaterialView from '../Venderrecive/OrderProductRawMaterialView';
import CustumerProductview from '../Venderrecive/CustumerProductview';
import VendorReceiveView from '../Venderrecive/VendorReceiveView';
import AddReceive from '../Venderrecive/AddReceive';

const VendorProductdetails = () => {
    const token = localStorage.getItem('token');
    const [orderlist, setOrderList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [rowMatrialslist, setRowMatrialslist] = useState([]);
    const [counters, setCounters] = useState([]);
    const [fields, setFields] = useState({
        date: '',
        vender_id: '',
        product_id: '',
        qty: '',
    });
    const [modalState, setModalState] = useState({
        addVendor: false,
        addReceive: false,
        orderProductView: false,
        orderVendorView: false,
    });


    // console.warn(initialProductDetails);
    const [productDetails, setProductDetails] = useState({});
    const handleInputChange = (order_id, product_order_id, vender_id, product_id, qty, key, value) => {
        // Update product details state with new value
        setProductDetails(prevDetails => {
            const updatedDetails = {
                ...prevDetails,

                [vender_id]: {
                    ...prevDetails[vender_id],
                    [product_id]: {
                        ...prevDetails[vender_id]?.[product_id],
                        [qty]: {
                            ...prevDetails[vender_id]?.[product_id]?.[qty],
                            [key]: value,
                        }
                    }
                }
            };

            // Return the updated state
            return updatedDetails;
        });
        const payload = {
            order_id: order_id,
            vender_id: vender_id,
            item_id: product_id,
            product_id: product_order_id,
            qty: qty,
            date: key == 'date' ? value : '',
            // unit_price: key == 'unit_price' ? value : '',
        };
        handleSubmit(payload);
        // console.warn(payload)
    };
    const handleInputChange1 = (order_id, product_order_id, vender_id, product_id, qty, key, value) => {
        // Update product details state with new value
        setProductDetails(prevDetails => {
            const updatedDetails = {
                ...prevDetails,

                [vender_id]: {
                    ...prevDetails[vender_id],
                    [product_id]: {
                        ...prevDetails[vender_id]?.[product_id],
                        [qty]: {
                            ...prevDetails[vender_id]?.[product_id]?.[qty],
                            [key]: value,
                        }
                    }
                }
            };

            // Return the updated state
            return updatedDetails;
        });
        const payload = {
            order_id: order_id,
            vender_id: vender_id,
            item_id: product_id,
            product_id: product_order_id,
            qty: qty,
            // date: key == 'date' ? value : '',
            unit_price: key == 'unit_price' ? value : '',
        };
        handleSubmit1(payload);
        // console.warn(payload)
    };

    const handleSubmit = async (payload) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Add-Receive-Product-Row-Matrials-store`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            if (response.data.status === 1) {
                // toast.success(response.data.message);
                fetchOrderlist();
            } else {
                // toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred');
        }
    };
    const handleSubmit1 = async (payload) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Add-Receive-Product-Row-Matrials-store-price`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            if (response.data.status === 1) {
                // toast.success(response.data.message);
                fetchOrderlist();
            } else {
                // toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred');
        }
    };


    const navigate = useNavigate();

    useEffect(() => {
        if (!modalState.addReceive) {
            fetchOrderlist();

        }
    }, [modalState.addReceive, token]);

    const fetchOrderlist = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Crete-custumer-product-name-list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            const orders = response.data.data || [];

            setOrderList(orders);
            const initialDetails = {};
            orders.forEach(order => {
                const customerName = order.customer_name;

                setProductDetails(prevFields => ({
                    ...prevFields,
                    CustumerName: customerName,
                }));

                // Process each product in product_data
                order.product_data.forEach(product => {
                    initialDetails[product.vdr_id] = {
                        product_id: product.product_id,
                        order_id: product.order_id,
                        vender_id: product.vender_id,
                        qty: product.qty,
                        unit_price: product.unit_price,
                        date: product.date,
                        customerName,
                    };
                });
                console.warn(initialDetails)
            });
            setProductDetails(initialDetails);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const ProductView = async (order) => {
        localStorage.setItem('Vendor_view_id', order);
        setModalState({ ...modalState, orderVendorView: true });
    };
    const VendorRecriveStatusChange = async (vendorId, newStatus) => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Status it!",
            });

            if (result.isConfirmed) {
                const token = localStorage.getItem('token');
                const url = `${process.env.REACT_APP_API_URL}Vendor-receive-status/${vendorId}`;

                const config = {
                    method: 'post',
                    url: url,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                    data: {
                        status: newStatus,
                    },
                };

                const response = await axios(config);

                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    fetchOrderlist();
                } else {
                    toast.error(response.data.message);
                }
            }
        } catch (error) {
            console.error("Error updating status:", error);
            toast.error("Error updating status. Please try again later.");
        }
    };

    const VendorDelete = async (order) => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });
            if (result.isConfirmed) {
                let url = `${process.env.REACT_APP_API_URL}Vendor-receive-delete/${order}`;
                const config = {
                    method: 'get',
                    url: url,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                };
                const response = await axios(config);
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    fetchOrderlist();
                } else {
                    toast.error(response.data.message);
                }
            }
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            // setLoadinglist(false);
        }
    };

    const handleView = (order) => {
        localStorage.setItem('orderRawproduct_id', order);
        setModalState({ ...modalState, orderProductView: true });
    };
    const handleViewProduct = (id) => {
        localStorage.setItem('product_view_id', id);
        setModalState({ ...modalState, orderProductView: true });
    };

    const vendorProductColumns = useMemo(
        () => [
            {
                Header: 'Sr No',
                accessor: (row, i) => i + 1,
            },
            {
                Header: 'Or. Id',
                accessor: 'order_id',
            },
            {
                Header: 'Cu. Name',
                accessor: 'customer_name',
            },
            {
                Header: 'Cu. Type',
                accessor: 'customer_type',
            },

            {
                Header: 'Order',
                accessor: 'product_data',
                id: 'product_name',
                Cell: ({ cell: { value } }) => (
                    <tbody className='table_child'>
                        {value.map((product, index) => (
                            <tr key={index}>
                                <td>{product.name}</td>
                            </tr>
                        ))}
                    </tbody>
                ),
            },
            {
                Header: 'Vendor',
                accessor: 'product_data',
                id: 'vendor_name',
                Cell: ({ cell: { value } }) => (
                    // <table>
                    <tbody className='table_child'>
                        {value && value.map((product, index) => (
                            <tr key={index}>
                                <td>{product.vendor_name}</td>
                            </tr>
                        ))}
                    </tbody>
                    // </table>
                ),
            },
            {
                Header: 'Ve. Date',
                accessor: 'product_data',
                id: 'date',
                Cell: ({ cell: { value }, row }) => (
                    <tbody>
                        {value && value.map((product, index) => (
                            <tr key={index}>
                                <td>
                                    <Col md={12} sm={12}>
                                        <Form.Group>
                                            <Form.Control
                                                type="date"
                                                placeholder="Enter Date"
                                                className='w-auto-design'
                                                name="date"
                                                value={
                                                    productDetails[product.order_id]?.[product.vender_id]?.[product.product_id]?.[product.qty]?.date ||
                                                    product.date 
                                                    // new Date().toISOString().split("T")[0] // Default to today's date
                                                }
                                                onChange={(e) => handleInputChange(
                                                    product.order_id,
                                                    product.product_order_id,
                                                    product.vender_id,
                                                    product.product_id,
                                                    product.qty,
                                                    'date',
                                                    e.target.value
                                                )}
                                                onFocus={(e) => e.target.showPicker()} // Open date picker on focus
                                            />
                                        </Form.Group>
                                    </Col>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                ),
            },


            {
                Header: 'Price',
                accessor: 'product_data',
                id: 'unit_price',
                
                Cell: ({ cell: { value }, row }) => {
                    // const formatPrice = (price) => {
                    //     if (price === undefined || price === null) return '';
                    //     return new Intl.NumberFormat().format(price);
                    // };
                    const formatPrice = (price) => {
                        if (price === undefined || price === null) return '';
                        return price.toString(); // Converts the price to a string without adding commas
                    };
                                
                    return (
                        <tbody>
                            {value && value.map((product, index) => (
                                <tr key={index}>
                                    <td>
                                        <Form.Group>
                                            <Form.Control
                                                type="text"
                                                placeholder="Price"
                                                name="unit_price"
                                                className='w-auto-design'
                                                value={formatPrice(
                                                    productDetails[row.order_id]?.[product.vender_id]?.[product.product_id]?.[product.qty]?.unit_price 
                                                    ?? product.unit_price
                                                )}
                                                onChange={(e) => handleInputChange1(
                                                    product.order_id,
                                                    product.product_order_id,
                                                    product.vender_id,
                                                    product.product_id,
                                                    product.qty,
                                                    'unit_price',
                                                    e.target.value
                                                )}
                                            />
                                        </Form.Group>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    );
                },
            },
            
            {
                Header: 'S Price',
                accessor: 'product_data',
                id: 'price',
                Cell: ({ cell: { value }, row }) => {
                    const formatPrice = (price) => {
                        if (price === undefined || price === null) return '';
                        return new Intl.NumberFormat().format(price);
                    };
            
                    return (
                        <>
                            {value && value.map((product, index) => (
                                <tr key={index}>
                                    <td>
                                        <Form.Group>
                                            <Form.Control
                                                type="text"
                                                placeholder="Price"
                                                name="unit_price1"
                                                className='w-auto-design'
                                                value={formatPrice(product.price)}
                                                disabled
                                            />
                                        </Form.Group>
                                    </td>
                                </tr>
                            ))}
                        </>
                    );
                },
            },
            


            {
                Header: 'Receive',
                accessor: 'product_data',
                id: 'status',
                Cell: ({ cell: { value } }) => (
                    <tbody>
                        {value && value.map((product, index) => (
                            <tr key={index}>
                                {/* {product.date !== null && ( */}
                                <td style={{ color: product.status === 1 ? 'green' : 'red' }}>
                                    <select
                                        className='form-select w-auto noborder'
                                        style={{ color: product.status === 1 ? 'green' : 'red' }}
                                        defaultValue={product.status === 1 ? 'Yes' : 'No'}
                                        onChange={(e) => {
                                            const newStatus = e.target.value === 'Yes' ? 1 : 0;
                                            VendorRecriveStatusChange(product.vdr_id, newStatus);
                                        }}
                                    >
                                        <option value="Yes" className='text-success' selected={product.status === 1}>Yes</option>
                                        <option value="No" className='text-danger' selected={product.status === 0}>No</option>
                                    </select>
                                </td>
                                {/* )} */}
                            </tr>
                        ))}
                    </tbody>
                ),
            },

            {
                Header: 'Action',
                accessor: 'product_data',
                // id: 'date',
                Cell: ({ cell: { value } }) => (
                    // <table>
                    <tbody>
                        {value && value.map((product, index) => (
                            <td className="edit-delete-action justify-content-start d-flex gap-2">

                                {product.date !== null && <Link to="#" style={{ "--i": "#233882" }} className="p-2" onClick={() => ProductView(product.vdr_id)}><FaEye /></Link>}
                                {/* {product.date !== null && <Button to="#" style={{ "--i": "#233882" }} className="p-2" ><FaEdit /></Button>} */}
                                {product.date !== null && <Link to="#" style={{ "--i": "red" }} className="p-2" onClick={() => VendorDelete(product.vdr_id)}><MdDelete /></Link>}
                            </td>
                        ))}
                    </tbody>
                    // </table>
                ),
            },


            // {
            //     Header: 'Action',
            //     accessor: 'customer_type',
            //     accessor: 'actions',
            //     Cell: ({ row }) => (
            //         <div>
            //             <Button variant="secondary" onClick={() => handleViewProduct(row.original.id)} className="ms-2">View</Button>
            //         </div>
            //     ),
            // },

        ],
        []
    );


    const buttonname = "Receive Material";
    const buttonclass = "btn-added btn_custum_new";
    const title = "Product Details";
    const titlesec = "Actual Receive History";

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value); // No need to parseInt, keep it as a string
    };

    const filteredOrderList = selectedStatus ? orderlist.filter(item => item.customer_name === selectedStatus) : orderlist;

    return (
        <>
            <ToastContainer />

            <div className='justify-content-center processing'>
                <div className='text-center'>
                    <Row>
                        <Col md={12} xs={12} lg={12} className='duedrop1'>
                            <Form.Select
                                aria-label="Default select example"
                                onChange={handleStatusChange}
                                value={selectedStatus}
                                style={{ border: '1px solid #8080802' }}
                            >
                                <option value=''>Customer Name</option>
                                {orderlist.map((order) => (
                                    <option key={order.customer_name} value={order.customer_name}>
                                        {order.customer_name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                </div>
            </div>
            <Vendorproductt columns={vendorProductColumns} data={filteredOrderList} title={title} addvendor={buttonname} btnclass={buttonclass} />
            <hr />
            <Modal
                size="lg"
                show={modalState.addReceive}
                onHide={() => setModalState({ ...modalState, addReceive: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <AddReceive onSave={() => { setModalState({ ...modalState, addReceive: false }); }} />
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={modalState.orderProductView}
                onHide={() => setModalState({ ...modalState, orderProductView: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 style={{ color: "gray" }} className="card-title">Customer Product View Details</h5>
                </Modal.Header>

                <Modal.Body closeButton>
                    <CustumerProductview onSave={() => { setModalState({ ...modalState, orderProductView: false }); }} />
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={modalState.orderVendorView}
                onHide={() => setModalState({ ...modalState, orderVendorView: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 style={{ color: "gray" }} className="card-title">Customer Vendor View Details</h5>
                </Modal.Header>
                <Modal.Body closeButton>
                    <VendorReceiveView onSave={() => { setModalState({ ...modalState, orderVendorView: false }); }} />
                </Modal.Body>
            </Modal>


        </>
    );
};

export default VendorProductdetails;
