import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ExtraCharges({ onSave }) {
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [rows, setRows] = useState([{
        vendor_id: null,
        category_id: null,
        customer_id: null,
        name: '',
        pro_qty: '',
        price: '',
        total_amount: ''
    }]);

    const categoryOptions = categories.map(item => ({ value: item.id, label: item.name }));
    const customerOptions = customers.map(item => ({ value: item.id, label: item.customer_name }));
    const vendorOptions = vendors.map(item => ({ value: item.id, label: item.vendor_name }));

    const handleAddRow = () => {
        setRows([...rows, { vendor_id: null, category_id: null, customer_id: null, name: '', pro_qty: '', price: '', total_amount: '' }]);
    };

    const handleDeleteRow = index => {
        setRows(rows.filter((_, i) => i !== index));
    };

    const handleRowChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;

        if (field === 'pro_qty' || field === 'price') {
            updatedRows[index].total_amount = (updatedRows[index].pro_qty * updatedRows[index].price).toFixed(2);
        }

        setRows(updatedRows);
    };
    const validateRows = () => {
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            if (!row.customer_id) {
                toast.error(`Row ${i + 1}: Customer is required.`);
                return false;
            }
            if (!row.name) {
                toast.error(`Row ${i + 1}: Product name is required.`);
                return false;
            }
        }
        return true;
    };
    const handleSubmitOrder = async () => {
        if (!validateRows()) {
            return;
        }
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}Extra-Charges-store`,
                { products: rows },
                { headers: { 'Content-Type': 'application/json', 'x-access-token': token } }
            );

            if (response.data.status === 1) {
                toast.success(response.data.message);
                setRows([{ vendor_id: null, category_id: null, customer_id: '', name: '', pro_qty: '', price: '', total_amount: '' }]);
                onSave();

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            // toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [categoriesRes, customersRes, vendorsRes] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}category-list`, { headers: { 'x-access-token': token } }),
                    axios.get(`${process.env.REACT_APP_API_URL}Custumer-name-list`, { headers: { 'x-access-token': token } }),
                    axios.get(`${process.env.REACT_APP_API_URL}Vender-list`, { headers: { 'x-access-token': token } })
                ]);

                setCategories(categoriesRes.data.data || []);
                setCustomers(customersRes.data.data || []);
                setVendors(vendorsRes.data.data || []);
            } catch (error) {
                toast.error('Failed to fetch data.');
            }
        };

        fetchData();
    }, [token]);

    return (
        <>
            <ToastContainer />
            <Card>
                <Card.Header>
                    <h3 className="card-title mb-0">Extra Charges Add</h3>
                </Card.Header>
                <Card.Body>
                    <div className=" d-flex w-100 justify-content-end">
                        <Button onClick={handleAddRow} className="mb-3">Add More</Button>
                    </div>
                    {rows.map((row, index) => (
                        <Row key={index} className="mb-3">
                            <Col xs={12} md={4}>
                                <Form.Group controlId={`customer_id_${index}`}>
                                    <Form.Label>Product Category</Form.Label>
                                    <Select
                                        value={customerOptions.find(option => option.value === row.customer_id)}
                                        options={customerOptions}
                                        onChange={selected => handleRowChange(index, 'customer_id', selected ? selected.value : null)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group controlId={`category_id_${index}`}>
                                    <Form.Label>Product Category</Form.Label>
                                    <Select
                                        value={categoryOptions.find(option => option.value === row.category_id)}
                                        options={categoryOptions}
                                        onChange={selected => handleRowChange(index, 'category_id', selected ? selected.value : null)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group controlId={`vendor_id_${index}`}>
                                    <Form.Label>Vendor Name</Form.Label>
                                    <Select
                                        value={vendorOptions.find(option => option.value === row.vendor_id)}
                                        options={vendorOptions}
                                        onChange={selected => handleRowChange(index, 'vendor_id', selected ? selected.value : null)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group controlId={`name_${index}`}>
                                    <Form.Label>Product Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={row.name}
                                        onChange={e => handleRowChange(index, 'name', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={2}>
                                <Form.Group controlId={`pro_qty_${index}`}>
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={row.pro_qty}
                                        onChange={e => handleRowChange(index, 'pro_qty', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={2}>
                                <Form.Group controlId={`price_${index}`}>
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={row.price}
                                        onChange={e => handleRowChange(index, 'price', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={2}>
                                <Form.Group controlId={`total_amount_${index}`}>
                                    <Form.Label>Total Amount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={row.total_amount}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={1} className="d-flex align-items-center">
                                <Button variant="danger" onClick={() => handleDeleteRow(index)}>
                                    <RiDeleteBin6Line />
                                </Button>
                            </Col>
                        </Row>
                    ))}
                    <div className=" d-flex w-100 justify-content-end">
                        <Button onClick={handleSubmitOrder} disabled={loading}>
                            {loading ? 'Saving...' : 'Create'}
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}
