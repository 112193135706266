import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Card, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

const useFormFields = (initialFields) => {
    const [fields, setFields] = useState(initialFields);
    const [errors, setErrors] = useState(
        Object.keys(initialFields).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    );

    const handleFieldChange = (key) => (e) => {
        let value = e.target.value;

        if (key === 'MobileNumber') {
            value = value.replace(/\D/g, '').slice(0, 10); // Limit to 10 digits
        } else if (key === 'Salary') {
            value = value.replace(/\D/g, ''); // Ensure only numbers are accepted
        } else if (key === 'AadharNumber') {
            value = formatAadharNumber(value); // Format Aadhar number
        }

        setFields({ ...fields, [key]: value });
        setErrors({ ...errors, [key]: false });
    };
    const formatAadharNumber = (value) => {
        // Remove all non-digit characters and limit to 12 digits
        const sanitizedValue = value.replace(/\D/g, '').slice(0, 12);
        // Format as '1111 1111 1111'
        return sanitizedValue.replace(/(\d{4})(\d{4})(\d{4})/, '$1 $2 $3');
    };

    return { fields, errors, handleFieldChange, setFields, setErrors };
};

const VendorForm = ({ onSave }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const initialFields = {
        Name: '',
        Age: '',
        MobileNumber: '',
        Designation: '',
        AadharNumber: '',
        EmailId: '',
        Salayr: '',
        Address: '',
        Joining_date: '',
        Status: '',
    };

    const { fields, errors, handleFieldChange, setFields, setErrors } = useFormFields(initialFields);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        EmployeeView();
    }, []);

    const EmployeeView = async () => {
        const vendorUpdate_id = localStorage.getItem('employee_id');
        try {
            let url = `${process.env.REACT_APP_API_URL}Employee-edit/${vendorUpdate_id}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            const data = response.data.data[0]; // Assuming you are expecting only one vendor data object

            setFields({
                Name: data.emp_name || '',
                Age: data.age || '',
                MobileNumber: data.mobile_number || '',
                Designation: data.designation || '',
                AadharNumber: data.role || '',
                EmailId: data.email || '',
                Salayr: data.salayr || '',
                Address: data.address || '',
                Joining_date: data.joining_date || '',
                Status: data.status || '',
            });
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const validateFields = (fields, setErrors) => {
        const newErrors = {};
        let isValid = true;

        if (!fields.Name) {
            newErrors.Name = true;
            isValid = false;
        }

        const aadharNumber = fields.AadharNumber.replace(/\s/g, ''); // Remove spaces
        if (aadharNumber.length !== 12) {
            newErrors.AadharNumber = "Aadhar number must be 12 digits";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async () => {
        const vendorUpdate_id = localStorage.getItem('employee_id');

        if (validateFields(fields, setErrors)) {
            setLoading(true);
            const payload = {
                id: vendorUpdate_id,
                emp_name: fields.Name,
                age: fields.Age,
                mobile_number: fields.MobileNumber,
                designation: fields.Designation,
                role: fields.AadharNumber.replace(/\s/g, ''),
                email: fields.EmailId,
                salayr: fields.Salayr,
                address: fields.Address,
                joining_date: fields.Joining_date,
                status: fields.Status
            };

            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to Update the Employee?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, update it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
            });
        
            if (result.isConfirmed) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}Employee-update`, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });

                if (response.data.status === 1) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Update Employee Successfully!',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false
                    });
                    setLoading(false);
                    // setTimeout(() => {
                    //     // navigate('/vendermanagement');
                    // }, 1000);
                    onSave();
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: response.data.message,
                        icon: 'error',
                    });
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'An error occurred during the update',
                    icon: 'error',
                });
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    } else {
        Swal.fire('Cancelled', 'Update Employee has been cancelled', 'info');
    }
    };

    const handleSubmitVendor = () => {
        setLoading(true);
        handleSubmit();
    };

    return (
        <div id="addVendorModal">
            <div className="page-header">
                <div className="add-item d-flex justify-content-between">
                    {/* <div className="page-title">
                        <h4>Update Employee</h4>
                        <h6>Employee</h6>
                    </div> */}
                </div>
                <div className="button_add_row"></div>
            </div>
            <Card>
                <Card.Header>
                    <Card.Title>Update Employee</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Container fluid>
                        <Row>
                            {Object.keys(initialFields).map((key, index) => (
                                <Col lg={4} sm={6} xs={12} key={index}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="custom-label">
                                            {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                        </Form.Label>
                                        {key === 'Status' ? (
                                            <Form.Control
                                                as="select"
                                                value={fields[key]}
                                                onChange={handleFieldChange(key)}
                                                className={errors[key] ? 'is-invalid' : ''}
                                            >
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </Form.Control>
                                        ) : (
                                            <Form.Control
                                                type={key === 'MobileNumber' || key === 'Salayr' || key === 'Age' ? 'number' : (key === 'Joining_date' ? 'date' : 'text')}
                                                placeholder={key.replace(/([A-Z])/g, ' $1')}
                                                value={fields[key]}
                                                onChange={handleFieldChange(key)}
                                                className={errors[key] ? 'is-invalid' : ''}
                                            />
                                        )}
                                    </Form.Group>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" className="btn btn-submit" onClick={handleSubmitVendor} disabled={loading}>
                            {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Employee-Update'}
                        </Button>
                    </div>
                </Card.Footer>
            </Card>

            {/* <ToastContainer /> */}
        </div>
    );
};

export default VendorForm;
