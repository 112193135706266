import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from 'react-bootstrap/Button'; // Import Button from react-bootstrap
import Modal from 'react-bootstrap/Modal'; // Import Modal from react-bootstrap
import Expenditure_Amountview from '../expenditure/Expenditure_Amountview';
import Expenditure_type_month_Amount from '../expenditure/Expenditure_type_month_Amount';
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { Link } from 'react-router-dom';
import { FaEye } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const ExpenditureMonthAmount = () => {
  const [employeeList, setEmployeeList] = useState([]);
  const navigate = useNavigate();
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const token = localStorage.getItem('token');
  const [modalState, setModalState] = useState({ ExpenditureAmountview: false, expenditure_type_month_Amount: false });

  useEffect(() => {
    fetchEmployeeList();
  }, [selectedYear, selectedMonth]);

  const fetchEmployeeList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}expenditure-month-totalamount`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      const data = response.data.data || [];
      extractYears(data);
      const filteredData = filterLastThreeMonths(data);
      setEmployeeList(filteredData);
    } catch (error) {
      console.error('Error fetching employee list:', error);
      toast.error('Error fetching data');
    }
  };

  const extractYears = (data) => {
    const years = [...new Set(data.map(item => item.month.split('-')[0]))];
    setYearOptions(years);
  };

  const filterLastThreeMonths = (data) => {
    const currentMonth = selectedMonth;
    const currentYear = selectedYear;

    const lastThreeMonths = Array.from({ length: 3 }, (_, i) => {
      const month = (currentMonth - i) <= 0 ? 12 + (currentMonth - i) : currentMonth - i;
      const year = (currentMonth - i) <= 0 ? currentYear - 1 : currentYear;
      return { month, year };
    });

    return data.filter(item => {
      const [year, month] = item.month.split('-');
      return lastThreeMonths.some(({ month: m, year: y }) => parseInt(month) === m && parseInt(year) === y);
    });
  };

  const getMonthName = (month) => {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[month - 1];
  };

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
    setSelectedMonth(new Date().getMonth() + 1);
  };

  const handlePreviousMonth = () => {
    if (selectedMonth === 1) {
      setSelectedMonth(12);
      setSelectedYear(selectedYear - 1);
    } else {
      setSelectedMonth(selectedMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (selectedMonth === 12) {
      setSelectedMonth(1);
      setSelectedYear(selectedYear + 1);
    } else {
      setSelectedMonth(selectedMonth + 1);
    }
  };

  const ExpenditureAmountView = (id) => {
    setModalState({ ExpenditureAmountview: true });
    navigate('/Expenditure-Amountview');
    localStorage.setItem('expenditure_month', id);
  };
  const ExpenditureTypeAmountView = (month, expenditure_id) => {
    setModalState({ expenditure_type_month_Amount: true });
    navigate('/Expenditure_Totalmonth_Amount');
    localStorage.setItem('expenditure_type_month', month);
    localStorage.setItem('expenditure_id_type_month', expenditure_id);
  };

  return (
    <>
      <ToastContainer />
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <div><h3>Expenditure Months Amount</h3></div>
          <div className='d-flex gap-2'>
            <div className="controls d-flex align-items-center gap-2" >
              <div><p htmlFor="year-select" className='mb-0'>Select Year:</p></div>
              <div>
                <select id="year-select" className='form-select' value={selectedYear} onChange={handleYearChange}>
                  {yearOptions.map((year, index) => (
                    <option key={index} value={year}>{year}</option>
                  ))}
                </select>
              </div>
            </div>
            <Link to='/Expenditure-list' className='btn-added'>Back</Link>
          </div>
        </div>
      </div>

      <div className="position-relative">
        <div className="expenditure-container">
          {employeeList.length > 0 ? (
            <table className="table table-striped">
              <thead className='thead-dark'>
                <tr>
                  <th>Month</th>
                  <th>Total Amount</th>
                  <th>Expenditure Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {employeeList.map((monthData, index) => (
                  <tr key={index}>
                    <td style={{color: '#ba1654'}}>{getMonthName(parseInt(monthData.month.split('-')[1]))} {monthData.month.split('-')[0]}</td>
                    <td>₹{monthData.total_amount}</td>
                    <td>
                      <ul className="expenditure-list">
                        {monthData.expenditures.map((expenditure) => (
                          <li key={expenditure.expenditure_id}>
                            <span onClick={() => ExpenditureTypeAmountView(monthData.month, expenditure.expenditure_id)}>
                              {expenditure.expenditure_type_name}: ₹{expenditure.total_amount}
                              <FaEye style={{ color: '#ba1654', marginLeft: '10px' }} />
                            </span>
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <button className='btn btn-primary ex-btn' onClick={() => ExpenditureAmountView(monthData.month)}>
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No expenditure data found for the selected year and month.</p>
          )}
        </div>
        {/* <div className="buttonslider">
          <button className='left' onClick={handlePreviousMonth}><MdOutlineArrowBackIosNew /></button>
          <button className='right' onClick={handleNextMonth}><MdOutlineArrowForwardIos /></button>
        </div> */}
      </div>

      {/* <Modal
        show={modalState.ExpenditureAmountview}
        size='lg'
        onHide={() => setModalState({ ExpenditureAmountview: false })}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static" 
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h3 className="card-title mb-0">Expenditure</h3>
        </Modal.Header>
        <Modal.Body>
          <Expenditure_Amountview onSave={() => setModalState({ ExpenditureAmountview: false })} />
        </Modal.Body>
      </Modal> */}

      <Modal
        show={modalState.expenditure_type_month_Amount}
        size='lg'
        onHide={() => setModalState({ expenditure_type_month_Amount: false })}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static" 
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h3 className="card-title mb-0">Expenditure</h3>
        </Modal.Header>
        <Modal.Body>
          <Expenditure_type_month_Amount onSave={() => setModalState({ expenditure_type_month_Amount: false })} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExpenditureMonthAmount;
