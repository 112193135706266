import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { MdAddCircle } from 'react-icons/md';
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong, FaPlus } from "react-icons/fa6";
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

export default function Addmaterial({ onSave }) {
    const token = localStorage.getItem('token');
    const met_receive_update = localStorage.getItem('met_receive_update');

    const navigate = useNavigate();
    const [custumerlist, setCustumerlist] = useState([]);
    const [loading, setLoading] = useState(false);
    const [vendorData, setVendorData] = useState([]);
    const [totalCostWithGst, setTotalCostWithGst] = useState(0);
    const [totalGstAmount, setTotalGstAmount] = useState(0);
    const [rowother, setRowother] = useState([{ met_name: '', met_qty: '', met_rate: '', met_cost: '', size_x: '', size_l: '', met_pcs: '' }]);
    const [total_cost, setTotalCost] = useState(0);
    const [selectedOrderType, setSelectedOrderType] = useState([]);
    const handleAddRowOther = () => {
        setRowother([...rowother, { met_name: '', met_qty: '', met_rate: '', met_cost: '', size_x: '', size_l: '', met_pcs: '' }]);
    };

    const handleOrderTypeChange = (selectedOption) => {
        setSelectedOrderType(selectedOption);
        // console.warn(selectedOrderType);
        // setFields({
        //     customer_id: '',
        //     company_name: '',
        // });
    };
    const initialFields = {
        vendor_id: '',
        company_name: '',
        customer_id: '',
        date: '',
        slip_no: '',
        gst: '',
        // met_type: selectedOrderType.value,
    };
    const [fields, setFields] = useState(initialFields);

    const handleChange = (value, name) => {
        setFields({ ...fields, [name]: value });
        if (name === 'customer_id') {
            // VenderView(value);
        }
    };

    // const handleRowChangeOther = (index, field, value) => {
    //     const updatedRows = [...rowother];
    //     updatedRows[index][field] = value;

    //     if (field === 'met_qty' || field === 'met_rate') {
    //         const met_qty = updatedRows[index].met_qty || 0;
    //         const met_rate = updatedRows[index].met_rate || 0;
    //         updatedRows[index].met_cost = met_qty * met_rate;
    //     }

    //     setRowother(updatedRows);
    // };
    const handleRowChangeOther = (index, field, value) => {
        const updatedRows = [...rowother];
        updatedRows[index][field] = value;

        // Calculate met_cost
        const { size_l, size_x, met_qty, met_rate } = updatedRows[index];
        const parsedSizeL = size_l ? parseFloat(size_l) : 1;
        const parsedSizeX = size_x ? parseFloat(size_x) : 1;
        const parsedMetQty = parseFloat(met_qty) || 0;
        const parsedMetRate = parseFloat(met_rate) || 0;

        const base_cost = parsedSizeL * parsedSizeX * parsedMetQty * parsedMetRate;
        updatedRows[index].met_cost = base_cost;

        setRowother(updatedRows);
    };

    useEffect(() => {
        const total = rowother.reduce((sum, row) => sum + (parseFloat(row.met_cost) || 0), 0);
        setTotalCost(total);
    }, [rowother]);

    useEffect(() => {
        const gst = parseFloat(fields.gst) || 0;
        const gstAmount = total_cost * (gst / 100);
        const totalWithGst = total_cost + gstAmount;

        setTotalGstAmount(gstAmount);
        setTotalCostWithGst(totalWithGst);
    }, [total_cost, fields.gst]);

    const handleSubmit = async (url, payload) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to Update the Employee?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'No, cancel!',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        });
    
        if (result.isConfirmed) {
        setLoading(true);
        try {
            const response = await axios.post(url, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            if (response.data.status === 1) {
                Swal.fire({
                    title: 'Success',
                    text: 'Material Receive Update Successfully!',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
                setRowother([{ met_name: '', met_qty: '', met_rate: '', met_cost: '', met_pcs: '' }]);
                setFields(initialFields);
                onSave();
            } else {
                Swal.fire({
                    title: 'Error',
                    text: response.data.message,
                    icon: 'error',
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'An error occurred during the update',
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
    } else {
        Swal.fire('Cancelled', 'Update Receive Material has been cancelled', 'info');
    }
    };

    const handleSubmitOrder = () => {
        const { vendor_id, customer_id } = fields;

        if (!vendor_id || !selectedOrderType.value) {
            if (!vendor_id) {
                toast.error('Vendor Name is required');
            }
            if (!selectedOrderType.value) {
                toast.error('Selected is required');
            }
            return;
        }
        if (total_cost === null || total_cost === 0) {
            toast.error('Total cost cannot be zero');
            return;
        }
        const OtherData = rowother.map(row => ({
            met_name: row.met_name,
            met_qty: row.met_qty,
            met_pcs: row.met_pcs,
            met_rate: row.met_rate,
            met_cost: row.met_cost,
            size_l: row.size_l,
            size_x: row.size_x,
            gst: row.gst,
        }));
        const requestData = {
            ...fields,
            id: met_receive_update,
            met_type: selectedOrderType.value,
            materialdata: OtherData,
            total_cost: totalCostWithGst.toFixed(2),
        };
        handleSubmit(
            `${process.env.REACT_APP_API_URL}Add-material-store-update`,
            requestData,
        );
    };

    useEffect(() => {
        const fetchCustumer = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Add-material-custumer-name-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setCustumerlist(response.data.data || []);
            } catch (error) {
                console.error('Error fetching Vendor:', error);
            }
        };

        fetchCustumer();
    }, [token]);

    useEffect(() => {
        VenderView()
    }, []);
    const VenderView = async (vendorId) => {
        try {
            let url = `${process.env.REACT_APP_API_URL}Vender-list`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            setVendorData(response.data.data || []);
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        }
    };
    useEffect(() => {
        fetchProducts()

    }, [])
    const fetchProducts = async () => {
        try {
            const url = `${process.env.REACT_APP_API_URL}material-edit/${met_receive_update}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            const { id, vendor_id, customer_id, date, gst, slip_no, company_name, materials, met_type } = response.data.data;
            console.warn('API Response:', response.data.data);
            const selectedOption = orderTypeOptions.find(option => option.value == met_type);

            // Update the state with the fetched data
            setFields({
                vendor_id: vendor_id,
                customer_id: customer_id,
                total_cost: total_cost,
                date: date,
                gst: gst,
                slip_no: slip_no,
                met_type: met_type,
                company_name: company_name,
                selectedOrderType: met_type,
            });
            setSelectedOrderType(selectedOption || null);
            if (materials.length > 0) {
                const updatedRows = materials.map(item => ({
                    met_name: item.met_name,
                    met_qty: item.met_qty,
                    met_pcs: item.met_pcs,
                    met_rate: item.met_rate,
                    met_cost: item.met_cost,
                    size_l: item.size_l,
                    size_x: item.size_x,
                    gst: item.gst,

                }));
                setRowother(updatedRows);
                console.warn('Updated Rows:', updatedRows);
            }

        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const handleDeleteRowother = (index) => {
        const updatedRows = rowother.filter((_, i) => i !== index);
        setRowother(updatedRows);
    };
    const orderTypeOptions = [
        { value: '', label: 'Select Receive' },
        { value: 'customer', label: 'Customer' },
        { value: 'company', label: 'Godaven' },
    ];
    return (
        <>
            {/* <ToastContainer /> */}
            <Card >

                <Card.Body>
                    <Form>
                        <Row>
                            {/* <Col md={2} lg={2} xs={12}>
                                <Form.Group>
                                    <Form.Label>Select Receive Type</Form.Label>
                                    <Select
                                        options={orderTypeOptions}
                                        // defaultValue={orderTypeOptions[0]}
                                        onChange={handleOrderTypeChange}
                                    />
                                </Form.Group>
                               

                            </Col> */}
                            <Col md={2} sm={12}>
                                <Form.Group>
                                    <Form.Label>Select Receive Type</Form.Label>
                                    <Form.Select
                                        value={selectedOrderType.value || ''}
                                        onChange={(e) => handleOrderTypeChange(orderTypeOptions.find(option => option.value == e.target.value))}
                                    >
                                        {orderTypeOptions.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            {selectedOrderType.value === 'company' && (
                            <Col md={2} lg={2} xs={2}>
                                <Form.Group controlId="company_name">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="company_name"
                                        placeholder='Company Name'
                                        value={fields.company_name}
                                        onChange={(e) => handleChange(e.target.value, 'company_name')}
                                    />
                                </Form.Group>
                            </Col>
                            )} 

                            {selectedOrderType.value === 'customer' ? (
                                <Col md={3} sm={12}>
                                    <Form.Group>
                                        <Form.Label>Customer Name</Form.Label>
                                        <Form.Select
                                            value={fields.customer_id}
                                            onChange={(e) => handleChange(e.target.value, 'customer_id')}
                                        >
                                            <option value="">Select Customer Name</option>
                                            {custumerlist.map((vendor) => (
                                                <option key={vendor.id} value={vendor.id}>
                                                    {vendor.customer_name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            ) : null}
                            <Col md={3} sm={12}>
                                <Form.Group>
                                    <Form.Label>Vendor Name</Form.Label>
                                    <Form.Select
                                        value={fields.vendor_id}
                                        onChange={(e) => handleChange(e.target.value, 'vendor_id')}
                                    >
                                        <option value="">Select Vendor Name</option>
                                        {vendorData.map((vendor) => (
                                            <option key={vendor.id} value={vendor.id}>
                                                {vendor.vendor_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={2} lg={2} xs={2}>
                                <Form.Group controlId="total_cost">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="date"
                                        value={fields.date}
                                        onChange={(e) => handleChange(e.target.value, 'date')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={1} lg={1} xs={1}>
                                <Form.Group controlId="total_cost">
                                    <Form.Label>Slip No</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder='Slip No'
                                        name="slip_no"
                                        value={fields.slip_no}
                                        onChange={(e) => handleChange(e.target.value, 'slip_no')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={1} lg={1} xs={1}>
                                <Form.Group controlId="total_cost">
                                    <Form.Label>Gst</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="gst"
                                        placeholder='Gst %'
                                        value={fields.gst}
                                        onChange={(e) => handleChange(e.target.value, 'gst')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>

                    <div className='mt-4'>
                        <div className="d-flex justify-content-end align-items-center w-100">
                            <Button onClick={handleAddRowOther} className="btn btn-secondary addproduct">
                                <MdAddCircle /> Receive Material
                            </Button>
                        </div>

                        {rowother.map((row, index) => (
                            <React.Fragment key={index}>
                                {index === 0 && (
                                    <Row>
                                        <Col md={2} xs={12} lg={2}>
                                            <Form.Label>Name</Form.Label>
                                        </Col>

                                        <Col md={1} xs={12} lg={1}>
                                            <Form.Label>Size L</Form.Label>
                                        </Col>
                                        <Col md={1} xs={12} lg={1}>
                                            <Form.Label>Size H</Form.Label>
                                        </Col>
                                        <Col md={1} xs={12} lg={1}>
                                            <Form.Label>Qty</Form.Label>
                                        </Col>
                                        <Col md={1} xs={12} lg={1}>
                                            <Form.Label>Pcs</Form.Label>
                                        </Col>
                                        <Col md={2} xs={12} lg={2}>
                                            <Form.Label>Rate</Form.Label>
                                        </Col>

                                        <Col md={2} xs={12} lg={2}>
                                            <Form.Label>Cost</Form.Label>
                                        </Col>
                                        <Col md={1} xs={12} lg={1}></Col>
                                    </Row>
                                )}
                                <Row className="mt-0">
                                    <Col md={2} xs={12} lg={2}>
                                        <Form.Group className="mb-3" controlId={`met_name_${index}`}>
                                            <Form.Control
                                                type="text"
                                                name="met_name"
                                                value={row.met_name || ''}
                                                onChange={(e) => handleRowChangeOther(index, 'met_name', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={1} xs={12} lg={1}>
                                        <Form.Group className="mb-3" controlId={`size_l${index}`}>
                                            <Form.Control
                                                type="number"
                                                name="size_l"
                                                value={row.size_l || ''}
                                                onChange={(e) => handleRowChangeOther(index, 'size_l', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={1} xs={12} lg={1}>
                                        <Form.Group className="" controlId={`size_x${index}`}>
                                            <Form.Control
                                                type="number"
                                                name="size_x"
                                                value={row.size_x || ''}
                                                onChange={(e) => handleRowChangeOther(index, 'size_x', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={1} xs={12} lg={1}>
                                        <Form.Group className="mb-3" controlId={`met_qty_${index}`}>
                                            <Form.Control
                                                type="number"
                                                name="met_qty"
                                                value={row.met_qty || ''}
                                                onChange={(e) => handleRowChangeOther(index, 'met_qty', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={1} xs={12} lg={1}>
                                        <Form.Group className="mb-3" controlId={`met_pcs${index}`}>
                                            <Form.Control
                                                type="text"
                                                name="met_pcs"
                                                value={row.met_pcs || ''}
                                                onChange={(e) => handleRowChangeOther(index, 'met_pcs', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={2} xs={12} lg={2}>
                                        <Form.Group className="mb-3" controlId={`met_rate_${index}`}>
                                            <Form.Control
                                                type="number"
                                                name="met_rate"
                                                value={row.met_rate || ''}
                                                onChange={(e) => handleRowChangeOther(index, 'met_rate', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={2} xs={12} lg={2}>
                                        <Form.Group className="mb-3" controlId={`met_cost_${index}`}>
                                            <Form.Control
                                                type="number"
                                                name="met_cost"
                                                value={isNaN(row.met_cost) ? '' : parseFloat(row.met_cost).toFixed(2)}
                                                // placeholder=''
                                                readOnly
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={1} xs={12} lg={1} className='d-flex align-items-center pb-3'>
                                        <Button
                                            className='btn-added'
                                            onClick={() => handleDeleteRowother(index)}
                                        >
                                            <RiDeleteBin6Line />
                                        </Button>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="d-flex justify-content-end align-items-center w-75">
                        <Row className="mt-3">
                            <Col md={6} lg={6} xs={6}>
                                <Form.Group controlId="total_cost">
                                    <Form.Label>Total Gst Amount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="total_cost"
                                        value={totalGstAmount.toFixed(2)}
                                        readOnly
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={6}>
                                <Form.Group controlId="total_cost">
                                    <Form.Label>Total Amount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="total_cost"
                                        value={totalCostWithGst.toFixed(2)}
                                        readOnly
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
                <div className="d-flex justify-content-end align-items-center w-95 mb-1">
                    <div className="d-flex gap-1">
                        <Button className="btn takeorder" disabled={loading} onClick={handleSubmitOrder}>
                            {loading ? 'Saving...' : 'Update'}
                        </Button>
                    </div>
                </div>
            </Card>
        </>
    );
}
