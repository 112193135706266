import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import Expenditure_Type from '../expenditure/Expenditure_Type';
import Category_Expenditure from '../expenditure/Category_Expenditure';
import Expenditure_edit from '../expenditure/Expenditure_Edit';
import Category_Expenditure_Edit from '../expenditure/Category_Expenditure_Edit';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

export default function AddExpenditure({ onSave }) {
    const token = localStorage.getItem('token');
    const [custumerlist, setCustumerlist] = useState([]);
    const [custumernamelist, setCustumernameList] = useState([]);
    console.warn(custumernamelist);
    const [employelist, setEmployelist] = useState([]);
    const [loading, setLoading] = useState(false);
    const [accountlist, setAccountList] = useState([]);
    const [category_list, setExpenditureCategoryList] = useState([]);
    const [filteredCustomerList, setFilteredCustomerList] = useState([]);
    const [modalState, setModalState] = useState({ addExpenditureType: false, category_expenditure: false, category_design_edit: false, category_expenditure_edit: false });
    const initialFields = {
        expenditure_id: '',
        expenditure_category_id: '',
        employee_id: '',
        customer_id: '',
        customer_type: '',
        date: new Date().toISOString().split("T")[0], 
        received_by: '',
        exp_details: '',
        payable_month: '',
        amount: '',
        account_mode: '',
        account_id: '',
        name: '',
        description: '',
    };
    const [fields, setFields] = useState(initialFields);

    const handleChange = (value, name) => {
        setFields(prevFields => ({ ...prevFields, [name]: value }));
    };

    const handleSelectChange = (name, selectedOption) => {
        if (name === 'customer_type') {
            const filteredList = custumernamelist.filter(customer => customer.customer_type === selectedOption.value);
            setFilteredCustomerList(filteredList);
        }

        setFields(prevFields => ({
            ...prevFields,
            [name]: selectedOption ? selectedOption.value : null
        }));
    };


    const handleSubmitOrder = async () => {
        if (!fields.expenditure_id) {
            toast.error('Please Select an Expenditure Type');
            return;
        }
        if (!fields.date) {
            toast.error('Please Select an Date');
            return;
        }
        setLoading(true);
        const requestData = {
            ...fields,
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}expenditure-add`,
                requestData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                }
            );
            if (response.data.status === 1) {
                Swal.fire({
                    title: 'Success',
                    text: 'Add Expenditure Successfully!',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
                setFields(initialFields);
                onSave(); // Trigger the onSave function
            } else {
                Swal.fire({
                    title: 'Error',
                    text: response.data.message,
                    icon: 'error',
                });
            }
        } catch (error) {
            // toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [custumerResponse, employeeResponse, accountResponse, custumernameResponse, ExpenditureCategory] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}expenditure-type-list`, {
                        headers: { 'x-access-token': token }
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}Employee-list`, {
                        headers: { 'x-access-token': token }
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}Account-list`, {
                        headers: { 'x-access-token': token }
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}Custumer-name-list`, {
                        headers: { 'x-access-token': token }
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}expenditure-category-list`, {
                        headers: { 'x-access-token': token }
                    }),
                ]);

                setCustumerlist(custumerResponse.data.data || []);
                setEmployelist(employeeResponse.data.data || []);
                setAccountList(accountResponse.data.data || []);
                setCustumernameList(custumernameResponse.data.data || []);
                setExpenditureCategoryList(ExpenditureCategory.data.data || []);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (!modalState.addExpenditureType || !modalState.category_expenditure || modalState.category_design_edit, !modalState.category_expenditure_edit) {
            fetchData();
        }
    }, [token, modalState.addExpenditureType, modalState.category_expenditure, modalState.category_design_edit, modalState.category_expenditure_edit]);

    const accountModeOptions = [
        { value: 'Cash', label: 'Cash' },
        { value: 'Online', label: 'Online' },
    ];

    const customerTypeOptions = [
        { value: '', label: 'Select Agents To' },
        { value: 'Retail', label: 'Retail' },
        { value: 'Site', label: 'Site' },
        { value: 'Design', label: 'Design' },
    ];
    const payable_month = [
        { value: 'January', label: 'January' },
        { value: 'February', label: 'February' },
        { value: 'March', label: 'March' },
        { value: 'April', label: 'April' },
        { value: 'May', label: 'May' },
        { value: 'June', label: 'June' },
        { value: 'July', label: 'July' },
        { value: 'August', label: 'August' },
        { value: 'September', label: 'September' },
        { value: 'October', label: 'October' },
        { value: 'November', label: 'November' },
        { value: 'December', label: 'December' }
    ];

    return (
        <>
            <section className="takeorder_section">
                <div className="page_design">
                    {/* <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Add Expenditure Details</h4>
                            </div>
                        </div>
                    </div> */}
                    <Card>
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} sm={6}>
                                        <Form.Group >
                                            <div className="d-flex justify-content-between">
                                                <Form.Label >Expenditure Type</Form.Label>
                                                <Button
                                                    className='btn-info btn-sm ml-1 mb-3'
                                                    onClick={() => setModalState({ ...modalState, addExpenditureType: true })}
                                                    variant="primary"
                                                >
                                                    Add
                                                </Button>
                                                <Button
                                                    className='btn-red btn-sm mb-3'
                                                    onClick={() => setModalState({ ...modalState, category_design_edit: true })}
                                                    variant="primary"
                                                >
                                                    Edit
                                                </Button>
                                            </div>
                                            <Form.Select

                                                value={fields.expenditure_id}
                                                onChange={(e) => handleChange(e.target.value, 'expenditure_id')}
                                            >
                                                <option value="">Select</option>
                                                {custumerlist.map((vendor) => (
                                                    <option key={vendor.id} value={vendor.id}>
                                                        {vendor.expenditure_type_name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    {fields.expenditure_id === '1' && (
                                        <>
                                            <Col md={6} lg={6} xs={3}>
                                                <Form.Group controlId="employee_id">
                                                    <Form.Label>Employee Name</Form.Label>
                                                    <Select
                                                        value={employelist.find(option => option.value === fields.employee_id)}
                                                        onChange={(selected) => handleSelectChange('employee_id', selected)}
                                                        options={employelist.map(employee => ({
                                                            value: employee.id,
                                                            label: employee.emp_name
                                                        }))}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col md={6} lg={6} xs={3}>
                                                <Form.Group controlId="received_by">
                                                    <Form.Label>Received By</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="received_by"
                                                        placeholder="Received By"
                                                        value={fields.received_by}
                                                        onChange={(e) => handleChange(e.target.value, 'received_by')}
                                                    />
                                                </Form.Group>
                                            </Col>



                                            <Col md={6} lg={6} xs={3}>
                                                <Form.Group controlId="employee_id">
                                                    <Form.Label>Payable Month</Form.Label>
                                                    <Select
                                                        value={payable_month.find(option => option.value === fields.payable_month)}
                                                        onChange={(selected) => handleSelectChange('payable_month', selected)}
                                                        options={payable_month}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>
                                    )}
                                    {fields.expenditure_id === '2' && (
                                        <>
                                            <Col md={6} sm={6}>
                                                <Form.Group >
                                                    <div className="d-flex justify-content-between">
                                                        <Form.Label >Expenditure Category</Form.Label>
                                                        <Button
                                                            className='btn-info btn-sm mb-3'
                                                            onClick={() => setModalState({ ...modalState, category_expenditure: true })}
                                                            variant="primary"
                                                        >
                                                            Add
                                                        </Button>
                                                        <Button
                                                            className='btn-red btn-sm mb-3'
                                                            onClick={() => setModalState({ ...modalState, category_expenditure_edit: true })}
                                                            variant="primary"
                                                        >
                                                            Edit
                                                        </Button>
                                                    </div>
                                                    <Form.Select

                                                        value={fields.expenditure_category_id}
                                                        onChange={(e) => handleChange(e.target.value, 'expenditure_category_id')}
                                                    >
                                                        <option value="">Select</option>
                                                        {category_list.map((vendor) => (
                                                            <option key={vendor.id} value={vendor.id}>
                                                                {vendor.expenditure_category_name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6} xs={3}>
                                                <Form.Group controlId="exp_details">
                                                    <Form.Label>Expenditure Details</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="exp_details"
                                                        placeholder="Expenditure Details"
                                                        value={fields.exp_details}
                                                        onChange={(e) => handleChange(e.target.value, 'exp_details')}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6} xs={3}>
                                                <Form.Group controlId="customer_type">
                                                    <Form.Label>Agents To</Form.Label>
                                                    <Select
                                                        value={customerTypeOptions.find(option => option.value === fields.customer_type)}
                                                        onChange={(selected) => handleSelectChange('customer_type', selected)}
                                                        options={customerTypeOptions}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6} xs={3}>
                                                <Form.Group controlId="customer_id">
                                                    <Form.Label>Customer Name</Form.Label>
                                                    <Select
                                                        value={filteredCustomerList.find(option => option.value === fields.customer_id)}
                                                        onChange={(selected) => handleSelectChange('customer_id', selected)}
                                                        options={filteredCustomerList.map(employee => ({
                                                            value: employee.id,
                                                            label: employee.customer_name
                                                        }))}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>
                                    )}
                                    <Col md={6} lg={6} xs={3}>
                                        <Form.Group controlId="amount">
                                            <Form.Label>Amount</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="amount"
                                                placeholder="Amount"
                                                value={fields.amount ? new Intl.NumberFormat().format(fields.amount) : ''}
                                                onChange={(e) => {
                                                    let value = e.target.value.replace(/,/g, '');
                                                    if (!isNaN(value) || value === "") {
                                                        handleChange(value, 'amount');
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>

                                    {(fields.expenditure_id !== '1' && fields.expenditure_id !== '2') && (
                                        <Col md={6} lg={6} xs={3}>
                                            <Form.Group controlId="name">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    value={fields.name}
                                                    onChange={(e) => handleChange(e.target.value, 'name')}
                                                />
                                            </Form.Group>
                                        </Col>
                                    )}
                                    <Col md={6} lg={6} xs={3}>
                                        <Form.Group controlId="date">
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="date"
                                                value={fields.date || new Date().toISOString().split("T")[0]}
                                                onFocus={(e) => e.target.showPicker()}
                                                onChange={(e) => handleChange(e.target.value, 'date')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} lg={6} xs={3}>
                                        <Form.Group controlId="account_mode">
                                            <Form.Label>Expenditure Mode</Form.Label>
                                            <Select
                                                value={accountModeOptions.find(option => option.value === fields.account_mode)}
                                                onChange={(selected) => handleSelectChange('account_mode', selected)}
                                                options={accountModeOptions}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} lg={6} xs={3}>
                                        <Form.Group controlId="account_id">
                                            <Form.Label>Select Account</Form.Label>
                                            <Select
                                                value={accountlist.find(option => option.value === fields.account_id)}
                                                onChange={(selected) => handleSelectChange('account_id', selected)}
                                                options={accountlist.map(account => ({
                                                    value: account.id,
                                                    label: account.account_name
                                                }))}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} xs={12} lg={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="description"
                                                placeholder="Description"
                                                value={fields.description}
                                                onChange={(e) => handleChange(e.target.value, 'description')}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="d-flex justify-content-end align-items-center w-95 mb-1">
                                <div className="d-flex gap-1">
                                    <Button
                                        className="btn takeorder"
                                        disabled={loading}
                                        onClick={handleSubmitOrder}
                                    >
                                        {loading ? 'Saving...' : 'Create'}
                                    </Button>
                                </div>
                            </div>
                        </Card.Body>

                    </Card>
                </div>
            </section>
            <Modal
                show={modalState.addExpenditureType}
                onHide={() => setModalState({ ...modalState, addExpenditureType: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">Add Expenditure Type</h3>
                </Modal.Header>
                <Modal.Body>
                    <Expenditure_Type onSave={() => setModalState({ ...modalState, addExpenditureType: false })} />
                </Modal.Body>
            </Modal>
            <Modal
                show={modalState.category_expenditure}
                onHide={() => setModalState({ ...modalState, category_expenditure: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">Add Expenditure Type</h3>
                </Modal.Header>
                <Modal.Body>
                    <Category_Expenditure onSave={() => setModalState({ ...modalState, category_expenditure: false })} />
                </Modal.Body>
            </Modal>
            <Modal
                show={modalState.category_design_edit}
                onHide={() => setModalState({ ...modalState, category_design_edit: false })}
                aria-labelledby="example-modal-sizes-title-lg" backdrop="static"
                keyboard={false}

            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">Edit Expenditure Type</h3>
                </Modal.Header>
                <Modal.Body>
                    <Expenditure_edit onSave={() => setModalState({ ...modalState, category_design_edit: false })} />
                </Modal.Body>
            </Modal>
            <Modal
                show={modalState.category_expenditure_edit}
                onHide={() => setModalState({ ...modalState, category_expenditure_edit: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">Edit Category Type</h3>
                </Modal.Header>
                <Modal.Body>
                    <Category_Expenditure_Edit onSave={() => setModalState({ ...modalState, category_expenditure_edit: false })} />
                </Modal.Body>
            </Modal>
            {/* <ToastContainer /> */}
        </>
    );
}