import React,{useMemo} from 'react'
import Vendorproductpayment from './Vendorreporttable';

export default function Vendorreportfile(){
    const title = "Balance History";
    const Vendordata = useMemo(
        () => {
          const getdataview = [];
          for (let i = 1; i <= 30; i++) {
            getdataview.push({
              srno: `${i}`,
              AccountNumber: `Account Name ${i}`,
              Type: `Type  ${i}`,
              Debit: `${Math.floor(Math.random() * 2000000)}`,
              Amount: `${Math.floor(Math.random() * 2000000)}`,
              AmountCredit: `${Math.floor(Math.random() * 2000000)}`,
              AmountDebit: `${Math.floor(Math.random() * 2000000)}`,
            });
          }
          return getdataview;
        },
        [] 
      );
    
      const Vendorproduct = useMemo(
        () => [
          {
            Header: 'Sr No',
            accessor: 'srno',
          },
          {
            Header: 'Account Number',
            accessor: 'AccountNumber',
          },
          {
            Header: 'Type',
            accessor: 'Type',
          },
          {
            Header: 'Amount',
            accessor: 'Amount',
          },
          {
            Header: 'Last Amount Credit',
            accessor: 'AmountCredit',
          },
          {
            Header: 'Last Amount Debit',
            accessor: 'AmountDebit',
          },
       
         
        ],
        []
      );
    
    return(
        <>

            <Vendorproductpayment title={title} columns={Vendorproduct} data={Vendordata} />
        </>
    )
}