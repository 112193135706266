import React from 'react'
import {Link} from 'react-router-dom'
import facebook from '../assets/img/facebook-logo.svg'
import applelogo from '../assets/img/apple-logo.svg'
import google from '../assets/img/google.png'
import user from '../assets/img/user-icon.svg'
import mail from '../assets/img/mail.svg'
const Register =  () =>{
    return(
        <>
        <div className="main-wrapper">
  <div className="account-content">
    <div className="login-wrapper register-wrap bg-img">
      <div className="login-content">
        <form action="signin.html">
          <div className="login-userset">
            <div className="login-logo logo-normal">
              <img src="assets/img/logo.png" alt="img" />
            </div>
            <Link to="index.html" className="login-logo logo-white">
              <img src="assets/img/logo-white.png" alt="" />
            </Link>
            <div className="login-userheading">
              <h3>Register</h3>
              <h4>Create New Dreamspos Account</h4>
            </div>
            <div className="form-login">
              <label>Name</label>
              <div className="form-addons">
                <input type="text" className="form-control" />
                <img src={user} alt="img" />
              </div>
            </div>
            <div className="form-login">
              <label>Email Address</label>
              <div className="form-addons">
                <input type="text" className="form-control" />
                <img src={mail} alt="img" />
              </div>
            </div>
            <div className="form-login">
              <label>Password</label>
              <div className="pass-group">
                <input type="password" className="pass-input" />
                <span className="fas toggle-password fa-eye-slash" />
              </div>
            </div>
            <div className="form-login">
              <label>Confirm Passworrd</label>
              <div className="pass-group">
                <input type="password" className="pass-inputs" />
                <span className="fas toggle-passwords fa-eye-slash" />
              </div>
            </div>
            <div className="form-login authentication-check">
              <div className="row">
                <div className="col-sm-8">
                  <div className="custom-control custom-checkbox justify-content-start">
                    <div className="custom-control custom-checkbox">
                      <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                        <input type="checkbox" />
                        <span className="checkmarks" />I agree to the
                        <Link to="#" className="hover-a">
                          Terms &amp; Privacy
                        </Link>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-login">
              <button type="submit" className="btn btn-login">
                Sign Up
              </button>
            </div>
            <div className="signinform">
              <h4>
                Already have an account ?
                <Link to="signin.html" className="hover-a">
                  Sign In Instead
                </Link>
              </h4>
            </div>
            <div className="form-setlogin or-text">
              <h4>OR</h4>
            </div>
            <div className="form-sociallink">
              <ul className="d-flex">
                <li>
                  <Link to="javascript:void(0);" className="facebook-logo">
                    <img
                      src={facebook}
                      alt="Facebook"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="javascript:void(0);">
                    <img src={google} alt="Google" />
                  </Link>
                </li>
                <li>
                  <Link to="javascript:void(0);" className="apple-logo">
                    <img src={applelogo} alt="Apple" />
                  </Link>
                </li>
              </ul>
            </div>
           
          </div>
        </form>
      </div>
    </div>
  </div>

</div>

        </>
    );
}
export default Register