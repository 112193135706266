import React, { useState, useEffect, useRef } from 'react';
import { Card, Table, Row, Col, Button, Modal } from 'react-bootstrap';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { FaArrowRightLong, FaPlus } from "react-icons/fa6";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { GrDocumentPdf } from "react-icons/gr";
import { RiDownloadLine } from "react-icons/ri";
import html2pdf from 'html2pdf.js';
import OrderConfirmation from './OrderBillSupplyPdf'
import OrderInvoicePdf from './OrderInvoicePdf'


const OrderProductPdfDownload = () => {
    const [Data, setViewData] = useState({});
    const [totalData, setTotalData] = useState({});
    const [loadingList, setLoadingList] = useState(false);
    const token = localStorage.getItem("token");
    const orderProductId = localStorage.getItem("orderproduct_id_pdf");
    const navigate = useNavigate();
    const [modalState, setModalState] = useState({ orderconfirmation: false, orderinvoicepdf: false });


    useEffect(() => {
        const fetchOrderData = async () => {
            setLoadingList(true);
            try {
                const url = `${process.env.REACT_APP_API_URL}Order-view-product-list/${orderProductId}`;
                const config = {
                    method: 'get',
                    url: url,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                };
                const response = await axios(config);
                const { createOrder, product, product_other_data } = response.data.rows;
                console.warn(product_other_data);

                // Assuming createOrder has only one item based on your example
                const orderDetails = createOrder[0];

                // setTotalData(orderDetails);
                setViewData({
                    ...orderDetails,
                    products: product,
                    otherData: product_other_data[0] || []
                });
                console.warn(Data);
            } catch (error) {
                console.error("There was a problem fetching data:", error);
            } finally {
                setLoadingList(false);
            }
        };

        fetchOrderData();
    }, [token]);

    const goBack = () => {
        navigate('/orderview');
    };

    const totalPrice = parseFloat(Data.total_price) || 0;
    const totalLabelCharge = parseFloat(Data.total_lable_charge) || 0;
    const totalamount = parseFloat(Data.amount) || 0;

    const total = totalPrice + totalLabelCharge;
    const totaldue = totalamount - total;

    return (
        <>
            <section className='orderview_section'>

                <div className="page-header">
                    <div className="add-item d-flex justify-content-between align-items-center w-100">
                        {/* <div className="page-title">
                            <h4>Product Details</h4>
                        </div> */}
                        <div className='d-flex justify-content-end w-100 gap-2'>
                            {/* {!buttonsDisabled && ( */}
                            <>

                                {/* <Button type="button" onClick={generatePDF}>pdf<GrDocumentPdf /> <RiDownloadLine /></Button> */}
                                {/* <Button type="button" className="btn-added" onClick={goBack}>   Back <FaArrowRightLong style={{ paddingRight: '3px' }} /></Button> */}
                            </>
                            {/* )} */}
                        </div>
                    </div>
                </div>

            </section>
            <div className='d-flex gap-2 justify-content-center'  >
                <Button type="button" className="btn-added d-flex gap-2" onClick={() => setModalState({ ...modalState, orderconfirmation: true })}>
                    <div className='pdfdesign'>
                        <GrDocumentPdf />
                    </div>
                    Order Confirmation
                </Button>
                <Button type="button" className="btn-added  d-flex gap-2" onClick={() => setModalState({ ...modalState, orderinvoicepdf: true })}> <div className='pdfdesign'> <GrDocumentPdf /> </div>Order Invoice</Button>


            </div>
            <Modal
                size="xl"
                show={modalState.orderconfirmation}
                onHide={() => setModalState({ ...modalState, orderconfirmation: false })}
                backdrop="static" 
          keyboard={false}
            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">Order Confirmation Pdf</h3>
                </Modal.Header>

                <Modal.Body closeButton>
                    <OrderConfirmation onSave={() => setModalState({ ...modalState, orderconfirmation: false })} />
                </Modal.Body>
            </Modal>
            <Modal
                size="xl"
                show={modalState.orderinvoicepdf}
                onHide={() => setModalState({ ...modalState, orderinvoicepdf: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static" 
          keyboard={false}
            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">Order Invoice Pdf</h3>
                </Modal.Header>

                <Modal.Body closeButton>
                    <OrderInvoicePdf onSave={() => setModalState({ ...modalState, orderinvoicepdf: false })} />
                </Modal.Body>
            </Modal>

        </>
    );
}

export default OrderProductPdfDownload;
