import React, { useState, useMemo, useEffect } from 'react';
import { Card, Modal, Button } from 'react-bootstrap';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdKeyboardArrowRight, MdKeyboardArrowLeft, MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { RiUser2Fill } from "react-icons/ri";
import AddEmployee from './AddAccount';
import EmployeeUpdate from './AccountUpdate';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Swal from 'sweetalert2';
import AmountTransfer  from './AmountTransefer'
import RefundAmount from './RefundAmount';
import { useNavigate } from 'react-router-dom';


const Accountlist = () => {
  const [show, setShow] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [addProductModal, setAddProductModal] = useState(false);
  const [addEmployeeEditModal, setAddEmployeeEditModal] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const token = localStorage.getItem('token');
  const [modalState, setModalState] = useState({ amountTransfer: false, RefundAmount:false });
  const navigate = useNavigate();


  useEffect(() => {
    if(!modalState.amountTransfer, !modalState.RefundAmount){
    fetchEmployeeList();
  }
  }, [addProductModal, addEmployeeEditModal, modalState.amountTransfer, modalState.RefundAmount]);

  const fetchEmployeeList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}Account-list`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      setEmployeeList(response.data.data || []);
      // setTotalEmployees(response.data.data.length);
    } catch (error) {
      console.error('Error fetching employee list:', error);
    }
  };



  const handleEditEmployee = (id) => {
    setAddEmployeeEditModal(true);
    localStorage.setItem('employee_id', id);
  };
  const handleLadger = (id) => {
    localStorage.setItem('account_ladger_id', id);
    navigate('/Account-ladger');
  };

  const data = useMemo(
    () => employeeList && employeeList.map((employee, index) => ({
      srnumber: index + 1,
      account_name: employee.account_name,
      account_number: employee.account_number,
      bank_name: employee.bank_name,
      mobile_number: employee.mobile_number,
      account_type: employee.account_type,
      total_amount: employee.total_amount,
      expenditure: employee.total_expenditure_amount,
      balance: employee.total_amount - employee.total_expenditure_amount,
      Action: (
        <div className='edit-delete-action d-flex gap-2'>
          <div className="me-2 p-2 editbutton" onClick={() => handleEditEmployee(employee.id)}>
            <FaEdit />
          </div>
          <div className="me-2 p-2 btn btn-primary" onClick={() => handleLadger(employee.id)}>
           Ladger
          </div>
          {/* Uncomment if you want to use the delete button */}
          {/* <div className="me-2 p-2 deletebtn" onClick={() => handleDeleteEmployee(employee.id)}>
            <MdDelete />
          </div> */}
        </div>
      ),
    })),
    [employeeList]
  );

  const columns = useMemo(
    () => [
      { Header: 'Sr.No', accessor: 'srnumber' },
      { Header: 'Account Name', accessor: 'account_name' },
      { Header: 'A/c Name', accessor: 'account_number' },
      { Header: 'Bank Name', accessor: 'bank_name' },
      { Header: 'Mobile Number', accessor: 'mobile_number' },
      { Header: 'Account Type', accessor: 'account_type' },
      { Header: 'Total', accessor: 'total_amount' , Cell: ({ value }) => (
        value !== undefined ? (
          new Intl.NumberFormat().format(value)
        ) : null
      ), }, 
      { Header: 'Total Payment', accessor: 'expenditure', Cell: ({ value }) => (
        value !== undefined ? (
          new Intl.NumberFormat().format(value)
        ) : null
      ), }, 
      { Header: 'Balance', accessor: 'balance', Cell: ({ value }) => (
        value !== undefined ? (
          new Intl.NumberFormat().format(value)
        ) : null
      ), }, 
      { Header: 'Action', accessor: 'Action' },
    ],
    []
  );




  const GlobalFilter = ({ filter, setFilter }) => (
    <input
      value={filter || ''}
      onChange={(e) => setFilter(e.target.value)}
      placeholder="Search..."
      className="form-control"
    />
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <>
      <ToastContainer />
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Account list</h5>
            <div className="d-flex gap-3">
              <Button variant="primary" onClick={() => setModalState({ ...modalState, amountTransfer: true })}>A/c To A/c Transfer</Button>
              <Button variant="primary" onClick={() => setModalState({ ...modalState, RefundAmount: true })}>Refund Amount</Button>
              <Button variant="primary" onClick={() => setAddProductModal(true)}>Add Account</Button>
              <select
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                className="form-select d-inline"
                style={{ width: "120px" }}
              >
                {[10, 20, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </select>
              <div style={{ width: '220px' }}>
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <table {...getTableProps()} className="table datanew">
              <thead style={{ backgroundColor: '#f2f2f2' }}>
                {headerGroups.map((headerGroup) => (
                  <tr className="thead-dark" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              Page <strong>{pageIndex + 1} of {pageOptions.length}</strong>
            </div>
            <div className="pagination justify-content-center mt-3">
              <Button variant="link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <MdKeyboardDoubleArrowLeft />
              </Button>
              <Button variant="link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                <MdKeyboardArrowLeft />
              </Button>
              <div>
                <h6 className="mb-0 pagination_count d-flex justify-content-center align-items-center">{pageIndex + 1}</h6>
              </div>
              <Button variant="link" onClick={() => nextPage()} disabled={!canNextPage}>
                <MdKeyboardArrowRight />
              </Button>
              <Button variant="link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <MdKeyboardDoubleArrowRight />
              </Button>
            </div>
          </div>
        </Card.Body>

        <Modal
          size="lg"
          show={addProductModal}
          onHide={() => setAddProductModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <h4 className="card-title mb-0">Add Account</h4>
          </Modal.Header>
          <Modal.Body>
            <AddEmployee onSave={() => setAddProductModal(false)} />
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          show={addEmployeeEditModal}
          onHide={() => setAddEmployeeEditModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Body>
            <EmployeeUpdate onSave={() => setAddEmployeeEditModal(false)} />
          </Modal.Body>
        </Modal>
      </Card>
      <Modal
        show={modalState.amountTransfer}
        size='lg'
        onHide={() => setModalState({ ...modalState, amountTransfer: false })}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <h3 className="card-title mb-0">Amount Transfer</h3>
        </Modal.Header>
        <Modal.Body>
          <AmountTransfer onSave={() => setModalState({ ...modalState, amountTransfer: false })} />
        </Modal.Body>
      </Modal>

      <Modal
        show={modalState.RefundAmount}
        size='lg'
        onHide={() => setModalState({ ...modalState, RefundAmount: false })}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <h3 className="card-title mb-0">Refund Amount</h3>
        </Modal.Header>
        <Modal.Body>
          <RefundAmount onSave={() => setModalState({ ...modalState, RefundAmount: false })} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Accountlist;
