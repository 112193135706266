import React, { useState, useEffect } from 'react';
import { Button,Modal } from 'react-bootstrap';
import { FaEye } from "react-icons/fa";
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Vendors_Payment from './MaterialRecriveView';

const Ven_Ladger_Material_Details_List = () => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [data, setVendorDataDetails] = useState([]);
    const ven_ladger_id = localStorage.getItem('ven_ladger_id');
    const ven_costomer_id = localStorage.getItem('ven_det_costomer_id');
    const [modalState, setModalState] = useState({ vendor_view: false });

    useEffect(() => {
        fetchVendorDetails();
    }, [token]);

    const fetchVendorDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Vendor-Materials-ladger-list/${ven_ladger_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            const otherData = response.data.data || [];
            setVendorDataDetails(otherData);
        } catch (error) {
            console.error('Error fetching vendors:', error);
        }
    };
    const handleViewDetails = (costomer_id) => {
        localStorage.setItem('vendor_view_id', costomer_id);
        setModalState({ ...modalState, vendor_view: true })

        // navigate('/vendor-ladgor-Details');
    };
    return (
        <>
            <div className="App">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex">

                            <div><Link to='/vendor-ladgor' className='btn-added'>Back</Link></div>
                        </div>
                    </div>

                    <div className="card-body">
                        <table className='table table-striped hover border'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Sr.no</th>
                                    <th>Date</th>
                                    <th>Customer Name</th>
                                    <th>Vendor Name</th>
                                    <th>Gst</th>
                                    <th>Total Amount</th>
                                    <th>Action</th>
                                    {/* <th>total_amount</th> */}

                                </tr>
                            </thead>
                            <tbody className='trcolorchange'>
                                {data && data.map((vendor, index) => (
                                    <tr key={vendor.vendor_id} onClick={() => handleViewDetails(vendor.id)}>
                                        <td>{index + 1}</td>
                                        <td>{vendor.date}</td>
                                        <td>{vendor.customer_name}</td>
                                        <td>{vendor.vendor_name}</td>
                                        <td>{vendor.gst}</td>
                                        <td>{vendor.total_cost}</td>
                                        {/* <td>{vendor.total_amount}</td> */}
                                        <td><Button variant="secondary" onClick={() => handleViewDetails(vendor.id)} className="ms-2"><FaEye /></Button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>


                    </div>
                </div>
            </div>
            <Modal
                size='xl'
                show={modalState.vendor_view}
                onHide={() => setModalState({ ...modalState, vendor_view: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">vendor view</h3>
                </Modal.Header>
                <Modal.Body>
                    <Vendors_Payment onSave={() => setModalState({ ...modalState, vendor_view: false })} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Ven_Ladger_Material_Details_List;
