import React, { useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import facebook from '../assets/img/facebook-logo.svg';
import applelogo from '../assets/img/apple-logo.svg';
import google from '../assets/img/google.png';
import mail from '../assets/img/mail.svg';
import logo from '../assets/img/logo.png';
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { EyeSlash, EyeFill, ExclamationCircle } from "react-bootstrap-icons";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const onInputChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailError(false); 
  };

  const onInputChangePassword = (e) => {
    setPassword(e.target.value);
    setPasswordError(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const Submit = async (e) => {
    e.preventDefault();

    setLoading(true);

    let hasError = false;
    if (!email) {
      setEmailError(true);
      hasError = true;
    }
    if (!password) {
      setPasswordError(true);
      hasError = true;
    }
    if (hasError) {
      setLoading(false);
      return;
    }

    try {
      let url = `${process.env.REACT_APP_API_URL}login`;

      const requestData = {
        email: email,
        password: password,

      };

      const config = {
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/json',
        },
        data: requestData,
      };

      const response = await axios(config);

      if (response.data.status === 1) {
        toast.success(response.data.message);
        navigate('/home');
        localStorage.setItem("userid", response.data.userid);
        localStorage.setItem("token", response.data.token);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper bg-img">
          <div className="login-content">
            {/* <Form onSubmit={Submit}> */}
            <div className="login-userset">
              <div className="login-logo logo-normal">
                <img src={logo} alt="img" />
              </div>
              <Link to="#" className="login-logo logo-white">
                <img src={logo} alt="" />
              </Link>
              <div className="login-userheading">
                <h3>Sign In</h3>
                <h4>Access the Dreamspos panel using your email and passcode.</h4>
              </div>
              <div className="form-login mb-3">
                <label className="form-label">Email Address</label>
                <div className="form-addons position-relative">
                  <input
                    type="text"
                    className={`form-control ${emailError ? 'is-invalid' : ''}`}
                    value={email}
                    onChange={onInputChangeEmail}
                  />
                  {emailError ? (
                    ''
                  ) : <img src={mail} alt="img" />}

                </div>
              </div>

              <div className="form-login mb-3">
                <label className="form-label">Password</label>
                <div className="pass-group position-relative">
                  <input
                    className={`pass-input form-control ${passwordError ? 'is-invalid' : ''}`}
                    value={password}
                    onChange={onInputChangePassword}
                    type={showPassword ? "text" : "password"}
                  />

                  <div className='d-flex justify-content-end toggleeyebtn'>
                    <div
                      className='toggleeye'
                      onClick={togglePasswordVisibility}
                    >
                      {passwordError ? (
                        ''
                      ) : (
                        showPassword ? (
                          <EyeSlash className="eye" />
                        ) : (
                          <EyeFill className="eye" />
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
{/* 
              <div className='d-flex justify-content-end'>
                <Link to="forgetpassword">Forgot Password?</Link>
                </div> */}

              <div className="form-login">
                <Link type="submit" onClick={Submit} className="btn btn-login" disabled={loading}>
                  {loading ? <Spinner animation="border" size="sm" /> : 'Sign In'}
                </Link>
              </div>
            </div>
            {/* </Form> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
