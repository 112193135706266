import React, { useState, useMemo, useEffect } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap'
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
export default function Leadmangement() {


    const initialFields = {
        Name: '',
        MobileNumber: '',
        Address: '',
        SiteName: '',
        Budget: '',
        LeadAddress: '',
        Date: '',
        EstDate: '',
        Message: '',
    };
    const token = localStorage.getItem('token');
    const [fields, setFields] = useState(initialFields);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    };
    const validateFields = () => {
        const errors = {};
        if (!fields.Name) errors.Name = 'Name is required';
        if (!fields.MobileNumber) errors.MobileNumber = 'Mobile Number is required';
        if (!fields.Address) errors.Address = 'Address is required';
        if (!fields.SiteName) errors.SiteName = 'Site Name is required';
        if (!fields.Budget) errors.Budget = 'Budget is required';
        if (!fields.LeadAddress) errors.LeadAddress = 'Lead Address is required';
        if (!fields.Date) errors.Date = 'Date is required';
        if (!fields.EstDate) errors.EstDate = 'Est Date is required';
        // if (!fields.Message) errors.Message = 'Message is required';

        return errors;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateFields();
        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }
        setErrors({});
        setLoading(true);
  
        const payload = {
            name: fields.Name,
            mobile_number: fields.MobileNumber,
            address: fields.Address,
            sitename: fields.SiteName,
            budget: fields.Budget,
            leadaddress: fields.LeadAddress,
            date: fields.Date,
            est_date: fields.EstDate,
            message: fields.Message,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Lead-Details-store`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setFields(initialFields);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>
                        Lead Management
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <h5>Customer Details</h5>
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Enter Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Name"
                                    value={fields.Name}
                                    onChange={handleInputChange}
                                    style={{ borderColor: errors.Name ? 'red' : '' }}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="MobileNumber"
                                    value={fields.MobileNumber}
                                    onChange={handleInputChange}
                                    style={{ borderColor: errors.MobileNumber ? 'red' : '' }}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Address"
                                    value={fields.Address}
                                    onChange={handleInputChange}
                                    style={{ borderColor: errors.Address ? 'red' : '' }}
                                ></Form.Control>
                            </Form.Group>
                        </Col>

                    </Row>
                    <hr />
                    <h5>Lead Details</h5>
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Enter Site Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="SiteName"
                                    value={fields.SiteName}
                                    onChange={handleInputChange}
                                    style={{ borderColor: errors.SiteName ? 'red' : '' }}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Budget</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="Budget"
                                    value={fields.Budget}
                                    onChange={handleInputChange}
                                    style={{ borderColor: errors.Budget ? 'red' : '' }}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="LeadAddress"
                                    value={fields.LeadAddress}
                                    onChange={handleInputChange}
                                    style={{ borderColor: errors.LeadAddress ? 'red' : '' }}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="Date"
                                    value={fields.Date}
                                    onChange={handleInputChange}
                                    style={{ borderColor: errors.Date ? 'red' : '' }}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Est Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="EstDate"
                                    value={fields.EstDate}
                                    onChange={handleInputChange}
                                    style={{ borderColor: errors.EstDate ? 'red' : '' }}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group>
                                <Form.Label>Requirements & Message</Form.Label>

                                <Form.Control as="textarea"
                                    placeholder="Requirements & Message "
                                    rows={3}
                                    name="Message"
                                    value={fields.Message}
                                    onChange={handleInputChange}
                                    // style={{ borderColor: errors.Message ? 'red' : '' }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12} className="d-flex justify-content-end align-items-end">
                            <Form.Group>
                                <div className="d-flex mt-2 justify-content-end align-items-end">
                                    <button className="btn-added d-flex gap-1" disabled={loading} onClick={handleSubmit}>
                                        {loading ? 'Submitting...' : 'Create'}
                                    </button>
                                </div>
                            </Form.Group>
                        </Col>

                    </Row>
                </Card.Body>
            </Card>
            <ToastContainer/>
        </>
    )
} 