import React, { useState, useEffect, useMemo } from 'react';
import { Container,Card, Row, Col, Form, Button, Table } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { MdAddCircle } from 'react-icons/md';
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { FaArrowLeftLong, FaPlus } from "react-icons/fa6";
import { toast, ToastContainer } from 'react-toastify';
import Stocktable from './Stocktable';
import DataTable from './Stocktable';

export default function Addmaterial({ onSave }) {
    const token = localStorage.getItem('token');
    const met_receive = localStorage.getItem('met_receive');
    const [materials, setMaterial] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');

       const columns = useMemo(
        () => [
            {
                Header: 'Customer Name',
                accessor: 'customer_name',
            },
            {
                Header: 'Total Paid',
                accessor: 'total_amount',
            },
            {
                Header: 'Total Receive',
                accessor: 'amount',
            },
            {
                Header: 'Total Due',
                accessor: 'due_amt',
                Cell: ({ row }) => {
                    const { total_amount, amount, due_amt } = row.original;
                    return (
                        <span
                            style={{
                                color: due_amt >= total_amount || due_amt <= amount ? 'green' : 'red'
                            }}
                        >
                            {due_amt}
                        </span>
                    );
                }
            },
        ],
        []
    );



    useEffect(() => {
        fetchProducts()

    }, [])
    const fetchProducts = async (coustomer_id) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}Customer-payment-list`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            const { id, vendor_id, customer_id, materials } = response.data.data;
            console.warn('API Response:', response.data.data.materials);
            setMaterial(response.data.data || [])
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const GlobalFilter = ({ filter, setFilter }) => {
        return (
            <input
                value={filter || ''}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Search..."
                className="form-control"
            />
        );
    };

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value); // No need to parseInt, keep it as a string
      };

      const filteredOrderList = selectedStatus ? materials.filter(item => item.customer_type === selectedStatus) : materials;
 

    return (
        <>
           <Card>
          <Container fluid>
           
                <Row className="p-2">
                    <Col md={6} className='d-flex justify-content-start'><h3>Order Recieve View</h3></Col>
                    <Col md={6} className='d-flex justify-content-end'><Link to="/orderview"><Button variant="dark">Back</Button></Link> </Col>
                </Row>
               
            </Container>
            </Card>
            <div className='justify-content-center processing'>
        <div className='text-center'>
          <Row>
            <Col md={12} xs={12} lg={12} className='duedrop'>
              <Form.Select aria-label="Default select example" onChange={handleStatusChange} value={selectedStatus} style={{ border: '1px solid #8080802' }}>
                <option value=''>Customer Type</option>
                <option value="Retail">Retail</option>
                <option value="Site">Site</option>
                <option value="Design">Design</option>
              </Form.Select>
            </Col>
          </Row>
        </div>
      </div>

            <DataTable columns={columns} data={filteredOrderList} />
            
          
            {/* </Card.Body> */}
            {/* </Card> */}
        </>
    );
}
