import React, { useState, useEffect } from 'react';
import { FaSearch, FaPlus, FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Button, Form, Modal } from 'react-bootstrap';
import $ from 'jquery';
import 'select2';
import { Link } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Swal from 'sweetalert2';
import nodata from '../assets/img/nodata.svg'

export default function Attribute() {
    const [showEditModal, setShowEditModal] = useState(false);
    const [data, setData] = useState([]);
    const [loadinglist, setLoadinglist] = useState(false);
    const [editname, setEditname] = useState('');
    const [editnameError, setEditNameError] = useState(false);
    const [currentEditId, setCurrentEditId] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const token = localStorage.getItem("token");

    const handleCloseEditModal = () => setShowEditModal(false);
    const handleShowEditModal = () => setShowEditModal(true);
    const onInputChangeEditName = (e) => {
        setEditname(e.target.value);
        setEditNameError(false);
    };

    useEffect(() => {
        Attributelist();
    }, []);


    const Attributelist = async () => {
        setLoadinglist(true);
        try {
            let url = `${process.env.REACT_APP_API_URL}attribute-list`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            setData(response.data.data  || []);

            // Calculate total pages based on the fetched data
            setTotalPages(Math.ceil(response.data.data.length / itemsPerPage));
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setLoadinglist(false);
        }
    };


    const AttributeEdit = async (id) => {
        setCurrentEditId(id);
        handleShowEditModal();
        setLoadinglist(true);
        try {
            let url = `${process.env.REACT_APP_API_URL}attribute-edit/${id}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            setEditname(response.data.data[0].name);
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setLoadinglist(false);
        }
    };

    const AttributeDelete = async (id) => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });
            if (result.isConfirmed) {
                let url = `${process.env.REACT_APP_API_URL}attribute-delete/${id}`;
                const config = {
                    method: 'get',
                    url: url,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                };
                const response = await axios(config);
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    Attributelist();
                } else {
                    toast.error(response.data.message);
                }
            }
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setLoadinglist(false);
        }
    };

    const AttributeUpdate = async () => {
        if (!editname) {
            setEditNameError(true);
            return;
        }
        try {
            let url = `${process.env.REACT_APP_API_URL}attribute-update`;
            const requestData = { name: editname, id: currentEditId };
            const config = {
                method: 'post',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
                data: requestData,
            };
            const response = await axios(config);
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setEditname('');
                setCurrentEditId(null);
                Attributelist();
                handleCloseEditModal();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        }
    };

    useEffect(() => {
        $('#mySelect2').select2();
        return () => {
            $('#mySelect2').select2('destroy');
        };
    }, []);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        const selectedItemsPerPage = parseInt(e.target.value);
        setItemsPerPage(selectedItemsPerPage);

        // Recalculate total pages based on the new items per page value
        setTotalPages(Math.ceil(filteredData.length / selectedItemsPerPage));
    };

    const filteredData = data ? data.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.category_name.toLowerCase().includes(searchQuery.toLowerCase())
    ) : [];



    const paginatedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const renderPagination = () => {
        const pageNumbers = [];
        const totalPages = Math.ceil(filteredData.length / itemsPerPage);
        const maxPageButtons = 10;
        const ellipsis = '...';

        let startPage, endPage;

        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= Math.floor(maxPageButtons / 2)) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + Math.floor(maxPageButtons / 2) >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - Math.floor(maxPageButtons / 2);
                endPage = currentPage + Math.floor(maxPageButtons / 2);
            }
        }

        // Adjust startPage and endPage if totalPages < maxPageButtons
        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= Math.floor(maxPageButtons / 2)) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + Math.floor(maxPageButtons / 2) >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - Math.floor(maxPageButtons / 2);
                endPage = currentPage + Math.floor(maxPageButtons / 2);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <>
                        <button onClick={() => handlePageChange(1)}>{1}</button>
                        {startPage > 2 && <span>{ellipsis}</span>}
                    </>
                )}
                {pageNumbers.map(number => (
                    <button key={number} onClick={() => handlePageChange(number)} className={number === currentPage ? 'active' : ''}>
                        {number}
                    </button>
                ))}
                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && <span>{ellipsis}</span>}
                        <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                    </>
                )}
            </div>
        );
    };


    return (
        <div className="Category">
            <div className="page-header">
                <div className="add-item d-flex justify-content-between">
                    <div className="page-title">
                        <h4>Attribute Mangement</h4>
                        <h6>Manage Your Attribute Mangement</h6>
                    </div>
                </div>
                <div className="button_add_row">
                    <Link to="/addattribute" className="btn btn-added d-flex gap-2">
                        <FaPlus /> Add Attribute
                    </Link>
                </div>
            </div>
            <div className="card table-list-card">
                <div className="card-header d-flex justify-content-between">
                    <div className="Category">
                        {/* Existing JSX code */}
                        <div>
                            <select onChange={handleItemsPerPageChange} className='form-select' value={itemsPerPage}>
                                <option value={10}>Items 10</option>
                                <option value={20}>Items 20</option>
                                <option value={30}>Items 30</option>
                                <option value={50}>Items 50</option>
                            </select>
                        </div>
                        {/* Existing JSX code */}
                    </div>
                    <div className="searchinputs d-flex">
                        <input type="text" placeholder="Search" value={searchQuery} onChange={handleSearch} />
                        <div className="btn d-flex align-items-center justify-content-center">
                            <FaSearch />
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table-striped bordered hover table">
                            <thead>
                                <tr className="thead-dark">
                                    <th>Sr Number</th>
                                    <th>Name</th>
                                    <th>Category Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {loadinglist ? (
                                    <tr>
                                        <td colSpan="4" className='text-center'>
                                            <div>Loading...</div>
                                        </td>
                                    </tr>
                                ) : (
                                    paginatedData && paginatedData.length > 0 ? (
                                        paginatedData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>{item.category_name}</td>
                                                <td className="edit-delete-action justify-content-start d-flex gap-2">
                                                    <Link to="#" style={{ "--i": "#233882" }} className="p-2" onClick={() => AttributeEdit(item.id)}><FaEdit /></Link>
                                                    <Link to="#" style={{ "--i": "red" }} className="p-2" onClick={() => AttributeDelete(item.id)}><MdDelete /></Link>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4" className='text-center'>
                                                <img src={nodata} alt="nodata" width={250} />
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td colSpan={3}>
                                        <span>Pages: <strong>{totalPages} </strong> </span>

                                        <span>Of <strong>{itemsPerPage}</strong></span>
                                    </td>
                                    <td className='d-flex justify-content-end'>
                                        {renderPagination()}

                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

            <Modal show={showEditModal} onHide={handleCloseEditModal} backdrop="static" 
          keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title><h4>Edit Attribute</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Enter Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Attribute Name"
                                value={editname}
                                onChange={onInputChangeEditName}
                                className={editnameError ? 'is-invalid' : ''}
                            />
                            {editnameError && <Form.Control.Feedback type="invalid">Please enter a attribute name.</Form.Control.Feedback>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end">
                    <div>
                        <Button onClick={AttributeUpdate} variant="primary" className="btn btn-submit">
                            Update
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </div>
    );
}

