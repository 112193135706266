import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

export default function Addrowmaterial() {
    const [adding, setAdding] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [data, setData] = useState([]);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [unit_type, setUnit] = useState('');
    const [unitError, setUnitError] = useState(false);
    const [atName, setAtName] = useState('');
    const [atNameError, setAtNameError] = useState(false);
    const [CatNameError, setCatNameError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedCategoryId, setCategoryId] = useState('');
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);

    const onInputChange = (setter, errorSetter) => (e) => {
        setter(e.target.value);
        errorSetter(false);
    };

    const validateFields = (fields) => {
        let isValid = true;
        fields.forEach(({ value, errorSetter }) => {
            if (!value) {
                errorSetter(true);
                isValid = false;
            }
        });
        return isValid;
    };

    const handleSubmit = async (url, requestData, successCallback) => {
        setLoading(true);
        try {
            const response = await axios.post(url, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            if (response.data.status === 1) {
                toast.success(response.data.message);
                successCallback();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitCategory = () => {
        if (validateFields([{ value: name, errorSetter: setNameError }])) {
            handleSubmit(
                `${process.env.REACT_APP_API_URL}category-store`,
                { name },
                () => {
                    setName('');
                    fetchCategories();
                    handleCloseAddModal();
                }
            );
        } else {
            setLoading(false);
        }
    };

    const handleSubmitAttribute = () => {
        if (validateFields([
            { value: atName, errorSetter: setAtNameError },
            { value: unit_type, errorSetter: setUnitError },
            { value: selectedCategoryId, errorSetter: setCatNameError }
        ])) {
            handleSubmit(
                `${process.env.REACT_APP_API_URL}RawMaterial-store`,
                { name: atName,unit_type: unit_type, category_id: parseInt(selectedCategoryId) },
                () => {
                    setAtName('');
                    setCategoryId('');
                    setUnit('');
                    setTimeout(() => navigate('/rawmatrial'), 1000);
                }
            );
        } else {
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}category-list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setData(response.data.data || []);
        } catch (error) {
            console.error('Error fetching categories:', error);
            toast.error('An error occurred while fetching categories');
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <div>
            <Container fluid>
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex align-items-center justify-content-between">
                            <h3 className="card-title mb-0">Add Raw Material</h3>
                            <Button className="btn btn-added d-flex gap-2 align-items-center" onClick={handleShowAddModal}>
                                <FaPlus /> Add Category
                            </Button>
                        </div>
                    </div>

                    <div className="card-body">
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Enter Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Name"
                                        value={atName}
                                        onChange={onInputChange(setAtName, setAtNameError)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleSubmitAttribute(); 
                                            }
                                        }}
                                        className={atNameError ? 'is-invalid' : ''}
                                    />
                                    {/* {atNameError && <Form.Control.Feedback type="invalid">Please enter raw material name.</Form.Control.Feedback>} */}
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Select Category</Form.Label>
                                    <Form.Select
                                        value={selectedCategoryId}
                                        onChange={onInputChange(setCategoryId, setCatNameError)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleSubmitAttribute(); 
                                            }
                                        }}
                                        className={CatNameError ? 'is-invalid' : ''}
                                    >
                                        <option value="">Select Category Name</option>
                                        {data.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {/* {CatNameError && <Form.Control.Feedback type="invalid">Please select a category.</Form.Control.Feedback>} */}
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Unit Type</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Unit Type"
                                        value={unit_type}
                                        onChange={onInputChange(setUnit, setUnitError)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleSubmitAttribute(); 
                                            }
                                        }}
                                        className={unitError ? 'is-invalid' : ''}

                                    />
                                </Form.Group>
                                {/* {unitError && <Form.Control.Feedback type="invalid">Please select a Unit Type.</Form.Control.Feedback>} */}

                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end">
                            <button className="btn bg-primary text-white" onClick={handleSubmitAttribute}>Create</button>
                        </div>
                    </div>
                </div>
            </Container>

            <Modal show={showAddModal} onHide={handleCloseAddModal} backdrop="static" 
          keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Enter Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Category Name"
                                        value={name}
                                        onChange={onInputChange(setName, setNameError)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleSubmitCategory(); 
                                            }
                                        }}
                                        className={nameError ? 'is-invalid' : ''}
                                    />
                                    {nameError && <Form.Control.Feedback type="invalid">Please enter category name.</Form.Control.Feedback>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmitCategory} disabled={loading}>
                        {loading ? 'Adding...' : 'Create'}
                    </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer />
        </div>
    );
}
