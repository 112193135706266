import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaEye } from "react-icons/fa";
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

const StockManagement = () => {
    const token = localStorage.getItem('token');
    const ven_ladger_id = localStorage.getItem('ven_ladger_id');
    const navigate = useNavigate();
    const [companyDetails, setCompanyDetails] = useState({});
    const [data, setVendorData] = useState([]);
    const [data1, setVendorDataother] = useState([]);
    const [dataDetails, setVendorDataDetails] = useState([]);

    useEffect(() => {
        fetchVendor();
        fetchVendorDetails();
    }, [token]);

    const fetchVendor = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Vendor-ladger-list/${ven_ladger_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            const otherData = response.data.data || [];
            const otherData1 = response.data;
            setVendorData(otherData);
            setVendorDataother(otherData1);
        } catch (error) {
            console.error('Error fetching vendors:', error);
        }
    };
    const fetchVendorDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Vender-edit/${ven_ladger_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            const otherData = response.data.data[0] || [];
            setVendorDataDetails(otherData);
        } catch (error) {
            console.error('Error fetching vendors:', error);
        }
    };

    const handleView = (Costomer_id) => {
        localStorage.setItem('ven_costomer_id', Costomer_id);
        navigate('/vendor-ladgor-payment');
    };
    const handleViewDetails = (costomer_id) => {
        localStorage.setItem('ven_det_costomer_id', costomer_id);
        navigate('/vendor-ladgor-Details');
    };
    const handleStockDetails = (costomer_id) => {
        // localStorage.setItem('ven_det_costomer_id', costomer_id);
        navigate('/vendor-ladgor-Stock-List');
    };
    const handleMeterilsDetails = (costomer_id) => {
        // localStorage.setItem('ven_det_costomer_id', costomer_id);
        navigate('/vendor-ladgor-Meterils-List');
    };

    useEffect(() => {
        const fetchCompanyDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Company-details-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                if (response.data.status === 1) {
                    setCompanyDetails(response.data.data[0]);
                }
            } catch (error) {
                console.error('Error fetching company details:', error);
            }
        };

        fetchCompanyDetails();
    }, [token]);

    // Calculate total, paid, and due amounts
    const totalAmount = data.reduce((sum, vendor) => sum + parseFloat(vendor.total_price || 0), 0);
    const totalPaid = data.reduce((sum, vendor) => sum + parseFloat(vendor.total_payments || 0), 0);
    const totalDue = totalAmount - totalPaid;


    return (
        <>
            <div className="App">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex">
                            <div className="w-25 mx-auto">
                                <div className="text-center">
                                    <h6 className='fw-bold mb-0'>{companyDetails.company_name}</h6>
                                    <p className='addresslink'>{companyDetails.company_address},{companyDetails.state},
                                        {companyDetails.city}-{companyDetails.pincode}</p>
                                    <a href="#" className='text-dark text-underlinetag'>MOB NO.- {companyDetails.mobile}</a>
                                </div>
                                <div className="text-center mb-30">
                                    <h5 className='fw-bold mb-0 text-red'>{dataDetails.vendor_name}</h5>
                                    <h6 className='fw-bold mb-0'>Vendor Ledger</h6>
                                    <p className='addresslink'> {dataDetails.address_line1} {dataDetails.address_line2}
                                        {dataDetails.city_name}-{dataDetails.pincode}</p>
                                    <a href="#" className='text-dark text-underlinetag'>MOB NO.- {dataDetails.mobile_number}</a>
                                </div>
                            </div>
                            <div><Link to='/vendermanagement' className='btn-added'>Back</Link></div>
                        </div>
                    </div>

                    <div className="card-body">
                        <table className='table table-striped hover border'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Sr.no</th>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Site Name</th>
                                    <th>Total Amount</th>
                                    <th>Paid Amount</th>
                                    <th>Due Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className='trcolorchange'>
                            <tr  onClick={() => handleStockDetails()} >
                                        <td></td>
                                        <td></td>
                                        <td>Stock Product</td>
                                        <td></td>
                                        <td>{data1.totalProductAmount}</td>
                                        <td></td> 
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr onClick={() => handleMeterilsDetails()}>
                                        <td></td>
                                        <td></td>
                                        <td>Receive Materials</td>
                                        <td></td>
                                        <td>{data1.totalMaterialCost}</td>
                                        <td></td> 
                                        <td></td>
                                        <td></td>
                                    </tr>
                                {data && data.map((vendor, index) => (
                                    <tr key={vendor.vendor_id}>
                                        <td>{index + 1}</td>
                                        <td>{vendor.date}</td>
                                        <td>{vendor.customer_type}</td>
                                        <td>{vendor.customer_name}</td>
                                        <td>{vendor.total_price}</td>
                                        <td onClick={() => handleView(vendor.order_id)}>{vendor.total_payments}</td>
                                        <td>{vendor.total_price - vendor.total_payments}</td>
                                        <td><Button variant="secondary" onClick={() => handleViewDetails(vendor.order_id)} className="ms-2"><FaEye /></Button></td>
                                    </tr>
                                ))}
                                
                                 
                            </tbody>
                        </table>

                        {/* Display Total, Paid, and Due Amount */}
                        <div className="d-flex justify-content-end">
                            <div className="totoalamount">
                                <div className='text-start mb-1'>
                                    <strong>Closing Balance :</strong>
                                </div>
                                <div className='d-flex gap-2 mb-1'>
                                    <div><strong>Total Amount :</strong></div>
                                    <div>{totalAmount + data1.totalProductAmount + data1.totalMaterialCost}</div>
                                </div>
                                <div className='d-flex gap-2 mb-1'>
                                    <div><strong>Paid Amount :</strong></div>
                                    <div>{totalPaid}</div>
                                </div>
                                <div className='d-flex gap-2 mb-1'>
                                    <div><strong>Due Amount :</strong></div>
                                    <div>{totalDue + data1.totalProductAmount + data1.totalMaterialCost}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StockManagement;
