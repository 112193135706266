import React, { useState } from 'react';
import { FaSearch } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";

export default function Venderreport() {
    const [tooltipPdf, setTooltipPdf] = useState(false);
    const [tooltipExcel, setTooltipExcel] = useState(false);
    const [tooltipPrint, setTooltipPrint] = useState(false);
    const [tooltipRefresh, setTooltipRefresh] = useState(false);

    const toggleTooltip = (tooltip) => {
        switch (tooltip) {
            case 'pdf':
                setTooltipPdf(!tooltipPdf);
                break;
            case 'excel':
                setTooltipExcel(!tooltipExcel);
                break;
            case 'print':
                setTooltipPrint(!tooltipPrint);
                break;
            case 'refresh':
                setTooltipRefresh(!tooltipRefresh);
                break;

            default:
                break;
        }
    };
    const [tableData, setTableData] = useState([
        {
            id: 1,
            srNumber: 101,
            vendorId: 'V001',
            vendorName: 'Vendor A',
            status: <span class="badge bedgeactive">Active</span>,
            editAction: <FaEdit/>,
            deleteAction: <MdDelete/>,
            view: <FaEye/>,
        },
        {
            id: 2,
            srNumber: 102,
            vendorId: 'V002',
            vendorName: 'Vendor B',
            status: <span class="badge bedgeinactive">Deactive</span>,
            editAction: <FaEdit/>,
            deleteAction: <MdDelete/>,
            view: <FaEye/>,
        },

    ]);

    // Handle checkbox selection
    const handleCheckboxChange = (id) => {
        setTableData(prevData =>
            prevData.map(item =>
                item.id === id ? { ...item, selected: !item.selected } : item
            )
        );
    };
    return (
        <div className="VenderReport">
            <div class="page-header">
                <div class="add-item d-flex">
                    <div class="page-title">
                        <h4>Vender Report</h4>
                        <h6>Manage Your Vender Report</h6>
                    </div>
                </div>
                
            </div>

            <div class="card table-list-card">
                <div className="card-header">
                    <div className="searchinputs d-flex">
                        <input type="text" placeholder="Search" />
                        <div className="btn d-flex align-items-center justify-content-center">
                            <FaSearch />
                        </div>
                    </div>

                </div>
                <div class="card-body">

                    <div className="table-responsive">
                        <table className="table-striped bordered hover table">
                            <thead>
                                <tr className="thead-dark">

                                    <th>Sr Number</th>
                                    <th>Vender ID</th>
                                    <th>Vender Name</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                    <th>View</th>

                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((item) => (
                                    <tr key={item.id}>

                                        <td>{item.srNumber}</td>
                                        <td>{item.vendorId}</td>
                                        <td>{item.vendorName}</td>
                                        <td>{item.status}</td>
                                        <td className="d-flex gap-1">
                                            <button className="btn btn-sm btn-success">{item.editAction}</button>
                                            <button className="btn btn-sm btn-danger">{item.deleteAction}</button>
                                        </td>
                                        <td>
                                            <button className="btn btn-sm btn-primary">{item.view}</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


    );
}