import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { MdAddCircle } from 'react-icons/md';
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong, FaPlus } from "react-icons/fa6";
import { toast, ToastContainer } from 'react-toastify';

export default function Addmaterial({ onSave }) {
    const token = localStorage.getItem('token');
    const met_receive = localStorage.getItem('vendor_view_id');
    const [materials, setMaterial] = useState([]);

    useEffect(() => {
        fetchProducts()

    }, [])
    const fetchProducts = async (coustomer_id) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}material-edit/${met_receive}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            const { id, vendor_id, customer_id, materials } = response.data.data;
            console.warn('API Response:', response.data.data.materials);
            setMaterial(response.data.data.materials)
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };
    // Calculate total met_rate and total met_cost
    const totalMetRate = materials.reduce((total, product) => total + (parseFloat(product.met_rate) || 0), 0);
    const totalMetCost = materials.reduce((total, product) => total + (parseFloat(product.met_cost) || 0), 0);
    return (
        <>
            {/* <Card> */}

                {/* <Card.Body> */}
                    <Table striped hover>
                        <thead>
                            <tr className='thead-dark'>
                                {/* <th>Sr. No.</th> */}
                                <th>Material Name</th>
                                <th>Qty</th>
                                {/* <th>Gst</th> */}
                                <th>Rete/sqft</th>
                                <th>Cost</th>

                            </tr>
                        </thead>
                        <tbody>
                            {materials && materials.map((product, index) => (
                                <tr key={product.id}>
                                    <td>
                                        {product.met_name}
                                        {product.size_l && product.size_x ? (
                                            <> <strong>{product.size_l} x {product.size_x}</strong></>
                                        ) : product.size_l ? (
                                            <> <strong>{product.size_l}</strong></>
                                        ) : product.size_x ? (
                                            <> <strong>{product.size_x}</strong></>
                                        ) : ''}
                                    </td>
                                    <td>{product.met_qty}</td>
                                    {/* <td>{product.gst}</td> */}
                                    <td>{product.met_rate}</td>
                                    <td>{isNaN(product.met_cost) ? '' : parseFloat(product.met_cost).toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                {/* <td colSpan={4} className='text-end' style={{ width: "87%" }}><strong>Rete/sqft :-</strong>{totalMetRate.toFixed(2)}</td> */}
                                <td colSpan={6} className='text-end'><strong>Total Cost :- </strong>{totalMetCost.toFixed(2)}</td>
                            </tr>
                        </tfoot>
                    </Table>
                {/* </Card.Body> */}
            {/* </Card> */}
        </>
    );
}
