import React, { useState, useMemo, useEffect } from 'react';
import { Card, Modal, Button, Col, Row } from 'react-bootstrap';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdKeyboardArrowRight, MdKeyboardArrowLeft, MdCurrencyRupee } from "react-icons/md";
import axios from 'axios';
import { CgFileDocument } from "react-icons/cg";
import { Link } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEdit, FaPlus } from "react-icons/fa";
import { MdDelete } from 'react-icons/md';
import Swal from 'sweetalert2';
import Expenditure_view from '../expenditure/Expenditureview';
import Expenditure_add from '../expenditure/AddExpenditure';
import Expenditure_update from '../expenditure/UpdateExpenditure';
import DataTable from '../expenditure/ExpenditureTable';

const Expenditurelist = () => {

  const [addProductModal, setAddProductModal] = useState(false);
  const [addEmployeeEditModal, setAddEmployeeEditModal] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const token = localStorage.getItem('token');
  const [totalAmounttoday, setTotalAmount] = useState(0);
  const [modalState, setModalState] = useState({ Expenditureview: false, Expenditureadd: false, ExpenditureUpdate: false });

  useEffect(() => {
    if (!modalState.Expenditureadd && !modalState.ExpenditureUpdate) {
      fetchEmployeeList();
    }
  }, [modalState.Expenditureadd, modalState.ExpenditureUpdate]);

  const fetchEmployeeList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}expenditure-list`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      setEmployeeList(response.data.data || []);
      const data = response.data.data || [];

      // Calculate total amount for today's date
      const today = new Date().toLocaleDateString('en-GB').replace(/\//g, '-');
      const todayAmount = data
        .filter((employee) => {
          const formattedDate = new Date(employee.date).toLocaleDateString('en-GB').replace(/\//g, '-');
          return formattedDate === today;
        })
        .reduce((total, employee) => total + parseFloat(employee.amount), 0);

      setTotalAmount(todayAmount);
    } catch (error) {
      console.error('Error fetching employee list:', error);
    }
  };

  // Calculate total amount
  const totalAmount = employeeList.reduce((sum, employee) => sum + parseFloat(employee.amount || 0), 0);

  const handleExpenditureView = (id) => {
    setModalState({ ...modalState, Expenditureview: true });
    localStorage.setItem('expenditure_view', id);
  };

  const ExpenditureUpdate = (id) => {
    setModalState({ ...modalState, ExpenditureUpdate: true });
    localStorage.setItem('expenditure_update', id);
  };

  const ExpenditureDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        let url = `${process.env.REACT_APP_API_URL}expenditure-delete/${id}`;
        const config = {
          method: 'get',
          url: url,
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        };
        const response = await axios(config);
        if (response.data.status === 1) {
          toast.success(response.data.message);
          fetchEmployeeList();
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const data = useMemo(() => employeeList.map((employee, index) => {
    const formattedDate = new Date(employee.date).toLocaleDateString('en-GB').replace(/\//g, '-');
    return {
      srnumber: index + 1,
      Date: formattedDate,
      Name: employee.expenditure_type_name,
      Category: employee.expenditure_category_name,
      Employee: employee.emp_name || employee.customer_name || employee.name,
      Amount: employee.amount,
      PaymentBy: employee.account_name,
      account_mode: employee.account_mode,
      actions: (
        <div className='edit-delete-action d-flex gap-2'>
          <Button variant="secondary" onClick={() => handleExpenditureView(employee.id)}>
            <FaEye />
          </Button>
          <Button variant="info" onClick={() => ExpenditureUpdate(employee.id)}>
            <FaEdit />
          </Button>
          <Button variant="danger" onClick={() => ExpenditureDelete(employee.id)}>
            <MdDelete />
          </Button>
        </div>
      ),
    };
  }), [employeeList]);

  const columns = useMemo(() => [
    { Header: 'Sr.No.', accessor: 'srnumber' },
    { Header: 'Date', accessor: 'Date' },
    { Header: 'Exp Name', accessor: 'Name' },
    { Header: 'Exp Category', accessor: 'Category' },
    { Header: 'Expenditure', accessor: 'Employee' },
    { Header: 'Amount', accessor: 'Amount', Cell: ({ value }) => (
      value !== undefined ? (
        new Intl.NumberFormat().format(value)
      ) : null
    ), },
    { Header: 'Payment By', accessor: 'PaymentBy' },
    { Header: 'Payment Mode', accessor: 'account_mode' },
    { Header: 'Actions', accessor: 'actions' },
  ], []);

  return (
    <>
      <ToastContainer />
      <div className="page-header">
        <div className="add-item d-flex justify-content-between">
          <div className="page-title">
            <h4>Expenditure</h4>
            <h6>Expenditure List</h6>
          </div>
        </div>
        <div className="button_add_row">
          <Button className="text-uppercase fs-18 fw-bold" onClick={() => setModalState({ ...modalState, Expenditureadd: true })}>
            <FaPlus /> Add Expenditure
          </Button>
        </div>
      </div>
      <Row>
        <Col md={4} xs={12} lg={4}>
          <Card>
            <Card.Body>
              <div className="box box-body">
                <div className="d-flex justify-content-between">
                  <div className="icon_total"><CgFileDocument /></div>
                  <div>
                    <h6>
                      <span className="text-uppercase fs-18 fw-bold">To Day Amount</span>
                    </h6>
                    <p className="fs-26">
                      <MdCurrencyRupee />
                      <strong>{totalAmounttoday.toFixed(2)}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} xs={12} lg={4}>
          <Card>
            <Card.Body>
              <Link to='/Expenditure-month-amount'>
              <div className="box box-body">
                <div className="d-flex justify-content-between">
                  <div className="icon_total"><CgFileDocument /></div>
                  <div>
                    <h6>
                      <span className="text-uppercase fs-18 fw-bold">Total Amount</span>
                    </h6>
                    <p className="fs-26">
                      <MdCurrencyRupee />
                      <strong>{new Intl.NumberFormat().format(totalAmount.toFixed(2))}</strong>
                    </p>
                  </div>
                </div>
              </div>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card>

        <Card.Body>
          <DataTable columns={columns} data={data} />
        </Card.Body>
      </Card>

      <Modal
        show={modalState.Expenditureview}
        size='lg'
        onHide={() => setModalState({ ...modalState, Expenditureview: false })}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static" 
          keyboard={false}
      >
        <Modal.Header closeButton>
          <h3 className="card-title mb-0">Expenditure Details</h3>
        </Modal.Header>
        <Modal.Body>
          <Expenditure_view onSave={() => setModalState({ ...modalState, Expenditureview: false })} />
        </Modal.Body>
      </Modal>
      <Modal
        show={modalState.Expenditureadd}
        size='xl'
        onHide={() => setModalState({ ...modalState, Expenditureadd: false })}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static" 
          keyboard={false}
      >
        <Modal.Header closeButton>
          <h3 className="card-title mb-0">Add Expenditure</h3>
        </Modal.Header>
        <Modal.Body>
          <Expenditure_add onSave={() => setModalState({ ...modalState, Expenditureadd: false })} />
        </Modal.Body>
      </Modal>
      <Modal
        show={modalState.ExpenditureUpdate}
        size='lg'
        onHide={() => setModalState({ ...modalState, ExpenditureUpdate: false })}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static" 
          keyboard={false}
      >
        <Modal.Header closeButton>
          <h3 className="card-title mb-0">Update Expenditure</h3>
        </Modal.Header>
        <Modal.Body>
          <Expenditure_update onSave={() => setModalState({ ...modalState, ExpenditureUpdate: false })} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Expenditurelist;
