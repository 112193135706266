import React, { useState } from 'react'
import { Table, Button, Card, Modal, Row, Col, Form } from 'react-bootstrap'
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom';

export default function Shipment() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="page-header">
        <div className="add-item d-flex">
          <div className="page-title">
            <h4>Shiping</h4>
            <h6>Manage Order Shipping</h6>
          </div>
        </div>

        <div className="page-btn d-flex gap-2">

        </div>
      </div>
      <Card>
        <Card.Header>
          <Card.Title>Shipping Details</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <Table Strriped bodered hover>
              <thead>
                <tr className="thead-dark">
                  <th>Date</th>
                  <th>Reference</th>
                  <th>Sale Reference</th>
                  <th>Customer</th>
                  <th>WareHouse</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>05/02/2024</td>
                  <td>5389607</td>
                  <td>S - 2234</td>
                  <td>Jane Ronan</td>
                  <td>Warehouse 01</td>
                  <td><span className="badge badge-success fs-14 fw-normal">Delivered</span></td>
                  <td className="edit-delete-action justify-content-start d-flex gap-2">
                    <Button to="javascript:void(0)" style={{ "--i": "#233882" }} onClick={handleShow} className="p-2" ><FaEdit /></Button>
                    <Link to="javascript:void(0)" style={{ "--i": "red" }} className="p-2" ><MdDelete /></Link>
                  </td>
                </tr>
                <tr>
                  <td>05/02/2024</td>
                  <td>5389607</td>
                  <td>S - 2234</td>
                  <td>Jane Ronan</td>
                  <td>Warehouse 01</td>
                  <td><span className="badge badge-info fs-14 fw-normal">Running</span></td>
                  <td className="edit-delete-action justify-content-start d-flex gap-2">
                    <Button to="javascript:void(0)" style={{ "--i": "#233882" }} onClick={handleShow} className="p-2" ><FaEdit /></Button>
                    <Link to="javascript:void(0)" style={{ "--i": "red" }} className="p-2" ><MdDelete /></Link>
                  </td>
                </tr>
                <tr>
                  <td>05/02/2024</td>
                  <td>5389607</td>
                  <td>S - 2234</td>
                  <td>Jane Ronan</td>
                  <td>Warehouse 01</td>
                  <td><span className="badge badge-danger fs-14 fw-normal">Pending</span></td>
                  <td className="edit-delete-action justify-content-start d-flex gap-2">
                    <Button to="javascript:void(0)" style={{ "--i": "#233882" }} onClick={handleShow} className="p-2" ><FaEdit /></Button>
                    <Link to="javascript:void(0)" style={{ "--i": "red" }} className="p-2" ><MdDelete /></Link>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
      <Modal show={show} size="lg" onHide={handleClose} dialogClassName="animate__animated animate__slideInUp" animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Shipping Details Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={4} lg={4} xs={12}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="custum-label">Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Date"

                />
              </Form.Group>
            </Col>
            <Col md={4} lg={4} xs={12}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="custum-label">	Reference</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Reference"

                />
              </Form.Group>
            </Col>
            <Col md={4} lg={4} xs={12}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="custum-label">	Sale Reference</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Sale Reference"

                />
              </Form.Group>
            </Col>
            <Col md={4} lg={4} xs={12}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="custum-label">	Customer</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Customer"

                />
              </Form.Group>
            </Col>
            <Col md={4} lg={4} xs={12}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="custum-label">Role</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Role"

                />
              </Form.Group>
            </Col>
            <Col md={4} lg={4} xs={12}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="custum-label">WareHouse</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="WareHouse"

                />
              </Form.Group>
            </Col>
            <Col md={4} lg={4} xs={12}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="custum-label">Status	</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Status"

                />
              </Form.Group>
            </Col>

          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">

          <Button variant="primary" onClick={handleClose}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
